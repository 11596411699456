import Vue from 'vue'
import firebase from 'firebase';
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2';
import wb from "./registerServiceWorker";

Vue.prototype.$workbox = wb;
Vue.config.productionTip = false
Vue.use(VueClipboard);

var firebaseConfig = {
  apiKey: "AIzaSyCybB_f8cV6uA7l61in9TROM4bO8kcLvVE",
  authDomain: "sierras-pack.firebaseapp.com",
  databaseURL: "https://sierras-pack-default-rtdb.firebaseio.com",
  projectId: "sierras-pack",
  storageBucket: "sierras-pack.appspot.com",
  messagingSenderId: "189548209834",
  appId: "1:189548209834:web:f2f02dfbb5b76cca3452d3",
  measurementId: "G-XZQKEDP7N3"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

global.vm = App;