import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Shipping from '../views/Shipping.vue'
import Tracking from '../views/Tracking.vue'
import Contact from '../views/Contact.vue'
import Help from '../views/Help.vue'
import PaymentURLOK from '../views/PaymentURLOK.vue'
import PaymentURLNOTOK from '../views/PaymentURLNOTOK.vue'
import PaymentOK from '../views/PaymentOK.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: {
      name: "Inicio", // poner loginui para que redirigir por defecto a login
    },
  },
  {
    path: "*",
    redirect: {
      name: "Inicio",
    },
  },
  {
    path: '/inicio',
    name: 'Inicio',
    component: Home
  },
  {
    path: '/envio',
    name: 'Envio',
    component: Shipping
  },
  {
    path: "/seguimiento/",
    redirect: {
      name: "Inicio",
    },
  },
  {
    path: '/seguimiento/:tracking',
    name: 'seguimiento',
    component: Tracking
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: Contact
  },
  {
    path: '/ayuda',
    name: 'ayuda',
    component: Help
  },
  {
    path: '/pago-correcto/:idpago',
    name: 'pago-correcto',
    component: PaymentURLOK
  },
  {
    path: '/pago-incorrecto/:idpago',
    name: 'pago-incorrecto',
    component: PaymentURLNOTOK
  },
  {
    path: '/pago-correcto-efectivo/:idpago',
    name: 'pago-correcto-efectivo',
    component: PaymentOK
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
