<template>
  <div class="bottom-bar" v-if="mobile">
    <v-bottom-navigation 
      :value="value" 
      color="primary" 
      class="bottom-bar-radius"
      shift
      active-class="active-icon"
      >
      <router-link
        to="/"
        class="decoration-remove route"
      >
        <v-btn class="inactive-icon">
          <span>Inicio</span>
          <v-icon style="height: 20px; width: 20px;"> $IconHome </v-icon>
        </v-btn>
      </router-link>
        <v-btn  class="inactive-icon-calculator"
        :style="{'fill' : fill , 'stroke' : 'none !important'}"
        @click="clickCalculator()">
          <span>Cotizador</span>
          <v-icon style="height: 20px; width: 20px;"> $IconCalculator </v-icon>
        </v-btn>
      <router-link v-if="path"  to="/envio" class="decoration-remove">
        <v-btn fab color="secondary accent-2 button-float"
        dark  absolute>
          <v-icon style="height: 20.2px; width: 22.1167px;"> $IconShipping </v-icon>
        </v-btn>
      </router-link>
      <router-link style="padding-top: 10px;padding-bottom: 10px;"
      to="/"
        class="decoration-remove route"
      >
        <v-btn v-if="!path" fab color="secondary accent-2 rounded-pill">
          <v-icon style="height: 20.2px; width: 22.1167px; transform: translateY(0px) !important;"> $IconShipping </v-icon>
        </v-btn>
      </router-link>
      <router-link
       to="/contacto"
        class="decoration-remove route"
      >
        <v-btn class="inactive-icon">
          <span>Contacto</span>
          <v-icon style="height: 20px; width: 20px;"> $IconContact </v-icon>
        </v-btn>
      </router-link>
      <router-link
       to="/ayuda"
        class="decoration-remove route"
      >
        <v-btn class="inactive-icon">
          <span>Ayuda</span>  
          <v-icon style="height: 22px; width: 22px;"> $IconHelp </v-icon>
        </v-btn>
      </router-link>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';

export default {
  data: () => ({
    mobile: isMobile ? true : false,
    value: 0,
    pathSelect: "",
    fill: '#adadad'
  }),
  computed: {
    path() {
      const path = this.$route.path;
      this.pathSelectValue();
      if (path === "/envio" || path.includes("/pago-correcto") || path.includes("/pago-correcto-efectivo") || path.includes("/pago-incorrecto")) {
        return false;
      } else {
        return true;
      }
    },
    calculatorSelected(){
      return this.$store.state.calculatorButton;
    },
    watchpathvalue() {
      return this.value;
    }
  },
  methods: {
    pathSelectValue() {
      this.pathSelect = this.$route.path;
      if (this.pathSelect == "/envio") this.value = 2;
      if (this.pathSelect == "/inicio") this.value = 0;
      if (this.pathSelect == "/contacto") this.value = 3;
      if (this.pathSelect == "/ayuda") this.value = 4;
      if (this.calculatorSelected == true ) this.value = 1;
    },
    clickCalculator(){
      this.$store.commit('valueCalculatorButton',true);
      this.$router.push({ path: '/envio' });
    }
  },
  watch:{
    watchpathvalue(){
       if(this.value == 1 ){
        this.fill =  "#0052cc !important";
      }
      else {
         this.fill = '#adadad';
      }
    }
  }
};

</script>

<style lang="scss">
.bottom-bar {
  position: fixed;
  bottom: 0%;
  width: 100%;
  z-index: 40;
}

.bottom-bar-radius{
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: white;
}

.button-float {
bottom: 45%;
height: 60px !important;
width: 60px !important;
box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12) !important;
will-change: box-shadow;
color: #ffff !important;
border-radius: 50% !important;
min-width: 60px !important;
}
.route{
  display: grid;
  text-decoration:none;
  height:100%;
  min-height:100%;
}

.active-icon {
  stroke: #0052cc !important ;
}

.inactive-icon {
  stroke: #adadad;
}
.inactive-icon-calculator{
  fill: #adadad;
}

.data-v-207d65cc{
  align-content: center !important;
  justify-content: center !important;
}

.v-btn:not(.v-btn--round).v-size--default{
  height: inherit !important; 
}

@media(max-width: 1024px){
.v-btn__content{
  position: absolute;
 }
}
</style>