import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import HomeIcon from '@/components/BottomBarIcons/HomeIcon.vue';
import CalculatorIcon from '@/components/BottomBarIcons/CalculatorIcon.vue';
import ShippingIcon from '@/components/BottomBarIcons/ShippingIcon.vue';
import ContactIcon from '@/components/BottomBarIcons/ContactIcon.vue';
import HelpIcon from '@/components/BottomBarIcons/HelpIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#0052cc',
            secondary: '#ff2633',
            backgroundDark: '#1d3460',
            accent: '#8c9eff',
            error: '#ff000a',
          },
        },
      },
      icons: {
        iconfont: 'mdi', // default
        values: {
          IconHome: {
            component: HomeIcon, // you can use string here if component is registered globally
          },
          IconCalculator: {
            component: CalculatorIcon, // you can use string here if component is registered globally
          },
          IconShipping: {
            component: ShippingIcon, // you can use string here if component is registered globally
          },
          IconContact: {
            component: ContactIcon, // you can use string here if component is registered globally
          },
          IconHelp: {
            component: HelpIcon, // you can use string here if component is registered globally
          },
        },
      },
});
