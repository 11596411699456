import axios from "axios";

//should be environment variables
const baseUrl = "https://ws.sierraspack.com.ar:441/api";
// const token = localStorage.getItem("Token");

axios.defaults.baseURL = baseUrl; 
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


 export const tokenAuth = () => {
   return axios({
     method: "POST",
     url: "/token",
     headers:{
            "content-type":"application/json",
    },
     data: {
      "usuario": "UsrAPP",
      "clave": "4ppS13rra5"
    }// using mocked data here until we have a login page commentar este bloque cuando se quiera usar el middleware de logueo
   })
 }

 export const localidades= () => {
  var token = localStorage.getItem("Token");
  return axios({
    method: "GET",
    url: "/datos/localidades",
    headers:{
      'Authorization': token
    },
    data: {}
  })
}

export const clients= (DNICliente) => {
  var token = localStorage.getItem("Token");
  return axios({
    method: "GET",
    url: "/cliente/consulta?dni=" + DNICliente,
    headers:{
      'Authorization': token
    },
    data: {},
  })
}

export const destination= (dataForm) => {
  var token = localStorage.getItem("Token");

  return axios({
    method: "GET",
    url: "/remito/destinos?IdOrigen=" + dataForm,
    headers:{
      'Authorization': token
    },
  })
}


export const articulos= () => {
  var token = localStorage.getItem("Token");

  return axios({
    method: "GET",
    url: "/remito/articulos",
    headers:{
      'Authorization': token
    },
  })
}


export const priceItem= (dataForm) => {
  var token = localStorage.getItem("Token");

  return axios({
    method: "GET",
    url: "/remito/precio?IdOrigen=" + dataForm.start + 
    "&IdDestino=" + dataForm.end + 
    "&idArt=" + dataForm.id,
    headers:{
      'Authorization': token
    },
  })
}

export const seguro= (dataForm) => {
  var token = localStorage.getItem("Token");

  return axios({
    method: "GET",
    url: "/remito/seguro?ValorDeclarado=" + dataForm,
    headers:{
      'Authorization': token
    },
  })
}

export const agregarCliente = (dataForm) => {
  var token = localStorage.getItem("Token");

  return axios({
    method: "POST",
    url: "/cliente/agregar",
    headers:{
      'Authorization': token
    },
    data: {
    "Clientes": [
      dataForm
    ]
   }// using mocked data here until we have a login page commentar este bloque cuando se quiera usar el middleware de logueo
  })
}

export const modificarCliente = (dataForm) => {
  var token = localStorage.getItem("Token");


  return axios({
    method: "POST",
    url: "/cliente/modificar",
    headers:{
      'Authorization': token
    },
    data: {
    "Clientes": [
      dataForm
    ]
   }// using mocked data here until we have a login page commentar este bloque cuando se quiera usar el middleware de logueo
  })
}

//get token from siro API
var qs = require('qs');
var data = qs.stringify({
 'Usuario': 'Tercerageneracionsrl',
 'Password': 'Sierraspack1' 
});
var config = {
  method: 'post',
  url: 'https://cors-grupo-sierras.herokuapp.com/https://www.bancoroela.com.ar:8081/api/Sesion',
  headers: { 
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  data : data
};

export const tokenPayment = () => {
  return axios(config)
}

//request payment from siro API

export const makePayment = (dataForm) => {
  var data = JSON.stringify({"nro_cliente_empresa":dataForm.nro_cliente_empresa,"nro_comprobante":dataForm.nro_comprobante,"Concepto":dataForm.Concepto,"Importe":dataForm.Importe,"URL_OK":dataForm.URL_OK,"URL_ERROR":dataForm.URL_ERROR,"IdReferenciaOperacion":dataForm.IdReferenciaOperacion,"Detalle":dataForm.Detalle});

  var config = {
    method: 'POST',
    url: 'https://cors-grupo-sierras.herokuapp.com/https://www.bancoroela.com.ar:8081/api/Pago',
    headers: { 
      'Authorization': 'Bearer ' + dataForm.token, 
      'Content-Type': 'application/json'
    },
    data : data
    };


  return axios(config)
}

export const queryPayment= (dataForm) => {

  return axios({
    method: "GET",
    url: "https://cors-grupo-sierras.herokuapp.com/https://www.bancoroela.com.ar:8081/api/Pago/" + dataForm.hash + 
    "/" + dataForm.id_resultado,
    headers:{
      'Authorization': 'Bearer ' + dataForm.token, 
      'Content-Type': 'application/json'
    },
  })
}


//end request to siro API

export const sendEmit = (dataForm) => {
  var token = localStorage.getItem("Token");

  return axios({
    method: "POST",
    url: "/remito/emitir",
    headers:{
      'Authorization': token
    },
    data: {
    "Remito": [
      dataForm
    ]
   }// using mocked data here until we have a login page commentar este bloque cuando se quiera usar el middleware de logueo
  })
}

export const seguimiento= (dataForm) => {
  var token = localStorage.getItem("Token");

  return axios({
    method: "GET",
    url: "/remito/seguimiento?PtoVta=" + dataForm.PtoVta + '&NrComp=' + dataForm.NrComp ,
    headers:{
      'Authorization': token
    },
  })
}

export const remito= (dataForm) => {
  var token = localStorage.getItem("Token");

  return axios({
    method: "GET",
    url: "/remito/ConsultaRemito?PtoVta=" + dataForm.PtoVta + '&NrComp=' + dataForm.NrComp ,
    headers:{
      'Authorization': token
    },
  })
}