import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tokenIsReady: false,
    buttomForm:true,
    valueStartForm:'',
    start: '',
    valueEndForm:'',
    destination:'',
    carryPackage: 'no',
    seekPackage: 'no',
    listPrice: {},
    idItem:'',
    modality:0,
    valueDeclared: 0 ,
    insurance: 0,
    total: 0,
    reference: '',
    idClientReceiver: 0,
    idClientRemitter: 0,
    dataReceiver: {},
    dataRemitter: {},
    idPayFrom: 1,
    idArticle: 0,
    payMethod: 'efectivo',
    remit: '',
    payUrl: '',
    calculatorButton: false,
    payNotOk: 'no',

    heightBox: 0,
    widthBox: 0,
    depthBox: 0, 

    formStartValid:false,
    formMeasuresValid:false,
    formReceiverValid: false,
    formRemitterValid:false,
  },
  mutations: {
    setTokenIsReady(state,payload){
      state.tokenIsReady= payload;
    },
    disableButtom(state,payload){
      state.buttomForm = payload;
    },
    valueStartForm(state,payload){
      state.valueStartForm = payload;
    },
    valueStart(state,payload){
      state.start = payload;
    },
    valueEndForm(state,payload){
      state.valueEndForm = payload;
    },
    valueDestination(state,payload){
      state.destination = payload;
    },
    valueIdItem(state,payload){
      state.idItem = payload;
    },
    valueModality(state,payload){
      state.modality = payload;
    },
    valueDeclare(state,payload){
      state.valueDeclared = payload;
    },
    valueIdClientReceiver(state,payload){
      state.idClientReceiver = payload;
    },
    valueIdClientRemitter(state,payload){
      state.idClientRemitter = payload;
    },
    valueReceiver(state,payload){
      state.dataReceiver = payload;
    },
    valueRemitter(state,payload){
      state.dataRemitter = payload;
    },
    valueCarryPackage(state,payload){
      state.carryPackage = payload;
    },
    valueSeekPackage(state,payload){
      state.seekPackage = payload;
    },
    valueListPrice(state,payload){
      state.listPrice = payload;
    },
    valueIdPayFrom(state,payload){
      state.idPayFrom = payload;
    },
    valuePayMethod(state,payload){
      state.payMethod = payload;
    },
    valueInsurance(state,payload){
      state.insurance = payload;
    },
    valueIdArticle(state,payload){
      state.idArticle = payload;
    },
    valueRemit(state,payload){
      state.remit = payload;
    },
    valuePayUrl(state,payload){
      state.payUrl = payload;
    },
    valueCalculatorButton(state,payload){
      state.calculatorButton = payload;
    },
    valuePayNotOk(state,payload){
      state.payNotOk = payload;
    },
    valueHeightBox(state,payload){
      state.heightBox = payload;
    },
    valueWidthBox(state,payload){
      state.widthBox = payload;
    },
    valueDepthBox(state,payload){
      state.depthBox = payload;
    },
    valueFormStartValid(state,payload){
      state.formStartValid = payload;
    },
    valueFormMeasuresValid(state,payload){
      state.formMeasuresValid = payload;
    },
    valueFormReceiverValid(state,payload){
      state.formReceiverValid = payload;
    },
    valueFormRemitterValid(state,payload){
      state.formRemitterValid = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})