<template>
<div>
    <v-container class="px-0 py-0" style="max-width: 100%; min-height: 800px;">
      <div class="bluediv"> 
        <img alt="Pago Exitoso" src="../assets/Image/icon exito.svg" class="pago-exitoso pt-11"/>
        <h1 class="pt-14 m-h1" style="color: white">
          ¡La transacción se realizó con éxito!
        </h1>
        <p class="pt-10 m-p1" style="color: white">
          Número de seguimiento:
        </p>
        <div class="pt-0">
          <v-progress-circular
            indeterminate
            color="secondary"
            v-if="!this.nroRemito"
          ></v-progress-circular>
          <h2 v-else style="color: white" class="m-seguimiento">
            {{ this.nroRemito }}
          </h2>
        </div>
        <button 
          v-clipboard:copy="nroRemito"
          v-clipboard:success="copySuccess" 
          v-clipboard:error="copyFail"
          class="px-3 pt-4">
          <img alt="Copiar" src="../assets/Image/copy.svg"/>
        </button>
        <button class="px-3 pt-4"> 
          <img alt="Compartir" src="../assets/Image/share.svg"  @click="shareViaWebShare"/>
        </button>
        <p v-if="copySucceeded === true" style="color: white">¡Código copiado!</p>
        <p v-if="copySucceeded === false" style="color: white">Error, copie manualmente.</p>
      </div>
      <div class="px-4 text-ok m-p1 waves" style="padding-bottom: 110px;">
        <p>
          Te enviaremos un mail con los datos de envío y número de seguimiento. Recuerda que podrás ver el estado de tu envío en el <b>buscador</b> ubicado en la <b>página de inicio.</b>
        </p>
      </div>
      <router-link
        to="/"
        class="decoration-remove route"
        style="align-content: center;"
      >
      <v-btn
        color="secondary"
        class="home-button mx-4"
        style="height: 44px !important;"
        m-button
        >
          IR AL INICIO
      </v-btn>
      </router-link>
    </v-container>
    <BottomBar />
</div>
</template>

<!-- eslint-disable -->
<script>
import BottomBar from "@/components/BottomBar.vue";

export default {
  name: "pago-correcto-efectivo",
  components: {
    BottomBar,
  },
  data:() => (
    {
      dataForm: {},
      nroRemito: '',
      copySucceeded: null,
    }
  ),
methods: {
  copySuccess(e) {
    this.copySucceeded = true;
  },
  copyFail: function copyFail() {
    this.copySucceeded = false;
  },
  shareViaWebShare() { 
    navigator.share({
      title: 'Compartir',
      text: '',
      url: location.protocol + '//' + location.host + "/seguimiento/" + this.nroRemito
    })
  },
  },
  mounted() {
    this.nroRemito = this.$route.params.idpago;
    console.log('nroRemito:',this.nroRemito);
  },
};
</script>


<style lang="css" scoped>
.bluediv {
  background-color: #1d3460;
  height: 28.188rem;
  width: 100%;
  
}

.text-ok {
  padding-top: 6.625rem;
  text-align: left;
}

.home-button {
  width: calc(100% - 32px) !important;
  border-radius: 4px;
  text-align: center !important;
  position: absolute !important;
  bottom: 76px !important;
  left: 0;
}

.waves {
  background-image: url("../assets/Image/waves.svg");
  background-position: top;
  background-repeat: no-repeat;
  height: 100px;
  background-size: 100% 40%;
}

@media(min-width: 1024px){
.home-button {
  margin: auto !important;
  position: absolute !important; 
  left: 0 !important;
  right: 0 !important;
}

.text-ok {
  padding-top: 8.625rem;
  padding-left:30% !important;
  padding-right: 30% !important;
  text-align: left;
}

.bluediv {
  padding-top:60px;
}
}
</style>