<template>
<div style="height: 100%; display:contents;">
  <div v-if="mobile">
    <v-container class="px-4" > 
      <div>
        <div class="d-flex justify-start pt-6 pb-3 ">
          <router-link to="/" class="decoration-remove route">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect width="16" height="16" fill="none" />
                </clipPath>
              </defs>
              <g
                id="Backward_arrow"
                data-name="Backward arrow"
                clip-path="url(#clip-path)"
              >
                <path
                  id="Trazado_10"
                  data-name="Trazado 10"
                  d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  transform="translate(16 16) rotate(180)"
                  fill="#2e2e2e"
                />
              </g>
            </svg>
          </router-link>
        </div>
        <v-container fluid class="px-0">
          <div>
            <h3 class="text-left my-2 px-20 m-h1 border-red primary--text">
              Seguimiento
            </h3>
            <p class="text-left m-p1">
              En esta sección podrás ver el estado en el que se encuentra el
              envío de tu paquete.
            </p>
            <div class="hr"></div>
          </div>
        </v-container>
      </div>
      <div class="pb-2">
        <v-text-field
          outlined
          label="Número de seguimiento"
          v-model="trackingCode"
          @keydown.enter="trackingCodeEnter"
          :rules="trackingRules"
          required
        >
          <template slot="append">
            <a @click="trackingCodeEnter">
              <search-icon
                size="1.5x"
                class="custom-class"
                color="#0052cc"
              ></search-icon>
            </a>
          </template>
        </v-text-field>
      </div>
      <Timeline
        :trackingEnter="trackingCodeTimeline"
      />
    </v-container>
    <BottomBar v-if="originalHeight <= windowHeight + 100" />
  </div>
  <div v-else class="bg-tracking">
    <v-container class="mt-16 pt-5 px-4" style="max-width: 1461px !important"> 
      <div>
        <div class="d-flex justify-start pt-6 pb-3 ">
          <router-link to="/" class="decoration-remove route">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect width="16" height="16" fill="none" />
                </clipPath>
              </defs>
              <g
                id="Backward_arrow"
                data-name="Backward arrow"
                clip-path="url(#clip-path)"
              >
                <path
                  id="Trazado_10"
                  data-name="Trazado 10"
                  d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  transform="translate(16 16) rotate(180)"
                  fill="#2e2e2e"
                />
              </g>
            </svg>
          </router-link>
        </div>
        <v-container fluid class="px-0">
          <div>
            <h1 class="mb-3 text-left primary--text ">Seguimiento</h1>
            <p class="text-left m-p1">
              En esta sección podrás ver el estado en el que se encuentra el
              envío de tu paquete.
            </p>
          </div>
        </v-container>
      </div>
      <div class="pb-2">
        <v-text-field
          outlined
          label="Número de seguimiento"
          v-model="trackingCode"
          @keydown.enter="trackingCodeEnter"
          class="tracking-input"
          :rules="trackingRules"
          required
        >
          <template slot="append">
            <a @click="trackingCodeEnter">
              <search-icon
                size="1.5x"
                class="custom-class"
                color="#0052cc"
              ></search-icon>
            </a>
          </template>
        </v-text-field>
      </div>
      <Timeline
        :trackingEnter="trackingCodeTimeline"
      />
    </v-container>
    <BottomBar v-if="originalHeight <= windowHeight + 100" />
  </div>
</div>
</template>

<script>
import BottomBar from "@/components/BottomBar.vue";
import Timeline from "@/components/Timeline.vue";
import { SearchIcon } from "vue-feather-icons";
import { isMobile } from 'mobile-device-detect';
export default {
  name: "seguimiento",
  components: {
    BottomBar,
    Timeline,
    SearchIcon,
  },
  data: () => ({
     mobile: isMobile ? true : false,
    originalHeight: window.innerHeight,
    windowHeight: window.innerHeight,
    trackingCode: "",
    trackingCodeTimeline: "",
    remito: true,
    trackingRules: [
            value => {
              // console.log(globalThis.vm.data.prototype, globalThis.vm.$data)
                if (value.length == 0) {
                    return "Este campo es requerido";  
                }
                // if(globalThis.vm.remito == false){
                  
                //   return "El código introducido no corresponde a un remito";
                // }
                else {
                    return true;
                }
            }
        ],
  }),
  methods: {
    trackingCodeEnter() {
      console.log("tracking code is", this.trackingCode);
      this.trackingCodeTimeline = this.trackingCode;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    handleOrientationChange() {
      this.originalHeight = window.innerHeight;
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        this.originalHeight = window.innerHeight;
      } else if (orientation === "landscape-primary") {
        this.originalHeight = window.innerHeight;
      }
    },
    // foundTracking(data) {
    //   console.log(data, "data en el padre");
    //   this.remito = data;
    // },
  },
  // computed:{
  //  trackingRules() {
  //     return [
  //       (v) => {
  //     if (v.length === 0) {
  //       console.log('entre?')
  //       return "Este campo es requerido";
  //     }
  //     if (this.remito == false) {
  //       return "El código introducido no corresponde a un remito";
  //     } else {
  //       return true;
  //     }
  //       }
  //     ]
  //   },
  // },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    window.addEventListener("orientationchange", this.handleOrientationChange);
    if (this.$route.params.tracking) {
      this.trackingCode = this.$route.params.tracking;
    }
    setTimeout(function () {
            window.scrollTo(0, 0);
        },2);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="css" scoped>
.tracking-input{
  width: 33.33%;
}

.bg-tracking{
  background-image: url("../assets/Image/fondo-shipping.svg");
  background-position:center bottom;
  background-repeat: no-repeat;
  background-size: 100%;  
  height: 100%;
}
</style>
