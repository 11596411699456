<template>
<div>
  <div v-if="mobile">
  <v-container fluid class=" pb-15 mb-15 px-0">
    <div>
      <h3 class="mobile text-left my-2 px-20 m-h1 border-red ">
        Origen y Destino
      </h3>
      <p class="text-left m-p3 mb-3">
        Los campos con (*) asterísco son obligatorios.
      </p>
      <div class="hr"></div>
    </div>
    <v-row align="center">
      <v-col cols="12" md="6" style="padding-top:0px;padding-bottom:0px">
        <v-autocomplete
          v-model="valueStart"
          :items="items"
          auto-select-first
          clearable
          outlined
          :loading="isLoading"
          label="Origen *"
          no-data-text="Cargando datos..."
        ></v-autocomplete>
        <!-- <v-text-field
          outlined
          label="Origen"
          v-model="trackingCode"
        ></v-text-field> -->
      </v-col>
      <v-col cols="12" md="6" style="padding-top:0px;padding-bottom:0px">
        <v-autocomplete
          v-model="valueEnd"
          :items="itemsDestination"
          auto-select-first
          clearable
          outlined
          :loading="isLoadingDest"
          label="Destino *"
          no-data-text="Cargando datos..."
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</div>
   <v-container  v-else fluid class="px-0 ">
    <div>
      <h3 class="mobile text-left my-2 px-20 m-h1 border-red ">
        Origen y Destino
      </h3>
      <p class="text-left m-p3 mb-3">
        Los campos con (*) asterísco son obligatorios.
      </p>
      <div class="hr"></div>
    </div>
    <v-row align="center">
      <v-col cols="12" md="6" style="padding-top:0px;padding-bottom:0px">
        <v-autocomplete
          v-model="valueStart"
          :items="items"
          auto-select-first
          clearable
          outlined
          :loading="isLoading"
          label="Origen *"
          no-data-text="Cargando datos..."
        ></v-autocomplete>
        <!-- <v-text-field
          outlined
          label="Origen"
          v-model="trackingCode"
        ></v-text-field> -->
      </v-col>
      <v-col cols="12" md="6" style="padding-top:0px;padding-bottom:0px">
        <v-autocomplete
          v-model="valueEnd"
          :items="itemsDestination"
          auto-select-first
          clearable
          outlined
          :loading="isLoadingDest"
          label="Destino *"
          no-data-text="Cargando datos..."
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>
<script>
import { localidades, destination } from "@/services/shipping";
import { isMobile } from 'mobile-device-detect';
export default {
  data: () => ({
    mobile: isMobile ? true : false,
    items: [],
    itemsDestination: [],
    valueStart: null,
    valueEnd: null,
    dataLocation: [],
    dataLocationDestination: [],
    isLoading: false,
    isLoadingDest:false,
    isRunDataLoc: false,
  }),
  methods: {
    async dataLoc() {
      this.isLoading = true;
      this.isRunDataLoc = true;
      const { data } = await localidades();
      this.dataLocation = data;
      if (this.dataLocation) {
        this.dataLocation.forEach((location) => {
          this.items.push(location.NmLocalidad);
        });
      }
      this.isLoading = false;
    },
    async dataDestination(idLocation) {
      this.isLoadingDest = true;
      var dataForm = idLocation;
      const { data } = await destination(dataForm);
      this.dataLocationDestination = data;
      if (this.dataLocationDestination) {
        this.dataLocationDestination.forEach((location) => {
          this.itemsDestination.push(location.NmLocalidad);
        });
      }
      else{
        this.dataLocationDestination = []
      }
      this.isLoadingDest = false;
    },
    completed() {
      this.$store.commit("disableButtom", false);
    },
    incompleted() {
      this.$store.commit("disableButtom", true);
    },
  },
  computed:{
  token(){
    return localStorage.getItem("Token");
  }
  },
  mounted() {
    if(this.token){
    this.dataLoc();
    }
    this.$store.commit("disableButtom", true);
  },
  watch: {
    valueStart() {
      this.dataLocation.forEach((location) => {
        if (this.valueStart == location.NmLocalidad) {
          this.$store.commit("valueStartForm", location.IdLocalidad);
            this.$store.commit("valueStart", location.NmLocalidad);
          this.dataDestination(location.IdLocalidad);
        }
      });
      if (this.valueStart && this.valueEnd) {
        this.completed();
    this.$store.commit("valueFormStartValid", true);
      } else {
        this.incompleted();
      }
    },
    valueEnd() {
      this.dataLocationDestination.forEach((location) => {
        if (this.valueEnd == location.NmLocalidad) {
          this.$store.commit("valueEndForm", location.IdLocalidad);
          this.$store.commit("valueDestination", location.NmLocalidad);
        }
      });
      if (this.valueStart && this.valueEnd) {
        this.completed();
        this.$store.commit("valueFormStartValid", true);
      } else {
        this.incompleted();
      }
    },
    '$store.state.tokenIsReady': function() {
    if(this.isRunDataLoc == false){
    this.dataLoc();
    }
  }
  },
};
</script>
<style lang="css">
.v-list-item__title {
    align-self: center;
    font-family: 'Roboto';
}



</style>