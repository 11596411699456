<template>
  <v-app>
    <v-banner
        v-if="deferredPrompt && mobile"
        color="#1d3460"
        dark
        class="text-left mobile"
        style="position: fixed; top: 0; font-size: 14px; z-index: 1; width: 100%; height: 90px;"
      >
        Instalá nuestra aplicación gratuita. <b>¡No ocupa espacio en tu dispositivo!</b> 
      <template v-slot:actions>
        <v-btn text @click="dismiss" style="font-size: 14px; position: fixed !important; right: 16px; padding-bottom: 16px;">Cerrar</v-btn>
        <v-btn text @click="install" style="font-size: 14px; position: fixed !important; right: 108px; padding-bottom: 16px;">Instalar</v-btn>
      </template>
    </v-banner>
    <TopBar v-if="!mobile" />
    <router-view />
    <BottomBarDesktop  v-if="!mobile" />
  </v-app>
</template>
<script>
// @ is an alias to /src
import { tokenAuth } from "@/services/shipping";
import TopBar from "@/components/TopBarDesktop.vue";
import BottomBarDesktop from "@/components/BottomBarDesktop.vue";
import { isMobile } from 'mobile-device-detect';

export default {
  name: "App",
   components: {
    TopBar,
    BottomBarDesktop,
  },
   data() {
    return {
      deferredPrompt: null,
      mobile: isMobile ? true : false
    }
    },
  methods:{
  async token() {
    const { data } = await tokenAuth();
    await localStorage.setItem("Token", data.token);
    if(data){
      console.log('va el true del token');
    this.$store.commit('setTokenIsReady',true);
    }
  },
  async accept() {
    this.showUpdateUI = false;
    await this.$workbox.messageSW({ type: "SKIP_WAITING" });
  },
  async dismiss() {
      this.deferredPrompt = null;
  },
  async install() {
    this.deferredPrompt.prompt();
  },
  },
  computed:{
   paymentUrl(){
     console.log(" this.$store.state.payUrl", this.$store.state.payUrl);
     return this.$store.state.payUrl;
   }
  },
  watch:{
     //eslint-disable-next-line
   paymentUrl(newValue){
     if(this.paymentUrl != ''){
       event.returnValue = true;
     }
   }
  },
 created(){
    this.token();
     window.addEventListener('beforeunload', (event) => {
             if(this.$route.name =="Envio" && !this.paymentUrl){
               event.preventDefault();
             }
            });
    if (this.$workbox) {
    this.$workbox.addEventListener("waiting", () => {
      this.showUpdateUI = true;
      });
    }
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });          
  }
};

/* with setTimeout you have control over how long the
splash screen sticks because the document will load first. */
//  setTimeout( function() {
//     var wrapper= document.getElementById("app") ;
//     var splash_screen = document.getElementById("splash_screen") ;
//     document.body.removeChild(splash_screen) ;
//     wrapper.style.display = "initial" ;
//     } , 2000 ) ;

</script>


<style lang="scss">
#app {
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2e2e2e;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2e2e2e;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.m-seguimiento{
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
}
.m-h1 {
  font-family: Poppins;
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #0052cc;

}
.m-h2{
  font-family: Poppins;
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #0052cc;
}
.m-h3{
  font-family: Roboto;
  font-size: 1rem !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.14px;

}
.m-p1{
  font-family: Roboto;
  font-size: 1rem !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  letter-spacing: 0.16px !important;

}
.m-p2{
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.14px;

}
.m-p3{
    font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.24px;

}
.m-button{
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: 0.66px;

}
.m-label{
  font-family: Roboto;
  font-size: 0.625rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;

}
.m-label-small{
  font-family: Roboto;
  font-size: 0.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.2px;
}
.hr{
 height: 1px;
margin-bottom: 1.5rem;
background-color: #d9d9d9;
}

.v-select-list {
  text-align: left !important;
}




//desktop
.d-seguimiento{
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
}
.d-h1 {
  font-family: Poppins;
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #0052cc;

}
.d-h2{
  font-family: Poppins;
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #0052cc;
}
.d-h3{
  font-family: Roboto;
  font-size: 1rem !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.14px;

}
.d-p1{
  font-family: Roboto;
  font-size: 1rem !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  letter-spacing: 0.16px !important;

}
.d-p2{
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.14px;

}
.d-p3{
    font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.24px;

}
.d-button{
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: 0.66px;

}
.d-label{
  font-family: Roboto;
  font-size: 0.625rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;

}
.d-label-small{
  font-family: Roboto;
  font-size: 0.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.2px;
}


/*
Using a media query to test for the display-mode of your application
*/
@media all and (display-mode: fullscreen) {
   body {
      margin: 0;
      border: 5px solid black;
   }
}

#splash_screen {
}
@media(max-width: 1024px){
#splash_screen {
    background: white;
    width: 100%;
    height: 100%;
    z-index: 2000;
    position: absolute;
}
}
</style>