<template>
<div>
  <v-container v-if="mobile" style="margin-right:90px">
     <v-progress-circular
            indeterminate
            color="primary"
            v-if="this.isLoading"
    ></v-progress-circular>
    <v-timeline 
      v-if="e1 != 0 && !this.isLoading "
      dense
      clipped
      align-top
      :class="{
        'actual-theme-gradient-1': e1 == 1,
        'actual-theme-gradient-2': e1 == 2,
        'actual-theme-gradient-3': e1 == 3,
        'actual-theme-gradient-4': e1 == 4,
      }"
    > 
      <div class="v-timeline-item theme--light">
        <div v-if="trackingData[0].Estado == 'SOLICITUD'" class="v-timeline-item__body">
          <div class="row justify-space-between ml-n11">
            <div class="text-left col" style="padding: 0px;">
              <div class="d-flex">
              <h3 class="m-h3 primary--text">
                En proceso
              </h3>
              <p class="color-grey mb-0" 
                style="line-height: 1.31;letter-spacing: 0.14px;">
              <span class="m-h3 primary--text">:</span> {{ trackingData[0].Fecha }}
              </p>
              </div>
              Tu pedido se cargó correctamente.
            </div>
          </div>
        </div>        
        <div v-else class="v-timeline-item__body">
          <div class="row justify-space-between ml-n11">
            <div class="text-left col" style="padding: 0px;">
              <div class="d-flex">
              <h3 v-if="trackingData[0].TipoMovimiento == 'EN ORIGEN'" class="m-h3 primary--text">
                En Origen
              </h3>
              <h3 v-else-if="trackingData[0].TipoMovimiento == 'EN RECOLECCION'" class="m-h3 primary--text">
                En Recolección
              </h3>
              <h3 v-else-if="trackingData[0].TipoMovimiento == 'ESPERANDO ENCOMIENDA'" class="m-h3 primary--text">
                En Espera
              </h3>
              <p v-if="e1 > 1" class="color-grey mb-0" 
                style="line-height: 1.31;letter-spacing: 0.14px;">
              <span class="m-h3 primary--text">:</span> {{ trackingData[0].Fecha }}
              </p>
              </div>
              <p v-if="trackingData[0].TipoMovimiento == 'EN ORIGEN'">
                El paquete se encuentra en terminal.
              </p>
              <p v-else-if="trackingData[0].TipoMovimiento == 'EN RECOLECCION'">
                Estaremos retirando el paquete por tu domicilio.
              </p>
              <p v-else-if="trackingData[0].TipoMovimiento == 'ESPERANDO ENCOMIENDA'">
                Estamos esperando que entregues el paquete en terminal.
              </p>
              
            </div>
          </div>
        </div>

        <div class="v-timeline-item__divider">
          <div v-if="e1 > 1">
            <div class="v-timeline-item__dot v-timeline-item__dot--small"></div>
          </div>
          <div v-else>
            <div
              class="actualv-timeline-item__dot"
              :class="{
                'v-timeline-item__dot v-timeline-item__dot--small': e1 == 1,
              }"
            >
              <div
                class="actual-v-timeline-item__inner-dot"
                :class="{ 'v-timeline-item__inner-dot white': e1 == 1 }"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="v-timeline-item theme--light">
        <div class="v-timeline-item__body">
          <div class="row justify-space-between ml-n11">
            <div class="text-left col" style="padding: 0px;">
              <div class="d-flex">
              <h3
                class="m-h3"
                style="color: #0052cc"
                :class="{ 'gray-text': e1 < 2 }"
              >
                En envío
              </h3>
               <p v-if="e1 > 2" class="color-grey mb-0" 
                style="line-height: 1.31;letter-spacing: 0.14px;">
              <span class="m-h3 primary--text">:</span> {{ trackingData[1].Fecha }}
              </p>
              </div>
              <div :class="{ 'gray-text': e1 < 2 }">
                Estamos enviando tu paquete.
              </div>
            </div>
          </div>
        </div>

        <div class="v-timeline-item__divider">
          <div v-if="e1 < 2">
            <div
              class="v-timeline-item__dot v-timeline-item__dot--small"
              style="background: #d9d9d9"
            ></div>
          </div>
          <div v-else-if="e1 > 2">
            <div class="v-timeline-item__dot v-timeline-item__dot--small"></div>
          </div>
          <div v-else>
            <div
              class="actualv-timeline-item__dot"
              :class="{
                'v-timeline-item__dot v-timeline-item__dot--small': e1 == 2,
              }"
            >
              <div
                class="actual-v-timeline-item__inner-dot"
                :class="{ 'v-timeline-item__inner-dot white': e1 == 2 }"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="v-timeline-item theme--light">
        <div class="v-timeline-item__body">
          <div class="row justify-space-between ml-n11">
            <div
              class="text-left col"
              style="padding: 0px;"
              :class="{ 'gray-text': e1 < 3 }"
            >
            <div class="d-flex">
              <h3
                class="m-h3"
                style="color: #0052cc"
                :class="{ 'gray-text': e1 < 3 }"
              >
                En destino
              </h3>
               <p v-if="e1 > 3" class="color-grey mb-0" 
                style="line-height: 1.31;letter-spacing: 0.14px;">
              <span class="m-h3 primary--text">:</span> {{ trackingData[1].Fecha }}
              </p>
               </div>
              <div :class="{ 'gray-text': e1 < 3 }">
                El paquete se encuentra en terminal.
              </div>
            </div>
          </div>
        </div>

        <div class="v-timeline-item__divider">
          <div v-if="e1 < 3">
            <div
              class="v-timeline-item__dot v-timeline-item__dot--small"
              style="background: #d9d9d9"
            ></div>
          </div>
          <div v-else-if="e1 > 3">
            <div class="v-timeline-item__dot v-timeline-item__dot--small"></div>
          </div>
          <div v-else>
            <div
              class="actualv-timeline-item__dot"
              :class="{
                'v-timeline-item__dot v-timeline-item__dot--small': e1 == 3,
              }"
            >
              <div
                class="actual-v-timeline-item__inner-dot"
                :class="{ 'v-timeline-item__inner-dot white': e1 == 3 }"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="v-timeline-item theme--light">
        <div class="v-timeline-item__body">
          <div class="row justify-space-between ml-n11">
            <div
              class="text-left col"
              style="padding: 0px;"
              :class="{ 'gray-text': e1 < 4 }"
            >
             <div class="d-flex">
              <h3
                class="m-h3"
                style="color: #0052cc"
                :class="{ 'gray-text': e1 < 4 }"
              >
                Entrega
              </h3>
               <p v-if="e1 > 4" class="color-grey mb-0" 
                style="line-height: 1.31;letter-spacing: 0.14px;">
              <span class="m-h3 primary--text">:</span> {{ trackingData[2].Fecha }}
              </p>
              </div>
              <div :class="{ 'gray-text': e1 < 4 }">
                El paquete se entregó exitosamente en destino.
              </div>
            </div>
          </div>
        </div>

        <div class="v-timeline-item__divider">
          <div v-if="e1 < 4">
            <div
              class="v-timeline-item__dot v-timeline-item__dot--small"
              style="background: #d9d9d9"
            ></div>
          </div>
          <div v-else-if="e1 > 4">
            <div class="v-timeline-item__dot v-timeline-item__dot--small"></div>
          </div>
          <div v-else>
            <div
              class="actualv-timeline-item__dot"
              :class="{
                'v-timeline-item__dot v-timeline-item__dot--small': e1 == 4,
              }"
            >
              <div
                class="actual-v-timeline-item__inner-dot"
                :class="{ 'v-timeline-item__inner-dot white': e1 == 4 }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </v-timeline>
    <h5 v-if="!this.isLoading && !this.remito" >El código introducido no corresponde a un remito</h5>
  </v-container>
   <v-container  v-else class = " d-traking-container">
     <v-progress-circular
            indeterminate
            color="primary"
            v-if="this.isLoading"
    ></v-progress-circular>
    <v-row class="justify-space-around iconos-tracking" v-if="e1 != 0">
          <v-col v-if="e1<=1" cols="3">
            <img src="../assets/Image/desktop-timeline/icon-retiro-color.svg" style="position: relative; z-index: 2; background: white;">
            <h3 v-if="this.movFinal == 'ESPERANDO ENCOMIENDA'" class="primary--text">En Espera</h3>
            <h3 v-else-if="this.movFinal == 'EN RECOLECCION'" class="primary--text">En Recolección</h3>
            <h3 v-else-if="this.movFinal == 'EN ORIGEN'" class="primary--text">En Origen</h3>
            <p v-if="this.movFinal == 'ESPERANDO ENCOMIENDA'" class="text-tracking">Estamos esperando que entregues el paquete en terminal.</p>
            <p v-else-if="this.movFinal == 'EN RECOLECCION'" class="text-tracking">Estaremos retirando el paquete por tu domicilio.</p>
            <p v-else-if="this.movFinal == 'EN ORIGEN'" class="text-tracking">El paquete se encuentra en terminal.</p>
          </v-col>
          <v-col v-else-if="e1>1" cols="3">
            <img src="../assets/Image/desktop-timeline/icon-retiro-azul.svg" style="position: relative; z-index: 2;">
            <h3 class="primary--text">En Origen</h3>
            <p class="text-tracking">El paquete se encuentra en terminal.</p>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-if="e1<2" cols="3">
            <img src="../assets/Image/desktop-timeline/icon-envio-gris.svg" style="position: relative; z-index: 2;">
            <h3 style="color: #adadad;">Envio</h3>
            <p class="text-tracking" style="color: #adadad;">Estamos enviando tu paquete.</p>
          </v-col>
          <v-col v-else-if="e1==2" cols="3">
            <img src="../assets/Image/desktop-timeline/icon-envio-color.svg" style="position: relative; z-index: 2; background: white;">
            <h3 class="primary--text">Envio</h3>
            <p class="text-tracking">Estamos enviando tu paquete.</p>
          </v-col>
          <v-col v-else-if="e1>2" cols="3">
            <img src="../assets/Image/desktop-timeline/icon-envio-azul.svg" style="position: relative; z-index: 2;">
            <h3 class="primary--text">Envio</h3>
            <p class="text-tracking">Estamos enviando tu paquete.</p>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-if="e1<3" cols="3">
            <img src="../assets/Image/desktop-timeline/pin_gris_blanco.svg" style="position: relative; z-index: 2;">
            <h3 style="color: #adadad;">En destino</h3>
            <p class="text-tracking" style="color: #adadad;">El paquete se encuentra en terminal.</p>
          </v-col>
          <v-col v-else-if="e1==3" cols="3">
            <img src="../assets/Image/desktop-timeline/pin_azul_rojo.svg" style="position: relative; z-index: 2; background: white;">
            <h3 class="primary--text">En destino</h3>
            <p class="text-tracking">El paquete se encuentra en terminal.</p>
          </v-col>
          <v-col v-else-if="e1>3" cols="3">
            <img src="../assets/Image/desktop-timeline/pin_azul_blanco.svg" style="position: relative; z-index: 2;">
            <h3 class="primary--text">En destino</h3>
            <p class="text-tracking">El paquete se encuentra en terminal.</p>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-if="e1<4" cols="3">
            <img src="../assets/Image/desktop-timeline/icon-entrega-gris.svg" style="position: relative; z-index: 2;">
            <h3 style="color: #adadad;">Entrega</h3>
            <p class="text-tracking" style="color: #adadad;">El paquete se entregó exitosamente en destino.</p>
          </v-col>
          <v-col v-else-if="e1==5" cols="3" style="margin-top: -15px;">
            <img src="../assets/Image/desktop-timeline/icon-entrega-color.svg" style="position: relative; z-index: 2; background: white; height: 120px;">
            <h3 class="primary--text">Entrega</h3>
            <p class="text-tracking">El paquete se entregó exitosamente en destino.</p>
          </v-col>
          <div v-if="e1 > 1" class="blue-line-1"></div>
          <div v-else class="gray-line-1"></div>
          <div v-if="e1 > 2" class="blue-line-2"></div>
          <div v-else class="gray-line-2"></div>
          <div v-if="e1 > 3" class="blue-line-3"></div>
          <div v-else class="gray-line-3"></div>
    </v-row>        
    <h5 v-if="!this.isLoading && !this.remito" >El código introducido no corresponde a un remito</h5>
  </v-container>
</div>  
</template>

<script>
import { seguimiento, remito  } from "@/services/shipping"
import { isMobile } from 'mobile-device-detect';
export default {
  name: "timeline",
  data() {
    return {
      mobile: isMobile ? true : false,
      e1: 0,
      movFinal: '',
      trackingData: {},
      isLoading: false,
      remito: true,
    };
  },
  props:{
   trackingEnter: String,
  },
  methods: {
   async trackingCode() {
     try {
     this.isLoading = true;
     let dataForm = {};
     let expresion = /\s*-\s*/ ;
     let code;
     let codeSplit = [];
     if(this.trackingEnter){
       code = this.trackingEnter;
     }
     else {
      code = this.$route.params.tracking;
     }
     if(code.split(expresion).length == 2){
        codeSplit = code.split(expresion);
     }
     else{
        codeSplit[0] = code.substr(0,4)
        codeSplit[1] = code.substr(4);
     }
     console.log(codeSplit,'slipt');
     dataForm.PtoVta = codeSplit[0];
     console.log(codeSplit[0], codeSplit[1]);
     dataForm.NrComp = codeSplit[1];
     console.log(dataForm.NrComp);
    const { data } = await seguimiento(dataForm);
    console.log(data);
    if( data.length != 0){
    this.trackingData = data;
    data.forEach(mov => {
      this.movFinal = mov.TipoMovimiento;
      console.log(this.movFinal,'mov');
    });
    console.log(this.movFinal , 'el ultimo');
    if ( this.movFinal == 'EN ORIGEN' || this.movFinal == 'EN RECOLECCION' || this.movFinal == 'ESPERANDO ENCOMIENDA'){
      this.e1 = 1;
    }
    if(this.movFinal == 'EN TRANSITO'){
      this.e1 = 2;
    }
    if(this.movFinal == 'EN DESTINO' ){
      this.e1 = 3;
    }
    if( this.movFinal == 'ENTREGADA'  ){
      this.e1 = 5;
    }
     this.remito = true;
    this.isLoading = false;
    }
    else{
    console.log('error en seguimiento, intentando remito:');
     const { data } = await remito(dataForm);
     console.log(data);
     if( data.length != 0){
     this.trackingData = data;
     this.remito = true;
     this.isLoading = false;
     this.e1 = 1;
     }
     else{
      this.remito = false;
      this.isLoading = false;
      this.e1 = 0;
    }
    }
     }
     catch(e){
     console.log(e.message, 'error en seguimiento, intentando remito:');
      this.remito = false;
      this.isLoading = false;
   }
   }
  },
  watch:{
   trackingEnter(){
     if(this.trackingEnter){
       this.trackingCode();
     }
   }
  },
  mounted(){
    if(this.$route.params.tracking){
    this.trackingCode();
    }
  }
};
</script>

<style lang="css">
.v-timeline {
  padding-top: 0px;
}

.theme--light.v-timeline .v-timeline-item__dot {
  background: #0052cc;
}

.theme--light.v-timeline:before {
  background: #0052cc;
  width: 0.06rem;
}

.gray-text {
  color: #d9d9d9 !important;
}

.actual-theme-gradient-1:before {
  background: #d9d9d9 !important;
  width: 0.06rem;
}

.actual-theme-gradient-2:before {
  background: linear-gradient(110deg, #0052cc 35%, #d9d9d9 0%) !important;
  width: 0.06rem;
}

.actual-theme-gradient-3:before {
  background: linear-gradient(110deg, #0052cc 67%, #d9d9d9 33%) !important;
  width: 0.06rem;
}

.actual-theme-gradient-4:before {
  background: #0052cc !important;
  width: 0.06rem;
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
  height: 19.125rem;
  left: 24px;
}

.v-timeline-item {
  padding-bottom: 0px;
}

.v-timeline-item__divider {
  align-items: normal;
  height: 6.375rem;
  padding-right: 48px;
}

.v-timeline-item__dot {
  height: 0.75rem;
  width: 0.75rem;
  box-shadow: none;
}

.actualv-timeline-item__dot {
  height: 1rem;
  width: 1rem;
  padding-top: 0.01px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actual-v-timeline-item__inner-dot {
  height: 0.8rem !important;
  width: 0.8rem !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-traking-container{
  margin: 0 !important;
  max-width: 1461px;
}

.iconos-tracking {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.text-tracking{
  max-width: 15.125rem;
  color: #2e2e2e;
  text-align: center;
  margin: auto;
}

.space-icon-tracking{
  padding-right: 6rem;
}

.blue-line-1 {
  background: #0052cc;
  height: 2px;
  width: 285px;
  position: absolute;
  top: 502px;
  z-index: 1;
}

.gray-line-1 {
  background: #d9d9d9;
  height: 2px;
  width: 285px;
  position: absolute;
  top: 502px;
  z-index: 1;
}

.blue-line-2 {
  background: #0052cc;
  height: 2px;
  width: 285px;
  position: absolute;
  top: 502px;
  left: calc(50% - 140px);
  z-index: 1;
}

.gray-line-2 {
  background: #d9d9d9;
  height: 2px;
  width: 285px;
  position: absolute;
  top: 502px;
  left: calc(50% - 140px);
  z-index: 1;
}

.blue-line-3 {
  background: #0052cc;
  height: 2px;
  width: 285px;
  position: absolute;
  top: 502px;
  z-index: 1;
}

.gray-line-3 {
  background: #d9d9d9;
  height: 2px;
  width: 285px;
  position: absolute;
  top: 502px;
  z-index: 1;
}

@media (min-width: 1024px) {
.blue-line-1 {
  left: calc(50% - 400px) !important;
 }
 
.gray-line-1 {
  left: calc(50% - 400px) !important;
 }

.blue-line-3 {
  left: calc(50% + 100px) !important;
 }
 
.gray-line-3 {
  left: calc(50% + 100px) !important;
 }

}

@media (min-width: 1174px) {
.blue-line-1 {
  left: calc(50% - 465px) !important;
 }
 
.gray-line-1 {
  left: calc(50% - 465px) !important;
 }

.blue-line-3 {
  left: calc(50% + 180px) !important;
 }
 
.gray-line-3 {
  left: calc(50% + 180px) !important;
 }

}

@media (min-width: 1440px) {
.blue-line-1 {
  left: calc(50% - 500px) !important;
 }
 
.gray-line-1 {
  left: calc(50% - 500px) !important;
 }

.blue-line-3 {
  left: calc(50% + 215px) !important;
 }
 
.gray-line-3 {
  left: calc(50% + 215px) !important;
 }

}

</style>
