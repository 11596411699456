<template>
<div>
  <div v-if="mobile">
  <v-container fluid class="px-0"
   :style="{
        'margin-bottom':
          (this.calculatorSelected == false )
            ? 180 + 'px'
            : 220 + 'px',
      }"  
  >
    <div>
      <h3 class="mobile text-left my-2 px-20 m-h1 border-red primary--text ">
        Medidas
      </h3>
      <p class="text-left m-p3 mb-3">
        Los campos con (*) asterísco son obligatorios.
      </p>
      <div class="hr"></div>
    </div>
    <v-form v-model="isFormValid">
      <v-row align="center">
        <v-col cols="12" style="padding-top:0px;padding-bottom:0px">
          <v-text-field
            outlined
            type="number" pattern="[0-9]*" inputmode="numeric"
            :rules="numberRules"
            required
            label="Alto (cm) *"
            v-model="heigthBox"
          >
            <template slot="append">
              <svg
                style="right: 1rem;bottom:31%;position:absolute"
                xmlns="http://www.w3.org/2000/svg"
                width="28.938"
                height="22.11"
                viewBox="0 0 28.938 22.11"
              >
                <g
                  id="icon_alto"
                  data-name="icon alto"
                  transform="translate(0.289 1.032)"
                >
                  <g id="package2" transform="translate(6.552 -2.002)">
                    <line
                      id="Línea_4"
                      data-name="Línea 4"
                      x1="9"
                      y1="5.19"
                      transform="translate(7.5 4.21)"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_438"
                      data-name="Trazado 438"
                      d="M21,16V8a2,2,0,0,0-1-1.73l-7-4a2,2,0,0,0-2,0l-7,4A2,2,0,0,0,3,8v8a2,2,0,0,0,1,1.73l7,4a2,2,0,0,0,2,0l7-4A2,2,0,0,0,21,16Z"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_439"
                      data-name="Trazado 439"
                      d="M3.27,6.96,12,12.01l8.73-5.05"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <line
                      id="Línea_5"
                      data-name="Línea 5"
                      y1="10.08"
                      transform="translate(12 12)"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                  <g
                    id="arrow_back-24px"
                    transform="translate(8.183 3.489) rotate(90)"
                  >
                    <path
                      id="Trazado_433"
                      data-name="Trazado 433"
                      d="M11.744,7.035H5.778L7.722,5.09a.4.4,0,0,0,0-.566.4.4,0,0,0-.562,0L4.534,7.15a.4.4,0,0,0,0,.562L7.16,10.339a.4.4,0,0,0,.562-.562L5.778,7.832h5.967a.4.4,0,0,0,0-.8Z"
                      transform="translate(-1.505 -2.273)"
                      fill="#ff2633"
                      stroke="#ff2633"
                      stroke-width="0.5"
                    />
                  </g>
                </g>
              </svg>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" style="padding-top:0px;padding-bottom:0px">
          <v-text-field
            outlined
            :rules="numberRules"
            type="number" pattern="[0-9]*" inputmode="numeric"
            required
            label="Ancho (cm) *"
            v-model="widthBox"
          >
            <template slot="append">
              <svg
                style="right: 1rem;bottom:21%;position:absolute"
                xmlns="http://www.w3.org/2000/svg"
                width="24.493"
                height="28.831"
                viewBox="0 0 24.493 28.831"
              >
                <g
                  id="icon_ancho"
                  data-name="icon ancho"
                  transform="translate(-334.604 -241.968)"
                >
                  <g id="package2" transform="translate(337 240.998)">
                    <line
                      id="Línea_4"
                      data-name="Línea 4"
                      x1="9"
                      y1="5.19"
                      transform="translate(7.5 4.21)"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_438"
                      data-name="Trazado 438"
                      d="M21,16V8a2,2,0,0,0-1-1.73l-7-4a2,2,0,0,0-2,0l-7,4A2,2,0,0,0,3,8v8a2,2,0,0,0,1,1.73l7,4a2,2,0,0,0,2,0l7-4A2,2,0,0,0,21,16Z"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_439"
                      data-name="Trazado 439"
                      d="M3.27,6.96,12,12.01l8.73-5.05"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <line
                      id="Línea_5"
                      data-name="Línea 5"
                      y1="10.08"
                      transform="translate(12 12)"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                  <g
                    id="arrow_back-24px"
                    transform="translate(344.004 273.726) rotate(-150)"
                  >
                    <path
                      id="Trazado_433"
                      data-name="Trazado 433"
                      d="M12.722,7.386H5.959l2.2-2.2a.455.455,0,0,0,0-.641.45.45,0,0,0-.637,0L4.55,7.517a.45.45,0,0,0,0,.637L7.527,11.13a.45.45,0,0,0,.637-.637l-2.2-2.2h6.763a.452.452,0,0,0,0-.9Z"
                      transform="translate(-1.505 -2.273)"
                      fill="#ff2633"
                      stroke="#ff2633"
                      stroke-width="0.5"
                    />
                  </g>
                </g>
              </svg>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" style="padding-top:0px;padding-bottom:0px">
          <v-text-field
            outlined
            :rules="numberRules"
            type="number" pattern="[0-9]*" inputmode="numeric"
            required
            label="Profundidad (cm) *"
            v-model="depthBox"
          >
            <template slot="append" class="pt-0 mt-0">
              <svg
                style="right: 1rem;bottom:26%;position:absolute"
                xmlns="http://www.w3.org/2000/svg"
                width="23.199"
                height="29.074"
                viewBox="0 0 23.199 29.074"
              >
                <g
                  id="icon_profundidad"
                  data-name="icon profundidad"
                  transform="translate(-335.898 -235.004)"
                >
                  <g id="package2" transform="translate(337 240.998)">
                    <line
                      id="Línea_4"
                      data-name="Línea 4"
                      x1="9"
                      y1="5.19"
                      transform="translate(7.5 4.21)"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_438"
                      data-name="Trazado 438"
                      d="M21,16V8a2,2,0,0,0-1-1.73l-7-4a2,2,0,0,0-2,0l-7,4A2,2,0,0,0,3,8v8a2,2,0,0,0,1,1.73l7,4a2,2,0,0,0,2,0l7-4A2,2,0,0,0,21,16Z"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_439"
                      data-name="Trazado 439"
                      d="M3.27,6.96,12,12.01l8.73-5.05"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <line
                      id="Línea_5"
                      data-name="Línea 5"
                      y1="10.08"
                      transform="translate(12 12)"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                  <g
                    id="arrow_back-24px"
                    transform="translate(350.273 241.394) rotate(150)"
                  >
                    <path
                      id="Trazado_433"
                      data-name="Trazado 433"
                      d="M12.28,7.227h-6.4L7.964,5.14a.431.431,0,0,0,0-.607.426.426,0,0,0-.6,0L4.543,7.351a.426.426,0,0,0,0,.6l2.818,2.818a.426.426,0,1,0,.6-.6L5.877,8.082h6.4a.428.428,0,0,0,0-.855Z"
                      transform="translate(-1.505 -2.273)"
                      fill="#ff2633"
                      stroke="#ff2633"
                      stroke-width="0.5"
                    />
                  </g>
                </g>
              </svg>
            </template>
          </v-text-field>
        </v-col>
        <p v-if="measuresError" class="px-4" style="color:#ff2633;font-size:0.75rem;" >Las medidas seleccionadas exceden el máximo permitido</p>
         <v-col cols="12" style="padding-top:0px;padding-bottom:0px">
         <div>
      <h3 class="text-left my-2 px-20 m-h1 border-red primary--text ">
        Seguro
      </h3>
      <div class="hr"></div>
    </div>
    </v-col>
    <v-col cols="12" style="padding-top:0px;padding-bottom:0px">
          <v-text-field
            outlined
            :rules="insuranceRules"
            type="number" pattern="[0-9]*" inputmode="numeric"
            required
            label="Valor declarado *"
            v-model="insurance"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" style="padding-top:0px;padding-bottom:3.125rem">
          <h3 class="text-left my-2 px-20 m-h1 border-red primary--text ">
            Opciones de envío y entrega
          </h3>
          <div class="hr"></div>
          <p class="text-left m-p1 font-weight-medium">
            ¿Retiramos el paquete por tu domicilio?
          </p>
          <v-radio-group :disabled="!isFormValid"
          v-model="carryPackage" >
            <v-row class="ml-0">
              <v-radio
               :disabled="price.PrecioPP == 0 && price.PrecioPT == 0"
              label="Si, retirar por domicilio"
              value="no"></v-radio>
              <p class="add-cost">(+ Costo adicional)</p>
            </v-row>
            <v-radio 
            :disabled="price.PrecioTT == 0 && price.PrecioTP == 0"
            label="No, lo llevo a la terminal" 
            value="yes"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" class="mobile-weight" style="padding-top:0px;padding-bottom:0px">
          <p class="text-left  m-p1 font-weight-medium  ">
            ¿Enviamos el paquete al domicilio del destinatario?
          </p>
          <v-radio-group v-model="seekPackage" :disabled="!isFormValid">
            <v-row class="ml-0">
              <v-radio 
               :disabled="price.PrecioPP == 0 && price.PrecioTP == 0"
              label="Si, llevar a domicilio" 
              value="no"></v-radio>
              <p class="add-cost">(+ Costo adicional)</p>
            </v-row>
            <v-radio 
             :disabled="price.PrecioTT == 0 && price.PrecioPT == 0"
            label="No, lo busca en la terminal"
             value="yes"></v-radio>
          </v-radio-group>
            <p
     class="text-left pl-5"
     style="color:#ff000a;"
      v-if="selectionError"
    >
      La combinación seleccionada no se encuentra disponible, porfavor seleccione otra.
    </p>
        </v-col>
      
      </v-row>
    </v-form>
  </v-container>
  </div>
  <v-container v-else fluid class="px-0"  
  >
    <div>
      <h3 class="mobile text-left my-2 px-20 m-h1 border-red primary--text ">
        Medidas
      </h3>
      <p class="text-left m-p3 mb-3">
        Los campos con (*) asterísco son obligatorios.
      </p>
      <div class="hr"></div>
    </div>
    <v-form v-model="isFormValid">
      <v-row align="center">
        <v-col cols="12" md="4" style="padding-top:0px;padding-bottom:0px">
          <v-text-field
            outlined
            :rules="numberRules"
            required
            label="Alto (cm) *"
            v-model="heigthBox"
          >
            <template slot="append">
              <svg
                style="right: 1rem;bottom:31%;position:absolute"
                xmlns="http://www.w3.org/2000/svg"
                width="28.938"
                height="22.11"
                viewBox="0 0 28.938 22.11"
              >
                <g
                  id="icon_alto"
                  data-name="icon alto"
                  transform="translate(0.289 1.032)"
                >
                  <g id="package2" transform="translate(6.552 -2.002)">
                    <line
                      id="Línea_4"
                      data-name="Línea 4"
                      x1="9"
                      y1="5.19"
                      transform="translate(7.5 4.21)"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_438"
                      data-name="Trazado 438"
                      d="M21,16V8a2,2,0,0,0-1-1.73l-7-4a2,2,0,0,0-2,0l-7,4A2,2,0,0,0,3,8v8a2,2,0,0,0,1,1.73l7,4a2,2,0,0,0,2,0l7-4A2,2,0,0,0,21,16Z"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_439"
                      data-name="Trazado 439"
                      d="M3.27,6.96,12,12.01l8.73-5.05"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <line
                      id="Línea_5"
                      data-name="Línea 5"
                      y1="10.08"
                      transform="translate(12 12)"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                  <g
                    id="arrow_back-24px"
                    transform="translate(8.183 3.489) rotate(90)"
                  >
                    <path
                      id="Trazado_433"
                      data-name="Trazado 433"
                      d="M11.744,7.035H5.778L7.722,5.09a.4.4,0,0,0,0-.566.4.4,0,0,0-.562,0L4.534,7.15a.4.4,0,0,0,0,.562L7.16,10.339a.4.4,0,0,0,.562-.562L5.778,7.832h5.967a.4.4,0,0,0,0-.8Z"
                      transform="translate(-1.505 -2.273)"
                      fill="#ff2633"
                      stroke="#ff2633"
                      stroke-width="0.5"
                    />
                  </g>
                </g>
              </svg>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" style="padding-top:0px;padding-bottom:0px">
          <v-text-field
            outlined
            :rules="numberRules"
            required
            label="Ancho (cm) *"
            v-model="widthBox"
          >
            <template slot="append">
              <svg
                style="right: 1rem;bottom:21%;position:absolute"
                xmlns="http://www.w3.org/2000/svg"
                width="24.493"
                height="28.831"
                viewBox="0 0 24.493 28.831"
              >
                <g
                  id="icon_ancho"
                  data-name="icon ancho"
                  transform="translate(-334.604 -241.968)"
                >
                  <g id="package2" transform="translate(337 240.998)">
                    <line
                      id="Línea_4"
                      data-name="Línea 4"
                      x1="9"
                      y1="5.19"
                      transform="translate(7.5 4.21)"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_438"
                      data-name="Trazado 438"
                      d="M21,16V8a2,2,0,0,0-1-1.73l-7-4a2,2,0,0,0-2,0l-7,4A2,2,0,0,0,3,8v8a2,2,0,0,0,1,1.73l7,4a2,2,0,0,0,2,0l7-4A2,2,0,0,0,21,16Z"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_439"
                      data-name="Trazado 439"
                      d="M3.27,6.96,12,12.01l8.73-5.05"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <line
                      id="Línea_5"
                      data-name="Línea 5"
                      y1="10.08"
                      transform="translate(12 12)"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                  <g
                    id="arrow_back-24px"
                    transform="translate(344.004 273.726) rotate(-150)"
                  >
                    <path
                      id="Trazado_433"
                      data-name="Trazado 433"
                      d="M12.722,7.386H5.959l2.2-2.2a.455.455,0,0,0,0-.641.45.45,0,0,0-.637,0L4.55,7.517a.45.45,0,0,0,0,.637L7.527,11.13a.45.45,0,0,0,.637-.637l-2.2-2.2h6.763a.452.452,0,0,0,0-.9Z"
                      transform="translate(-1.505 -2.273)"
                      fill="#ff2633"
                      stroke="#ff2633"
                      stroke-width="0.5"
                    />
                  </g>
                </g>
              </svg>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" style="padding-top:0px;padding-bottom:0px">
          <v-text-field
            outlined
            :rules="numberRules"
            required
            label="Profundidad (cm) *"
            v-model="depthBox"
          >
            <template slot="append" class="pt-0 mt-0">
              <svg
                style="right: 1rem;bottom:26%;position:absolute"
                xmlns="http://www.w3.org/2000/svg"
                width="23.199"
                height="29.074"
                viewBox="0 0 23.199 29.074"
              >
                <g
                  id="icon_profundidad"
                  data-name="icon profundidad"
                  transform="translate(-335.898 -235.004)"
                >
                  <g id="package2" transform="translate(337 240.998)">
                    <line
                      id="Línea_4"
                      data-name="Línea 4"
                      x1="9"
                      y1="5.19"
                      transform="translate(7.5 4.21)"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_438"
                      data-name="Trazado 438"
                      d="M21,16V8a2,2,0,0,0-1-1.73l-7-4a2,2,0,0,0-2,0l-7,4A2,2,0,0,0,3,8v8a2,2,0,0,0,1,1.73l7,4a2,2,0,0,0,2,0l7-4A2,2,0,0,0,21,16Z"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_439"
                      data-name="Trazado 439"
                      d="M3.27,6.96,12,12.01l8.73-5.05"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <line
                      id="Línea_5"
                      data-name="Línea 5"
                      y1="10.08"
                      transform="translate(12 12)"
                      fill="none"
                      stroke="#0052cc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                  <g
                    id="arrow_back-24px"
                    transform="translate(350.273 241.394) rotate(150)"
                  >
                    <path
                      id="Trazado_433"
                      data-name="Trazado 433"
                      d="M12.28,7.227h-6.4L7.964,5.14a.431.431,0,0,0,0-.607.426.426,0,0,0-.6,0L4.543,7.351a.426.426,0,0,0,0,.6l2.818,2.818a.426.426,0,1,0,.6-.6L5.877,8.082h6.4a.428.428,0,0,0,0-.855Z"
                      transform="translate(-1.505 -2.273)"
                      fill="#ff2633"
                      stroke="#ff2633"
                      stroke-width="0.5"
                    />
                  </g>
                </g>
              </svg>
            </template>
          </v-text-field>
        </v-col>
        <p v-if="measuresError" class="px-4" style="color:#ff2633;font-size:0.75rem;" >Las medidas seleccionadas exceden el máximo permitido</p>
         <v-col cols="12"  style="padding-top:0px;padding-bottom:0px">
         <div>
      <h3 class="text-left my-2 px-20 m-h1 border-red primary--text ">
        Seguro
      </h3>
      <div class="hr"></div>
    </div>
    </v-col>
    <v-col cols="12" md="6" style="padding-top:0px;padding-bottom:0px">
          <v-text-field
            outlined
            :rules="insuranceRules"
            required
            label="Valor declarado *"
            v-model="insurance"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" style="padding-top:0px;padding-bottom:3.125rem">
          <h3 class="text-left my-2 px-20 m-h1 border-red primary--text ">
            Opciones de envío y entrega
          </h3>
          <div class="hr"></div>
          <p class="text-left m-p1 font-weight-medium">
            ¿Retiramos el paquete por tu domicilio?
          </p>
          <v-radio-group :disabled="!isFormValid"
          v-model="carryPackage" >
            <v-row class="ml-0">
              <v-radio
                :disabled="price.PrecioPP == 0 && price.PrecioPT == 0"
              label="Si, retirar por domicilio"
              value="no"></v-radio>
              <p class="add-cost">(+ Costo adicional)</p>
            </v-row>
            <v-radio 
            :disabled="price.PrecioTT == 0 && price.PrecioTP == 0"
            label="No, lo llevo a la terminal" 
            value="yes"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12"  style="padding-top:0px;padding-bottom:0px">
          <p class="text-left  m-p1 font-weight-medium  ">
            ¿Enviamos el paquete al domicilio del destinatario?
          </p>
          <v-radio-group v-model="seekPackage" :disabled="!isFormValid">
            <v-row class="ml-0">
              <v-radio 
              :disabled="price.PrecioPP == 0 && price.PrecioTP == 0"
              label="Si, llevar a domicilio" 
              value="no"></v-radio>
              <p  
              class="add-cost">(+ Costo adicional)</p>
            </v-row>
            <v-radio 
            :disabled="price.PrecioTT == 0 && price.PrecioPT == 0"
            label="No, lo busca en la terminal"
             value="yes"></v-radio>
          </v-radio-group>
        </v-col>
        <p
     class="text-left pl-5"
     style="color:#ff000a;"
      v-if="selectionError"
    >
      La combinación seleccionada no se encuentra disponible, porfavor seleccione otra.
    </p>
      </v-row>
    </v-form>
  </v-container>
  </div>
</template>
<script>
import { localidades, articulos, priceItem , seguro } from "@/services/shipping";
import { isMobile } from 'mobile-device-detect';

export default {
  data: () => ({
    mobile: isMobile ? true : false,
    items: [],
    heigthBox: null,
    widthBox: null,
    depthBox: null,
    insurance:null,
    carryPackage: "no",
    seekPackage: "no",
    subtotal: 0,
    dataLocalidades: [],
    isLoading: false,
    isFormValid: false,
    articulos: [],
    idArticulo: null,
    price: {},
    priceinsurance: 0,
    total:0,
    oldTotal: 0,
    countPriceinsurance: 0,
    numberRules: [
      (v) => !!v || "Este dato es requerido",
      (v) => /^\d+$/.test(v) || "Este campo solo acepta números",
      (v) => v > 0 || "El valor declarado no puede ser $0",
    ],
    insuranceRules:[
       (v) => !!v || "Este dato es requerido",
       (v) => v > 0 || "El valor declarado no puede ser $0",
       (v) => v <= 40000 || "El valor declarado no puede ser mayor a $40.000",
    ],
    measuresError: false,
    selectionError:false,
  }),
  methods: {
    async dataLoc() {
      this.isLoading = true;
      const { data } = await localidades();
      this.dataLocalidades = data;
      if (this.dataLocalidades) {
        this.dataLocalidades.forEach((location) => {
          this.items.push(location.NmLocalidad);
        });
      }
      this.isLoading = false;
    },
    async articulosData() {
      const { data } = await articulos();
      this.articulos = data;
    },
    async getPriceItem() {
      var dataForm = {};
      dataForm.id = this.idArticulo;
      dataForm.start = this.valueStartForm;
      dataForm.end = this.valueEndForm;
      const { data } = await priceItem(dataForm);
      this.price = data;
      if(this.price.PrecioPP == 0 && this.price.PrecioPT == 0){
        this.carryPackage = "yes";
      }
      if(this.price.PrecioPP == 0 && this.price.PrecioTP == 0){
        this.seekPackage = "yes";
      }
      this.$store.commit('valueListPrice',data);
      if(this.price){
         this.$emit("price", true);
      }
      
    },
    async getValueinsurance() {
      var dataForm = this.insurance;
      const { data } = await seguro(dataForm);
       this.priceinsurance =  data.ImpSeguro;  
       this.$store.commit('valueInsurance', data.ImpSeguro);    
    },
    completed() {
      this.$store.commit("disableButtom", false);
    },
    incompleted() {
      this.$store.commit("disableButtom", true);
    },
    getIdArticle() {
      var count = 0;
      var volumen = this.depthBox * this.heigthBox * this.widthBox;
      this.articulos.forEach((articulo) => {
        var articuloMinVolumen = articulo.VolDesde; 
        var articuloMaxVolumen = articulo.VolHasta;
        if (volumen >= articuloMinVolumen && volumen <= articuloMaxVolumen ) {
          this.measuresError = false;
          this.idArticulo = articulo.IdArt;
          this.$store.commit('valueIdArticle', articulo.IdArt);
          count = 1;
        }
        if (count != 1){
        this.measuresError = true;
        }
      });
    },
    sumSubtotal(){
       if (this.carryPackage == "yes" && this.seekPackage == "yes") {
         this.subtotal = this.price.PrecioTT + this.priceinsurance;
         this.$store.commit("valueModality", 1);
      }
      if (this.carryPackage == "no" && this.seekPackage == "yes") {
        this.subtotal = this.price.PrecioPT + this.priceinsurance;
        this.$store.commit("valueModality", 4);
      }
       if (this.carryPackage == "yes" && this.seekPackage == "no") {
        this.subtotal = this.price.PrecioTP + this.priceinsurance;
         this.$store.commit("valueModality", 2);
      }
       if (this.carryPackage == "no" && this.seekPackage == "no") {
        this.subtotal = this.price.PrecioPP + this.priceinsurance;
         this.$store.commit("valueModality", 3);
      }
    }
  },
  computed: {
    valueStartForm() {
      return this.$store.state.valueStartForm;
    },
    valueEndForm(){
      return this.$store.state.valueEndForm;
    },
  calculatorSelected(){
      return this.$store.state.calculatorButton;
    },
  },
  watch: {
    heigthBox() {
      if (this.heigthBox && this.widthBox && this.depthBox ) {
        this.$store.commit('valueHeightBox', this.heigthBox);
        this.$store.commit('valueWidthBox', this.widthBox);
         this.$store.commit('valueDepthBox', this.depthBox);
        this.getIdArticle();
      }
    },
    widthBox() {
      if (this.heigthBox && this.widthBox && this.depthBox) {
        this.$store.commit('valueHeightBox', this.heigthBox);
        this.$store.commit('valueWidthBox', this.widthBox);
         this.$store.commit('valueDepthBox', this.depthBox);
        this.getIdArticle();
      }
    },
    depthBox() {
      if (this.heigthBox && this.widthBox && this.depthBox) {
        this.$store.commit('valueHeightBox', this.heigthBox);
        this.$store.commit('valueWidthBox', this.widthBox);
         this.$store.commit('valueDepthBox', this.depthBox);
        this.getIdArticle();
      }
    },
    insurance(){
        this.$store.commit('valueDeclare', this.insurance);
        this.getValueinsurance();
      },
    carryPackage() {
        this.sumSubtotal();
        this.$store.commit('valueCarryPackage', this.carryPackage);
    },
    seekPackage() {
         this.sumSubtotal();
         this.$store.commit('valueSeekPackage', this.seekPackage);
    },
    priceinsurance(){
          this.sumSubtotal();
    },
    price(){
     this.sumSubtotal();
    },
    subtotal() {
      var total = this.subtotal - this.priceinsurance;
      if(total != 0){
        if(this.subtotal){
         this.$emit("total", this.subtotal);
         this.oldTotal = this.subtotal;
          this.selectionError = false;
          this.completed();
          this.$store.commit("valueFormMeasuresValid", true);
      }
      }
      else{
        this.incompleted();
        this.selectionError = true;

      }
    },

    isFormValid() {
      if (this.isFormValid == true && this.measuresError == false) {
        this.completed();
        if(this.heigthBox){
        this.$store.commit("valueFormMeasuresValid", true);
        }
      } else {
        this.incompleted();
      }
    },
    idArticulo() {
      this.getPriceItem();
    },
   /* eslint-disable */
      valueStartForm(newValue) {
            this.getPriceItem();
        },
   
          valueEndForm(newValue) {
            this.getPriceItem();
        },
        /* eslint-enable */
  },
  created(){
    this.$emit("total", this.subtotal);
    this.articulosData();
  },
};
</script>
<style lang="scss">
.card-total {
  box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.1);
  padding: 1.375rem 1.875rem 1.313rem 1.5rem;
  border-radius: 4px;
  box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.v-input--selection-controls .v-radio > .v-label {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  text-align: left;
}
@media (max-width:420px){
.mobile-weight{
padding-bottom: 68px !important;
}
}
</style>
