<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.193" height="22.11" viewBox="0 0 20.193 22.11">
    <defs>
        <svg:style>
            .cls-shipping{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}
        </svg:style>
    </defs>
    <g id="package2" transform="translate(-1.904 -.97)">
        <path id="Línea_4" d="M9 5.19L0 0" class="cls-shipping" transform="translate(7.5 4.21)"/>
        <path id="Trazado_438" d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" class="cls-shipping"/>
        <path id="Trazado_439" d="M3.27 6.96L12 12.01l8.73-5.05" class="cls-shipping"/>
        <path id="Línea_5" d="M0 10.08L0 0" class="cls-shipping" transform="translate(12 12)"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'ShippingIcon'
  }
</script>