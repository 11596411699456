<template>
  <div class="home" style="display:contents;">
    <div v-if="mobile">
      <v-container class="px-4">
        <v-stepper v-model="e1" style="box-shadow: none;">
          <v-stepper-header elevation="0">
            <div class="d-flex justify-start pt-6 pb-3 ">
              <a v-if="e1 == 1" @click="$router.go(-1)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect width="16" height="16" fill="none" />
                    </clipPath>
                  </defs>
                  <g
                    id="Backward_arrow"
                    data-name="Backward arrow"
                    clip-path="url(#clip-path)"
                  >
                    <path
                      id="Trazado_10"
                      data-name="Trazado 10"
                      d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"
                      transform="translate(16 16) rotate(180)"
                      fill="#2e2e2e"
                    />
                  </g>
                </svg>
              </a>
              <a v-else @click="backForm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect width="16" height="16" fill="none" />
                    </clipPath>
                  </defs>
                  <g
                    id="Backward_arrow"
                    data-name="Backward arrow"
                    clip-path="url(#clip-path)"
                  >
                    <path
                      id="Trazado_10"
                      data-name="Trazado 10"
                      d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"
                      transform="translate(16 16) rotate(180)"
                      fill="#2e2e2e"
                    />
                  </g>
                </svg>
              </a>
            </div>
            <div class="col-11 pt-0 ">
              <div class="d-flex justify-center">
                <row class="d-flex pt-1">
                  <template v-for="n in steps">
                    <v-stepper-step
                      :key="`${n}-step`"
                      :complete="e1 > n"
                      step=""
                      v-if="e1 != n"
                      :class="{ stepStayNone: e1 == n }">
                    </v-stepper-step>
                    <div :key="n" class="stepStayFather" v-if="e1 == n">
                      <div class="stepStay"></div>
                    </div>

                    <v-divider
                      v-if="n != 5"
                      :key="n"
                      class="divider"
                      :class="{ dividerComplete: e1 >= n + 1 }"
                    ></v-divider>
                  </template>
                </row>
              </div>
              <!--  <div class="d-flex justify-center" v-for="n in steps" :key="n">
              <p class="steps" v-if="n == e1">{{ n }} de {{ steps.length }}</p>
            </div> -->
            </div>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              v-for="n in steps"
              :key="`${n}-content`"
              :step="n"
              class="pt-0 px-0"
              :class="{ heightEnd : e1 == 5 ,  HeightDefault : e1 != 5 }"
            >
              <div v-if="n === 1">
                <StartEnd />
              </div>
              <div v-else-if="n === 2">
                <Measures @total="total" @price="price" />
              </div>
              <div v-else-if="n === 3">
                <Receiver
                  :clickButtonNext="buttonNextClient"
                  :stateE1="e1"
                  @finishApiClient="nextClientFinish"
                />
              </div>
              <div v-else-if="n === 4">
                <Remitter
                  :clickButtonNext="buttonNextClient"
                  @finishApiClient="nextClientFinishRemitter"
                />
              </div>
              <div v-else-if="n === 5">
                <Payment />
              </div>
              <div
                class="next-button"
                :style="{
                  bottom: 0 + '%',
                }"
              >
                <v-btn
                  block
                  color="secondary button"
                  @click="nextStep(n)"
                  :disabled="disableButtom"
                  v-if="e1 != 3 && e1 != 4 && shippingnextbutton"
                  class="next-button"
                >
                  Siguiente
                </v-btn>
                <v-btn
                  block
                  color="secondary button"
                  @click="nextStepClient(n)"
                  :disabled="disableButtom"
                  :loading="isLoadingButtonNext"
                  v-else-if="(e1 == 3 || e1 == 4) && shippingnextbutton"
                  class="next-button"
                >
                  Siguiente
                </v-btn>
                <div
                  v-else-if="calculatornextbutton"
                  style="height: 6.5rem !important;"
                >
                  <v-btn
                    block
                    color="secondary button"
                    @click="
                      nextStep(n);
                      clickContinueButton();
                    "
                    :disabled="disableButtom"
                    style="height: 3rem !important; bottom: 2.5rem !important;"
                  >
                    CONTINUAR CON EL ENVÍO
                  </v-btn>
                  <router-link
                    to="/"
                    class="decoration-remove route"
                    style="align-content: center; min-height: 3rem !important;height: 3rem !important;"
                  >
                    <v-btn
                      block
                      color="white"
                      depressed
                      m-button
                      style="height: 3rem !important; margin-bottom: 68px;"
                    >
                      IR AL INICIO
                    </v-btn>
                  </router-link>
                </div>
                <v-btn
                  block
                  color="secondary button"
                  @click="buttonConfirm()"
                  v-else
                  m-button
                >
                  CONFIRMAR
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
      <div
        v-if="e1 == 2"
        class="col-12 py-5 mt-10 px-4"
        style="position : absolute"
        :style="{
          bottom: this.calculatornextbutton == false ? 10 + '%' : 170 + 'px',
        }"
        transition="slide-y-reverse-transition"
      >
        <div class="card-total ">
          <p
            :class="[
              'text-left total',
              {
                'mb-0':
                  (costTotal == 0 && priceFinish == true) || costTotal == 0,
              },
            ]"
          >
            Costo total:
            <span
              v-if="
                (this.costTotal != 0 && this.priceFinish != true) ||
                  this.costTotal != 0
              "
              class="primary--text"
              >${{ costTotal }}</span
            >
            <span v-else class="primary--grey"> - </span>
          </p>
          <p
            v-if="this.costTotal == 0 && this.priceFinish == true"
            class="m-label-small red--text text-left"
          >
            Esta modalidad no esta disponible para las localidades
            seleccionadas.
          </p>
          <p class="text-left text-total">
            Precio del envío según origen y destino seleccionado, medidas del
            paquete y opciones de envío.
          </p>
        </div>
      </div>
      <div
        v-if="e1 == 5"
        class="col-12 py-5 mt-10 px-4"
        style="position: absolute;
        bottom: 17%;"
        transition="slide-y-reverse-transition"
      >
        <div class="card-total ">
          <h3 class="text-left m-h2 ">
            Detalle del envío
          </h3>
          <div class="d-flex">
            <div>
              <div class="d-flex pt-3">
                <p class="m-p2 mb-2 pa-0 col-4 font-weight-bold pr-16">
                  Origen
                </p>
                <p class="m-p2 mb-2 pa-0 col-8 text-left">{{ start }}</p>
              </div>
              <div class="d-flex">
                <p class="m-p2 mb-2 pa-0 col-4 font-weight-bold pr-16">
                  Destino
                </p>
                <p class="m-p2 mb-4 pa-0 col-8 text-left">{{ destination }}</p>
              </div>
            </div>
          </div>
          <div
            v-if="this.seekPackage == 'no' && this.carryPackage == 'no'"
            class="hr mb-4"
          ></div>
          <div class="d-flex">
            <div>
              <div class="d-flex" v-if="this.carryPackage == 'no'">
                <p class="m-p2 mb-2 pa-0 pr-5">Retiro por domicilio</p>
                <p
                  class="m-p2 mb-2 pa-0 text-left secondary--text font-weight-medium"
                ></p>
              </div>
              <div class="d-flex" v-if="this.seekPackage == 'no'">
                <p class="m-p2 mb-2 pa-0 pr-5">Envio a domicilio</p>
                <p
                  class="m-p2 mb-2 pa-0 text-left secondary--text font-weight-medium"
                ></p>
              </div>
            </div>
          </div>
          <div class="hr mb-4"></div>
          <p class="text-center total">
            Costo total: <span class="primary--text">${{ costTotal }}</span>
          </p>
        </div>
      </div>

      <BottomBar v-if="originalHeight <= windowHeight + 100" />
    </div>
    <div v-else class=" bg-shipping">
      <v-container class="mt-16 pt-5 px-4 
      d-flex justify-space-between"
      style="margin-bottom:253px">
       <div class="col-7">
        <div class="d-flex pl-7 justify-start">
          <a @click="$router.go(-1)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect width="16" height="16" fill="none" />
                </clipPath>
              </defs>
              <g
                id="Backward_arrow"
                data-name="Backward arrow"
                clip-path="url(#clip-path)"
              >
                <path
                  id="Trazado_10"
                  data-name="Trazado 10"
                  d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  transform="translate(16 16) rotate(180)"
                  fill="#2e2e2e"
                />
              </g>
            </svg>
          </a>
        </div>
        <h1 class="mb-3  pl-7 text-left primary--text ">Enviar un paquete</h1>
        <v-stepper v-model="e1" vertical style="box-shadow:none">
          <v-stepper-step :complete="e1 > 1" step="1">
          <h2 :class="['m-h2',{'title-steps' : e1 >= 1}]"> Origen y destino </h2>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card
              class="mb-2"
              style="box-shadow:none"
            >
                <StartEnd  v-show="e1 === 1" />
            </v-card>
            <div class="d-flex justify-start">
            <v-btn m-button
              :disabled="disableButtom"
               color="secondary button" @click="e1 = 2">
              Continuar
            </v-btn>
            <v-btn color="secondary" @click="$router.go(-1)" text>
              Cancelar
            </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-step :complete="e1 > 2" step="2">
          <h2 :class="['m-h2 colorgray',{'title-steps' : e1 >= 2}]" >  Medidas </h2>
          </v-stepper-step>

          <v-stepper-content 
          step="2"
           :style="{'border-left' : calculatorSelected ? 'none' : ''}">
            <v-card
              class="mb-2"
              style="box-shadow:none"
            >
             <Measures v-show="e1 === 2"  @total="total" @price="price" />
            </v-card>
            <div v-if="!calculatornextbutton" class="d-flex justify-start">
            <v-btn m-button
            :disabled="disableButtom"
               color="secondary button" @click="e1 = 3">
              Continuar
            </v-btn>
            <v-btn color="secondary" @click="e1 = e1 - 1" text>
              Cancelar
            </v-btn>
            </div>
            <div
                  v-else
                  class="d-flex justify-start"
                >
                  <v-btn
                    color="secondary button"
                    @click="
                      e1 = 3;
                      clickContinueButton();
                    "
                    :disabled="disableButtom"
                  >
                    CONTINUAR CON EL ENVÍO
                  </v-btn>
                  <router-link
                    to="/"
                    class="decoration-remove route"
                  >
                    <v-btn
                      text
                      color="secondary"
                    >
                      IR AL INICIO
                    </v-btn>
                  </router-link>
                </div>
          </v-stepper-content>

          <v-stepper-step v-if="!calculatorSelected" :complete="e1 > 3" step="3">
          <h2 :class="['m-h2 colorgray',{'title-steps' : e1 >= 3}]">  Datos del destinatario 
            </h2>
          </v-stepper-step>

          <v-stepper-content
           :style="{'border-left' : calculatorSelected ? 'none' : ''}"
           step="3">
            <v-card
              class="mb-2"  
              style="box-shadow:none"
            >
            <Receiver
                  v-show="e1 === 3"
                  :clickButtonNext="buttonNextClient"
                  :stateE1="e1"
                  @finishApiClient="nextClientFinish"
                />
            </v-card>
            <div class="d-flex justify-start">
            <v-btn m-button
            @click="nextStepClient(n)"
              :disabled="disableButtom"
                  :loading="isLoadingButtonNext"
               color="secondary button">
              Continuar
            </v-btn>
            <v-btn color="secondary" @click="e1 = e1 - 1" text>
              Cancelar
            </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-step v-if="!calculatorSelected"  :complete="e1 > 4" step="4">
          <h2 :class="['m-h2 colorgray',{'title-steps' : e1 >= 4}]">  Datos del remitente</h2>
                    </v-stepper-step>
          <v-stepper-content 
          :style="{'border-left' : calculatorSelected ? 'none' : ''}"
           step="4">
            <v-card
            style="box-shadow:none"
              class="mb-2"
            > <Remitter
                  v-show="e1 === 4" 
                  :clickButtonNext="buttonNextClient"
                  @finishApiClient="nextClientFinishRemitter"
                />
            </v-card>
            <div class="d-flex justify-start">
            <v-btn m-button
            :disabled="disableButtom"
            @click="nextStepClient(n)"
                  :loading="isLoadingButtonNext"
               color="secondary button">
              Continuar
            </v-btn>
            <v-btn color="secondary" @click="e1 = e1 - 1" text>
              Cancelar
            </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-step v-if="!calculatorSelected" :complete="e1 > 5" step="5">
          <h2 :class="['m-h2 colorgray',{'title-steps' : e1 >= 5}]">  Método de pago
          </h2>     </v-stepper-step>
          <v-stepper-content 
          :style="{'border-left' : calculatorSelected ? 'none' : ''}"
           step="5">
            <v-card
              class="mb-2"
              style="box-shadow:none"
            >
              <Payment v-show="e1 === 5" />
            </v-card>
            <div class="d-flex justify-start">
            <v-btn
            :disabled="disableButtom"
              color="secondary button"
              @click="buttonConfirm()"
              m-button
            >
              CONFIRMAR
            </v-btn>
            <v-btn v-if="cancelbuttonNotOk == false" color="secondary" @click="e1 = e1 - 1" text>
              Cancelar
            </v-btn>
            <v-btn v-else color="secondary" @click="backcancelbuttonNotOk" text>
              Cancelar
            </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper>
       </div>
       <div class="col-4 pt-9">
        <v-card class="pa-3" style="position: fixed; width: 27.813rem;">
          <h3 class="m-h2 text-left pb-3 pl-0">
            Datos de envío
          </h3>
          <div
            class="hr mb-3"
          ></div>
          <div class="d-flex card-detail  px-3 py-3 mb-3">
            <img class="mr-3" src="@/assets/Image/map-card-detail.svg" alt="map card detail">
            <h5 :style="{color: e1 > 1 ? '#2e2e2e' : '#adadad' }">Origen y destino</h5>
          </div>
          <div class="text-left px-3 py-3 mb-3" v-if="e1 >= 2 || (start && destination)">
            <div class="d-flex">
            <h5>Envio desde:</h5> <h5 class="ml-2 font-weight-regular">{{ start }}</h5>
            </div>
            <div class="d-flex">
            <h5>Envio a: </h5>  <h5 class="ml-2 font-weight-regular">{{ destination }}</h5>
            </div>
          </div>
          <div class="d-flex card-detail  px-3 py-3 mb-3">
            <img class="mr-3" src="@/assets/Image/measures-card-detail.svg" alt="measures card detail">
            <h5 :style="{color: e1 > 2 ? '#2e2e2e' : '#adadad' }">Medidas</h5>
          </div>
          <div class="text-left px-3 py-3 mb-3" v-if="e1 >= 3 || costTotal">
            <div class="d-flex justify-space-between mb-4">
            <div class="d-flex">
            <h5>Alto:</h5> <h5 class="ml-2 font-weight-regular">{{ heightBox }} cm.</h5>
            </div>
            <div class="d-flex">
            <h5>Ancho: </h5>  <h5 class="ml-2 font-weight-regular">{{ widthBox }} cm.</h5>
            </div>
            <div class="d-flex">
            <h5>Profundidad: </h5>  <h5 class="ml-2 font-weight-regular">{{ depthBox }} cm.</h5>
            </div>
            </div>
              <div class="mb-3">
              <div class="d-flex" v-if="this.carryPackage == 'no'">
                <p class="m-p2 mb-2 pa-0 pr-5">Retiro por domicilio</p>
                <p
                  class="m-p2 mb-2 pa-0 text-left secondary--text font-weight-medium"
                ></p>
              </div>
              <div class="d-flex" v-if="this.seekPackage == 'no'">
                <p class="m-p2 mb-2 pa-0 pr-5">Envio a domicilio</p>
                <p
                  class="m-p2 mb-2 pa-0 text-left secondary--text font-weight-medium"
                ></p>
              </div>
            </div>
            <div
            class="hr mb-3"
          ></div>
          <h2 class="text-center total mb-3">
            Costo total <br> <span class="primary--text">${{ costTotal }}</span>
          </h2>
          <p>Precio del envío según origen y destino seleccionado, medidas del paquete y opciones de envío.</p>
          </div>
        </v-card>
       </div>
      </v-container>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<!-- eslint-disable -->
<script>
// @ is an alias to /src

import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import BottomBar from "@/components/BottomBar.vue";
import StartEnd from "@/components/FormShipping/StartEnd.vue";
import Measures from "@/components/FormShipping/Measures.vue";
import Receiver from "@/components/FormShipping/Receiver.vue";
import Remitter from "@/components/FormShipping/Remitter.vue";
import Payment from "@/components/FormShipping/Payment.vue";
import { tokenPayment, makePayment, sendEmit } from "@/services/shipping";
import { isMobile } from 'mobile-device-detect';

export default {
  name: "envio",
  components: {
    BottomBar,
    StartEnd,
    Measures,
    Receiver,
    Remitter,
    Payment,
  },
  data() {
    return {
      e1: 1,
      steps: [1, 2, 3, 4, 5],
      costTotal: 0,
      priceFinish: false,
      originalHeight: window.innerHeight,
      windowHeight: window.innerHeight,
      tokenPaymentData: "",
      errorMessageRemit: "",
      urlpayment: "",
      idPayment: "",
      buttonNextClient: false,
      isLoadingButtonNext: false,
      dataFormNotOk: {},
      cancelbuttonNotOk: false,
      overlay: false,
      maxStep:1,
      mobile: isMobile ? true : false,
    };
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
    e1(){
      var valid = false;
      if(this.e1 == 1){
       if( this.validStartForm == true){
         valid = true;
       }
      }
       if(this.e1 == 2){
       if( this.validMeasuresForm == true){
         valid = true;
       }
      }
       if(this.e1 == 3){
       if( this.validReceiverForm == true){
         valid = true;
       }
      }
       if(this.e1 == 4){
       if( this.validRemitterForm == true){
         valid = true;
       }
      }
      if( this.e1 == 5){
        valid = true;
      }
      if(this.maxStep < this.e1){
        this.maxStep = this.e1;
         console.log(this.maxStep ,  ' igual a?');
             console.log(this.maxStep, this.e1,  ' entra?');
             if(this.e1 != 5){
      this.$store.commit("disableButtom", true);
             }
        }
        else{
          console.log('entras aca??',valid)
          this.$store.commit("disableButtom", false);
           if(valid == false){
              console.log('y aca??')
          this.$store.commit("disableButtom", true);
        }
        }
    }
  },
  computed: {
    disableButtom() {
      return this.$store.state.buttomForm;
    },
    start() {
      return this.$store.state.start;
    },
    destination() {
      return this.$store.state.destination;
    },
    priceList() {
      return this.$store.state.listPrice;
    },
    modality() {
      return this.$store.state.modality;
    },
    carryPackage() {
      return this.$store.state.carryPackage;
    },
    seekPackage() {
      return this.$store.state.seekPackage;
    },
    idPayFrom() {
      return this.$store.state.idPayFrom;
    },
    payMethod() {
      return this.$store.state.payMethod;
    },
    idClientRemitter() {
      return this.$store.state.idClientRemitter;
    },
    idClientReceiver() {
      return this.$store.state.idClientReceiver;
    },
    idValueStartForm() {
      return this.$store.state.valueStartForm;
    },
    idValueEndForm() {
      return this.$store.state.valueEndForm;
    },
    valueDeclared() {
      return this.$store.state.valueDeclared;
    },
    valueInsurance() {
      return this.$store.state.insurance;
    },
    idArticle() {
      return this.$store.state.idArticle;
    },
    calculatorSelected() {
      return this.$store.state.calculatorButton;
    },
    shippingnextbutton() {
      if (
        (this.e1 != 5 && this.calculatorSelected == false) ||
        (this.e1 == 1 && this.calculatorSelected == true)
      ) {
        return true;
      } else {
        return false;
      }
    },
    calculatornextbutton() {
      if (this.e1 == 2 && this.calculatorSelected == true) {
        return true;
      } else {
        return false;
      }
    },
    paymentNotOk() {
      return this.$store.state.payNotOk;
    },
    heightBox(){
      return this.$store.state.heightBox;
    },
    widthBox(){
      return this.$store.state.widthBox;
    },
    depthBox(){
      return this.$store.state.depthBox;
    },

    validStartForm(){
      return this.$store.state.formStartValid;
    },
    validMeasuresForm(){
      return this.$store.state.formMeasuresValid;
    },
    validReceiverForm(){
      return this.$store.state.formReceiverValid;
    },
    validRemitterForm(){
      return this.$store.state.formRemitterValid;
    }
  },
  methods: {
    async emit() {
      var dataForm;
      if (Object.keys(this.dataFormNotOk).length === 0) {
        dataForm = {
          IdCliente: this.idClientRemitter,
          IdDestinatario: this.idClientReceiver,
          IdLocDesde: this.idValueStartForm,
          IdLocHasta: this.idValueEndForm,
          IdModalidad: this.modality,
          IdPago: this.idPayFrom,
          ValorDeclarado: this.valueDeclared,
          Seguro: this.valueInsurance,
          Total: this.costTotal,
          Referencia: "",
          IdArt: this.idArticle,
          Cantidad: 1,
          Precio: this.costTotal - this.valueInsurance,
          Pagado: 0,
        };
      } else {
        dataForm = {
          IdCliente: this.dataFormNotOk.IdCliente,
          IdDestinatario: this.dataFormNotOk.IdDestinatario,
          IdLocDesde: this.dataFormNotOk.IdLocDesde,
          IdLocHasta: this.dataFormNotOk.IdLocHasta,
          IdModalidad: this.dataFormNotOk.IdModalidad,
          IdPago: this.dataFormNotOk.IdPago,
          ValorDeclarado: this.dataFormNotOk.ValorDeclarado,
          Seguro: this.dataFormNotOk.Seguro,
          Total: this.dataFormNotOk.Total,
          Referencia: "",
          IdArt: this.dataFormNotOk.IdArt,
          Cantidad: this.dataFormNotOk.Cantidad,
          Precio: this.dataFormNotOk.Precio,
          Pagado: 0,
        };
      }

      const { data } = await sendEmit(dataForm);
      console.log("data", data);
      if (data.codigo != 0) {
        // error al mandar remito
        this.errorMessageRemit = data.mensaje;
        this.overlay = false;
      } else {
        // avanzo a pagina de exito y guardo el remito en el store
        this.$store.commit("valueRemit", data.NroRemito);
        this.overlay = false;
        this.$router.push({
          name: "pago-correcto-efectivo",
          params: { idpago: data.NroRemito },
        });
      }
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
        if(this.maxStep < this.e1){
        this.maxStep = this.e1;
         console.log(this.maxStep ,  ' igual a?');
        }
      }
      if(this.maxStep > this.step){
        console.log(this.maxStep, this.step ,  ' entra?');
      this.$store.commit("disableButtom", true);
      setTimeout(function () {
            window.scrollTo(0, 0);
        },2);
    }},
    nextStepClient(n) {
      this.buttonNextClient = true;
      this.isLoadingButtonNext = true;
    },
    nextClientFinish(data) {
      console.log("datass", data);
      if (data == true) {
        console.log("datass2", data);
        this.buttonNextClient = false;
        this.isLoadingButtonNext = false;
        console.log("e1??", this.e1, this.e1 + 1);
        this.e1 = this.e1 + 1;
         if(this.maxStep < this.e1){
        this.maxStep = this.e1;
         console.log(this.maxStep ,  ' igual a?');
             console.log(this.maxStep, this.e1,  ' entra?');
             if(this.e1 != 5){
      this.$store.commit("disableButtom", true);
             }
        }
      }
    },
    nextClientFinishRemitter(data) {
      console.log("datass remitter", data);
      if (data == true) {
        console.log("datass2", data);
        this.buttonNextClient = false;
        this.isLoadingButtonNext = false;
        console.log("e1??", this.e1, this.e1 + 1);
        this.e1 = this.e1 + 1;
         if(this.maxStep < this.e1){
        this.maxStep = this.e1;
         console.log(this.maxStep ,  ' igual a?');
             console.log(this.maxStep, this.e1,  ' entra?');
             if(this.e1 != 5){
      this.$store.commit("disableButtom", true);
             }
        }
      }
    },
    backForm() {
      this.e1 = this.e1 - 1;
      this.$store.commit("disableButtom", false);
      setTimeout(function () {
            window.scrollTo(0, 0);
        },2);
    },
    total(data) {
      this.costTotal = data;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    price() {
      this.priceFinish = true;
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        this.originalHeight = window.innerHeight;
      } else if (orientation === "landscape-primary") {
        this.originalHeight = window.innerHeight;
      }
    },
    buttonConfirm() {
      this.overlay = true;
      if (this.idPayFrom == 1) {
        console.log("pago en origen", this.idPayFrom);
        if (this.payMethod == "efectivo") {
          console.log("pago efectivo", this.payMethod);
          this.emit();
        } else {
          console.log("pago online", this.payMethod);
          if (Object.keys(this.dataFormNotOk).length === 0) {
          this.writeBaseDate();
          this.callPaymentToken();
          } else {
          this.writeBaseDateNotOk();  
          this.callPaymentToken();
          }
          
        }
      } else {
        console.log("pago en destino", this.idPayFrom);
        this.emit();
      }
    },
    async callPaymentToken() {
      const { data } = await tokenPayment();
      this.tokenPaymentData = data.access_token;
      console.log("el token es:", data.access_token);

      if (this.tokenPaymentData != "") {
        this.buttonPayment();
        console.log("entro el token");
      }
    },
    async buttonPayment() {
      var dataForm;
      var location = window.location.protocol + "//" + window.location.host;
      var id = this.idPayment;

      console.log(location);
      dataForm = {
        nro_cliente_empresa: "0000000015150058293",
        nro_comprobante: this.idPayment,
        Concepto: "pago",
        Importe: this.costTotal,
        URL_OK: location + "/pago-correcto/" + this.idPayment,
        URL_ERROR: location + "/pago-incorrecto/" + this.idPayment,
        IdReferenciaOperacion: this.idPayment,
        Detalle: [],
        token: this.tokenPaymentData,
      };
      try{
      const { data } = await makePayment(dataForm);
      this.urlpayment = data.Url;
      console.log("payment data: ", data);
      console.log("payment dataform: ", dataForm);
      //grabo hash obtenido
      const updates = {};
      updates[`pagoremito/${id}/hash`] = data.Hash;
      console.log(updates);
      firebase.database().ref().update(updates).then(() => {
      console.log('se guardo el hash');
      });
      }
      catch{
      this.overlay = false;
      }
      if (this.urlpayment != "") {
        console.log("la URL es: ", this.urlpayment);

        this.$store.commit("valuePayUrl", this.urlpayment);
        window.location.replace(this.urlpayment);
      }
    },
    getvalueRemit() {
      return this.$store.state.valueRemit;
    },
    generateId() {
      // Modeled after base64 web-safe chars, but ordered by ASCII.
      console.log("generate Id:");
      var PUSH_CHARS =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzfc";

      // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
      var lastPushTime = 0;

      // We generate 72-bits of randomness which get turned into 12 characters and appended to the
      // timestamp to prevent collisions with other clients.  We store the last characters we
      // generated because in the event of a collision, we'll use those same characters except
      // "incremented" by one.
      var lastRandChars = [];

      var now = new Date().getTime();
      var duplicateTime = now === lastPushTime;
      lastPushTime = now;

      var timeStampChars = new Array(8);
      for (var i = 7; i >= 0; i--) {
        timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
        // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
        now = Math.floor(now / 64);
      }
      if (now !== 0)
        throw new Error("We should have converted the entire timestamp.");

      var id = timeStampChars.join("");

      if (!duplicateTime) {
        for (i = 0; i < 12; i++) {
          lastRandChars[i] = Math.floor(Math.random() * 64);
        }
      } else {
        // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
        for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
          lastRandChars[i] = 0;
        }
        lastRandChars[i]++;
      }
      for (i = 0; i < 12; i++) {
        id += PUSH_CHARS.charAt(lastRandChars[i]);
      }
      if (id.length != 20) throw new Error("Length should be 20.");
      this.idPayment = id;
      console.log(this.idPayment);
    },
    writeBaseDate() {
      var id = this.idPayment;
      var dataForm;
      dataForm = {
        IdCliente: this.idClientRemitter,
        IdDestinatario: this.idClientReceiver,
        IdLocDesde: this.idValueStartForm,
        IdLocHasta: this.idValueEndForm,
        IdModalidad: this.modality,
        IdPago: this.idPayFrom,
        ValorDeclarado: this.valueDeclared,
        Seguro: this.valueInsurance,
        Total: this.costTotal,
        Referencia: id,
        IdArt: this.idArticle,
        Cantidad: 1,
        Precio: this.costTotal - this.valueInsurance,
        Remito: "",
        start: this.start,
        destination: this.destination,
        heightBox: this.heightBox,
        widthBox: this.widthBox,
        depthBox: this.depthBox,
        carryPackage: this.carryPackage,
        seekPackage: this.seekPackage,
        hash:"",
        date: Date().toLocaleString(),
      };
      const updates = {};
      updates[`pagoremito/${id}`] = dataForm;
      console.log(updates);
      firebase
        .database()
        .ref()
        .update(updates)
        .then(() => {
          console.log("se guardo");
        });
    },
    writeBaseDateNotOk() {
      var id = this.idPayment;
      var dataForm;
      dataForm = {
        IdCliente: this.dataFormNotOk.IdCliente,
        IdDestinatario: this.dataFormNotOk.IdDestinatario,
        IdLocDesde: this.dataFormNotOk.IdLocDesde,
        IdLocHasta: this.dataFormNotOk.IdLocHasta,
        IdModalidad: this.dataFormNotOk.IdModalidad,
        IdPago: this.dataFormNotOk.IdPago,
        ValorDeclarado: this.dataFormNotOk.ValorDeclarado,
        Seguro: this.dataFormNotOk.Seguro,
        Total: this.dataFormNotOk.Total,
        Referencia: id,
        IdArt: this.dataFormNotOk.IdArt,
        Cantidad: 1,
        Precio: this.dataFormNotOk.Precio,
        Remito: "",
        start: this.dataFormNotOk.start,
        destination: this.dataFormNotOk.destination,
        heightBox: this.dataFormNotOk.heightBox,
        widthBox: this.dataFormNotOk.widthBox,
        depthBox: this.dataFormNotOk.depthBox,
        carryPackage: this.dataFormNotOk.carryPackage,
        seekPackage: this.dataFormNotOk.seekPackage,
        hash:"",
        date: Date().toLocaleString(),
      };
      const updates = {};
      updates[`pagoremito/${id}`] = dataForm;
      console.log(updates);
      firebase
        .database()
        .ref()
        .update(updates)
        .then(() => {
          console.log("se guardo");
        });
    },
    clickContinueButton() {
      this.$store.commit("valueCalculatorButton", false);
    },
    paymentNotOkStatus() {
      console.log("Hay un pago incorrecto?");
      if (this.paymentNotOk != "no") {
        console.log("Si, hay un pago incorrecto");
        console.log(this.paymentNotOk)
        this.e1 = 5;
        firebase
          .database()
          .ref("pagoremito")
          .child(this.paymentNotOk)
          .once("value", (snapshot) => {
            this.dataFormNotOk = snapshot.val();
            console.log(this.dataFormNotOk);
            this.$store.commit("valuePayNotOk", "no");
            this.costTotal =  this.dataFormNotOk.Total;
            this.$store.commit("valueStart", this.dataFormNotOk.start);
            this.$store.commit("valueDestination", this.dataFormNotOk.destination);
            this.$store.commit("valueCarryPackage", this.dataFormNotOk.carryPackage);
            this.$store.commit("valueSeekPackage", this.dataFormNotOk.seekPackage);
            this.$store.commit("valueHeightBox", this.dataFormNotOk.heightBox);
            this.$store.commit("valueWidthBox", this.dataFormNotOk.widthBox);
            this.$store.commit("valueDepthBox", this.dataFormNotOk.depthBox);
            this.cancelbuttonNotOk = true;
            this.$store.commit("disableButtom", false);
          });
      } else {
        console.log("No hay pago incorrecto");
      }
    },
    backcancelbuttonNotOk() {
      window.location.replace("https://sierraspack.com.ar");
    },
  },

  mounted() {
    this.generateId();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    window.addEventListener("orientationchange", this.handleOrientationChange);
    this.paymentNotOkStatus();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="css" scoped>
.carousel {
  padding-bottom: 57px;
}
.theme--dark.v-btn.v-btn--icon {
  color: blue;
}
.v-stepper {
  padding-bottom: 56px !important;
}

.v-btn {
  bottom: 0px !important;
}
.v-stepper__header {
  box-shadow: none;
}
.next-button {
  position: absolute !important;
  width: 100%;
  height: 3rem !important;
  border-radius: 4px;
}
.title-steps{
  color:#0052cc;
  border-left: solid 0.188rem #ff2633;
  padding-left: 0.5rem;
  padding-bottom:0px;
}
.card-detail{
  background: #fafafa;;
}
.bg-shipping{
    background-image: url("../assets/Image/fondo-shipping.svg");
    background-position:center bottom;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.heightEnd{
height:700px;
}  
@media(min-height:850px){
 .heightEnd{
height:800px;
}  
}
@media(min-height:970px){
 .heightEnd{
height:900px;
} 
}
.HeightDefault{
height:100%;
}
</style>
<style lang="css">
@media (min-width: 1024px) {
.v-btn:not(.v-btn--round).v-size--default {
    height: 53px !important;
    width: auto !important;
    padding-left: 35px;
    padding-right: 35px;

}
.v-stepper--vertical .v-stepper__step{
  padding-bottom:0px;
}
.theme--light.v-stepper {
    background: transparent;
}
.colorgray {
  color: #adadad;
}
}
@media (max-width: 1024px) {
  .v-slide-group__content {
    max-width: 100%;
  }
  .v-stepper__wrapper {
    min-height: 540px;
    height: 100%;
  }
  .button {
    height: 2.75rem;
    padding: 0.813rem 9.375rem 0.75rem;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    font-family: Roboto;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 1.25px;
    text-align: center;
    color: #ffffff;
  }
  .stepStay {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    font-size: 0.75rem;
    justify-content: center;
    height: 24px;
    min-width: 24px;
    width: 24px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    background: transparent;
    border: solid 2px #0052cc;
    width: 0.75rem;
    height: 0.75rem;
    min-width: 0.75rem;
  }
  .stepStayFather {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 24px;
    position: relative;
  }
  .divider {
    width: 2.188rem;
    min-width: 2.188rem;
    position: relative;
    margin-left: -23px !important;
    margin-right: -23px !important;
  }
  .dividerComplete {
    border-color: #0052cc !important;
  }

  .theme--light.v-stepper
    .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error)
    .v-stepper__step__step {
    width: 0.75rem;
    height: 0.75rem;
    background-color: #d9d9d9;
  }
  .v-stepper__step__step {
    width: 0.75rem;
    height: 0.75rem;
    min-width: 0.75rem;
    margin: 0 !important;
  }

  .v-stepper__step__step .v-icon.v-icon {
    display: none;
  }
  .steps {
    font-family: Roboto;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.24px;
    text-align: left;
    color: #0052cc;
  }
}
</style>


