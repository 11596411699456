<template>
  <div>
    <div v-if="mobile" class="flex-column">
      <div>
        <v-container class="px-4">
          <div>
            <div class="d-flex justify-start pt-6 pb-3 ">
              <router-link to="/" class="decoration-remove route">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect width="16" height="16" fill="none" />
                    </clipPath>
                  </defs>
                  <g
                    id="Backward_arrow"
                    data-name="Backward arrow"
                    clip-path="url(#clip-path)"
                  >
                    <path
                      id="Trazado_10"
                      data-name="Trazado 10"
                      d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"
                      transform="translate(16 16) rotate(180)"
                      fill="#2e2e2e"
                    />
                  </g>
                </svg>
              </router-link>
            </div>
            <v-container fluid class="px-0">
              <div>
                <h3 class="text-left my-2 px-20 m-h1 border-red primary--text">
                  Contacto
                </h3>
                <p class="text-left m-p1">
                  ¡Te compartimos nuestros medios de contacto!
                </p>
                <div class="hr"></div>
              </div>
            </v-container>
          </div>

          <v-container fill-height fill-weight class="px-0 py-0">
            <button
              class="contact-button"
              role="link"
              onclick="window.location='https://api.whatsapp.com/send?phone=5493516560390'"
            >
              <v-row align="center" justify="center">
                <v-col cols="2" class="py-0 pr-0">
                  <img
                    alt="Whatsapp"
                    src="../assets/Image/Contact/icon_wp.svg"
                  />
                </v-col>
                <v-col cols="8" class="py-0 px-0">
                  <p class="text-left m-label-small mb-0">
                    Whatsapp
                  </p>
                  <p class="text-left m-p1 mb-0">
                    +549 3516560390
                  </p>
                </v-col>
                <v-col cols="2" class="py-0">
                  <img src="../assets/Image/Contact/chevron-right.svg" />
                </v-col>
              </v-row>
            </button>
          </v-container>

          <v-container fill-height fill-weight class="px-0 py-0">
            <button
              class="contact-button"
              role="link"
              onclick="window.location.href ='tel:08005553928'"
            >
              <v-row align="center" justify="center">
                <v-col cols="2" class="py-0 pr-0">
                  <img alt="Mail" src="../assets/Image/Contact/icon_phone-mobile.svg" />
                </v-col>
                <v-col cols="8" class="py-0 px-0">
                  <p class="text-left m-label-small mb-0">
                    Telefono
                  </p>
                  <p class="text-left m-p1 mb-0">
                    0800-555-3928
                  </p>
                </v-col>
                <v-col cols="2" class="py-0">
                  <img src="../assets/Image/Contact/chevron-right.svg" />
                </v-col>
              </v-row>
            </button>
          </v-container>

          <v-container fill-height fill-weight class="px-0 py-0">
            <button
              class="contact-button"
              role="link"
              onclick="window.location.href ='mailto:encomiendas@grupo-sierras.com.ar?subject=Consulta Sierras Pack'"
            >
              <v-row align="center" justify="center">
                <v-col cols="2" class="py-0 pr-0">
                  <img alt="Mail" src="../assets/Image/Contact/icon_mail.svg" />
                </v-col>
                <v-col cols="8" class="py-0 px-0">
                  <p class="text-left m-label-small mb-0">
                    Mail
                  </p>
                  <p class="text-left m-p1 mb-0">
                    encomiendas@grupo-sierras.com.ar
                  </p>
                </v-col>
                <v-col cols="2" class="py-0">
                  <img src="../assets/Image/Contact/chevron-right.svg" />
                </v-col>
              </v-row>
            </button>
          </v-container>

          <v-container fill-height fill-weight class="px-0 py-0">
            <button type="button" class="contact-button">
              <v-row align="center" justify="center">
                <v-col cols="2" class="py-0 pr-0">
                  <img
                    alt="Direccion"
                    src="../assets/Image/Contact/icon_map.svg"
                  />
                </v-col>
                <v-col cols="8" class="py-0 pl-0">
                  <v-select
                    cols="8"
                    v-model="select"
                    :items="items"
                    item-text="location"
                    item-value="src"
                    label="Localidad"
                    hide-details
                    flat
                    background-color="#fafafa"
                    append-icon
                    return-object
                  >
                  </v-select>
                </v-col>
                <v-col cols="2" class="py-0">
                  <img src="../assets/Image/Contact/chevron-right.svg" />
                </v-col>
              </v-row>
            </button>
          </v-container>
          <div v-if="select.boleteria != 'Aún no disponemos de boletería.'">
          <iframe
            :src="select.src"
            width="100%"
            height="320px"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
            class="pb-4"
          ></iframe>
          </div>
         <div v-else style="width: 100%;height: 320px;" class="m-empty-map">
         </div>
          <p class='m-p1 pt-2 text-left' style="padding-bottom: 100px;"> 
            <span style="color: #ff2633; font-weight: 1000;"> · </span><b> Envío a terminal: </b>{{ select.a_terminal}}<br>
            <span style="color: #ff2633; font-weight: 1000;"> · </span><b> Envío a domicilio: </b>{{ select.a_domicilio}}<br>
            <span style="color: #ff2633; font-weight: 1000;"> · </span><b> Boletería: </b>{{ select.boleteria}}
          </p>
        </v-container>
        <BottomBar />
      </div>
    </div>
    <div v-else>
      <div class="mt-12 col-12 px-0 ">
        <div v-if="select.boleteria != 'Aún no disponemos de boletería.'"
             style="height: 100vh;height: 55vh;">
          <iframe
            :src="select.src"
            width="100%"
            height="100%"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
        <div v-else style="height: 100vh;height: 55vh;" class="empty-map">
        </div>
        <div class="bg-contact">
          <div style="position: relative;bottom: 51px;">
          <div class="d-flex justify-center">
            <div
              class="col-8 grid justify-center white"
              style=" border-radius: 4px;
               box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.1);"
            >
              <button type="button" class="col-8 contact-button">
                <v-row align="center" justify="center">
                  <v-col cols="2" class="py-0 pr-0">
                    <img
                      alt="Direccion"
                      src="../assets/Image/Contact/icon_map.svg"
                    />
                  </v-col>
                  <v-col cols="8" class="py-0 pl-0">
                    <v-select
                      cols="8"
                      v-model="select"
                      :items="items"
                      item-text="location"
                      item-value="src"
                      label="Localidad"
                      hide-details
                      flat
                      background-color="white"
                      append-icon
                      return-object
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <img src="../assets/Image/Contact/chevron-right.svg" />
                  </v-col>
                </v-row>
              </button>
               <pre class='m-p1'><b>Envío a terminal: </b>{{ select.a_terminal}}<span style="color: #ff2633; font-weight: 1000;">   |   </span><b>Envío a domicilio: </b>{{ select.a_domicilio}}<span style="color: #ff2633; font-weight: 1000;">   |   </span><b>Boletería: </b>{{ select.boleteria}}</pre>
            </div>
          </div>
          </div>
          <v-container class="px-0 py-0">
            <button
              class="col-12"
              role="link"
              onclick="window.open('https://api.whatsapp.com/send?phone=5493516560390')"
              style="outline:none;"
            >
              <v-row align="center" justify="center">
                <v-col cols="12" class="py-0 pr-0">
                  <img
                    alt="Whatsapp"
                    src="../assets/Image/Contact/wp-desktop.svg"
                  />
                </v-col>
                <v-col cols="12" class="py-0 px-0 ">
                  <p class="text-center white--text m-p1 mb-0">
                    +549 351 6560390
                  </p>
                </v-col>
              </v-row>
            </button>

            <button
              role="link"
              onclick="window.location.href ='tel:08005553928'"
               class="col-12 mt-8"
               target="_blank"
               style="outline:none; -webkit-user-select: text;  -moz-user-select: text; -ms-user-select: text; user-select: text;"
            >
              <v-row align="center" justify="center">
                <v-col cols="12" class="py-0 pr-0">
                  <img alt="Mail" src="../assets/Image/Contact/icon_phone.svg" />
                </v-col>
                <v-col cols="12" class="py-0 px-0">
                  <p class="text-center m-p1 mb-0 white--text">
                    0800-555-3928
                  </p>
                </v-col>
              </v-row>
            </button>

            <button
              role="link"
              onclick="window.location.href ='mailto:encomiendas@grupo-sierras.com.ar?subject=Consulta Sierras Pack'"
               class="col-12 my-8"
               target="_blank"
               style="outline:none; -webkit-user-select: text;  -moz-user-select: text; -ms-user-select: text; user-select: text;"
            >
              <v-row align="center" justify="center">
                <v-col cols="12" class="py-0 pr-0">
                  <img alt="Mail" src="../assets/Image/Contact/mail-desktop.svg" />
                </v-col>
                <v-col cols="12" class="py-0 px-0">
                  <p class="text-center m-p1 mb-0 white--text">
                    encomiendas@grupo-sierras.com.ar
                  </p>
                </v-col>
              </v-row>
            </button>
          </v-container>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import BottomBar from "@/components/BottomBar.vue";
import { isMobile } from 'mobile-device-detect';

export default {
  name: "contacto",
  components: {
    BottomBar,
  },
  data() {
    return {
      mobile: isMobile ? true : false,
      select: {
        "location": "CORDOBA",
        "a_terminal": "SI",
        "a_domicilio": "SI",
        "boleteria": "SIERRAS DE CALAMUCHITA, BOL 142",
        "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.6379045938857!2d-64.17552368515796!3d-31.424101003768875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432a2964ded66a1%3A0xa358b1858430086f!2sTerminal%20de%20%C3%93mnibus%20Bicentenario%20%5BT2%5D%20%7C%20Terminal%20de%20%C3%93mnibus%20C%C3%B3rdoba%20S.E.!5e0!3m2!1ses!2sar!4v1611847338068!5m2!1ses!2sar"
      },
      "items": [
        {
            "location": "ADELIA MARIA",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.070988511117!2d-64.02942298508553!3d-33.62940131410417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95ce36eccdd517f5%3A0x7f0dab897230507b!2sINTA!5e0!3m2!1ses!2sar!4v1611845796743!5m2!1ses!2sar"
        },
        {
            "location": "AGUA DE ORO",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3417.652402958499!2d-64.30025400566863!3d-31.063774828232344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94327fb01fe11267%3A0x87800d54529f90d0!2sTerminal%20de%20Omnibus!5e0!3m2!1ses!2sar!4v1611845862364!5m2!1ses!2sar"
        },
        {
            "location": "ALCIRA GIGENA",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3355.403221918069!2d-64.3412751851147!3d-32.755033769543545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d27580b7df26ef%3A0xec44388ceea20900!2sTerminal%20de%20Omnibus%20de%20Alcira%20Gigena!5e0!3m2!1ses!2sar!4v1611846019573!5m2!1ses!2sar"
        },
        {
            "location": "ALMAFUERTE",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3376.4985075639656!2d-64.2582886851332!3d-32.190795441354474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cd6158468e6af3%3A0x2ceb641f6457c096!2sAlmafuerte!5e0!3m2!1ses!2sar!4v1611846367298!5m2!1ses!2sar"
        },
        {
            "location": "ALTA GRACIA",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS DE CALAMUCHITA",
            "src": "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3396.2364997454542!2d-64.44871508515041!3d-31.654748814988643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2sar!4v1611846694276!5m2!1ses!2sar"
        },
        {
            "location": "ARROYITO",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.6922531381083!2d-63.04747638515798!3d-31.42260400369633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94334ddbbdf40ceb%3A0x826b56b19ed8d4d6!2sTerminal%20de%20Omnibus%20de%20Arroyito!5e0!3m2!1ses!2sar!4v1611846725135!5m2!1ses!2sar"
        },
        {
            "location": "BALLESTEROS",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "BALNEARIA",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3419.5939407481374!2d-62.66837348517101!3d-31.009697783800004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x943499951c61df77%3A0xd16f484c1694444b!2sTerminal%20de%20Omnibus%20de%20Balnearia!5e0!3m2!1ses!2sar!4v1611846860557!5m2!1ses!2sar"
        },
        {
            "location": "BELL VILLE",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "BERROTARAN",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3366.7526531109274!2d-64.38805518512467!3d-32.45255485437643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d280f54723360d%3A0x9670157f98e83249!2sTerminal%20De%20Berrotar%C3%A1n!5e0!3m2!1ses!2sar!4v1611846905805!5m2!1ses!2sar"
        },
        {
            "location": "BIALTE MASET",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "BRINCKMAN",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3424.7791682534867!2d-62.03754618517576!3d-30.864857176878065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94351e673e2e04b3%3A0xad044b45d473f13c!2sTerminal%20de%20Omnibus%20de%20Brinkmann!5e0!3m2!1ses!2sar!4v1611846942573!5m2!1ses!2sar"
        },
        {
            "location": "CAPILLA DEL MONTE",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "CARLOS PAZ",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.840354737383!2d-64.49651848515813!3d-31.418524303498486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942d6643a10c12c7%3A0xf59da578f71d5a75!2sTerminal%20De%20Omnibus!5e0!3m2!1ses!2sar!4v1611847261460!5m2!1ses!2sar"
        },
        {
            "location": "CASA GRANDE",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "CORDOBA",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS DE CALAMUCHITA, BOL 142",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.6379045938857!2d-64.17552368515796!3d-31.424101003768875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432a2964ded66a1%3A0xa358b1858430086f!2sTerminal%20de%20%C3%93mnibus%20Bicentenario%20%5BT2%5D%20%7C%20Terminal%20de%20%C3%93mnibus%20C%C3%B3rdoba%20S.E.!5e0!3m2!1ses!2sar!4v1611847338068!5m2!1ses!2sar"
        },
        {
            "location": "COSQUIN",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "CUESTA BLANCA",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "DEAN FUNES",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3440.385135248308!2d-64.34916204312691!3d-30.425182301525588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942dfffcac466eed%3A0x22c6df166db0e15a!2sTerminal%20de%20%C3%93mnibus%20de%20Dean%20Funes!5e0!3m2!1ses!2sar!4v1611847377041!5m2!1ses!2sar"
        },
        {
            "location": "DEVOTO",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3405.228309441131!2d-62.30881248515838!3d-31.40783520298046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cb322c5802310f%3A0x71df0d555013d9e8!2sTerminal%20de%20Omnibus!5e0!3m2!1ses!2sar!4v1611847551353!5m2!1ses!2sar"
        },
        {
            "location": "EL TIO ",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3406.0928586400582!2d-62.83044238515912!3d-31.384003001826002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x943355f5bec646cf%3A0xc09b66e2e7164402!2sTerminal%20De%20%C3%93mnibus%20El%20T%C3%ADo!5e0!3m2!1ses!2sar!4v1611847601932!5m2!1ses!2sar"
        },
        {
            "location": "ELCANO",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d262557.2834441849!2d-63.68237744198271!3d-30.158795742620715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9431821b06291031%3A0x15a0568a83e18568!2sSebastian%20Elcano%2C%20C%C3%B3rdoba!5e0!3m2!1ses!2sar!4v1612190680132!5m2!1ses!2sar"
        },
        {
            "location": "ELENA",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3362.464987784649!2d-64.39589528512087!3d-32.56712116010606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d2792e615c7e69%3A0x88855a054f98a67d!2sTerminal%20De%20Omnibus%20De%20Elena!5e0!3m2!1ses!2sar!4v1611847639166!5m2!1ses!2sar"
        },
        {
            "location": "EMBALSE",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS DE CALAMUCHITA",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11031.75666059922!2d-64.40636820295087!3d-32.20786321193991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d29f5380940543%3A0x7b070f5fa4a69a8a!2sTerminal%20de%20omnibus%20embalse!5e0!3m2!1ses!2sar!4v1611847684482!5m2!1ses!2sar"
        },
        {
            "location": "ESTACION CAROYA",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "GENERAL CABRERA",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "SIERRAS DE CALAMUCHITA",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3353.1953049566227!2d-63.881217185112696!3d-32.81358977249447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cdc390ffa2ad97%3A0xd530fe676c55def2!2sTerminal%20De%20Omnibus%20General%20Cabrera!5e0!3m2!1ses!2sar!4v1611847769537!5m2!1ses!2sar"
        },
        {
            "location": "GENERAL DEHEZA",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "SIERRAS DE CALAMUCHITA",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3355.3903290774138!2d-63.79534928511471!3d-32.7553759695607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cdc60d4d5666ff%3A0x49662f2a75e9f955!2sTerminal%20De%20Omnibus%20de%20General%20Deheza!5e0!3m2!1ses!2sar!4v1611847812376!5m2!1ses!2sar"
        },
        {
            "location": "GENERAL PAZ",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "HUANCHILLA",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3320.5494851625313!2d-63.6369052156806!3d-33.66883342892378!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cfab1884094c5b%3A0xe6ad35f20f6d262e!2sJulio%20Argentino%20Roca%2C%20Huanchillas%2C%20C%C3%B3rdoba!5e0!3m2!1ses!2sar!4v1611854706549!5m2!1ses!2sar"
        },
        {
            "location": "HUERTA GRANDE",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3417.387738351655!2d-64.49188008516906!3d-31.071139886745293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942d83df85943daf%3A0x72e09d2355e96c61!2sTerminal%20de%20%C3%B3mnibus!5e0!3m2!1ses!2sar!4v1611847857950!5m2!1ses!2sar"
        },
        {
            "location": "HUINCA RENANCO",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3274.6129121454514!2d-64.3701600850441!3d-34.840823777588625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95dacbd7e6c1ea4b%3A0xcee7dfe91dca8966!2sTerminal%20de%20Omnibus%20de%20Huinca%20Renanco!5e0!3m2!1ses!2sar!4v1611847885103!5m2!1ses!2sar"
        },
        {
            "location": "ICHO CRUZ",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "JESUS MARIA",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3420.642506094233!2d-64.09671818517205!3d-30.980457082400125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432639b864ca6fd%3A0x454342d8b89e7099!2sTerminal%20de%20%C3%93mnibus%20de%20Jes%C3%BAs%20Mar%C3%ADa!5e0!3m2!1ses!2sar!4v1611847909359!5m2!1ses!2sar"
        },
        {
            "location": "JOVITA",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3287.5341738524226!2d-63.942965685055384!3d-34.514692060299524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95c567997e40fcad%3A0x4bfc020cca1f5836!2sTerminal%20De%20Omnibus!5e0!3m2!1ses!2sar!4v1611847985911!5m2!1ses!2sar"
        },
        {
            "location": "JUAREZ CELMAN",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "LA CALERA",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "LA CUMBRE",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3420.542280640503!2d-64.496961485172!3d-30.98325308253394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942d856c480484dd%3A0xbf16818d06d98a71!2sTerminal%20de%20Omnibus!5e0!3m2!1ses!2sar!4v1611848014352!5m2!1ses!2sar"
        },
        {
            "location": "LA DORMIDA",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3442.7322270060827!2d-63.952721585191526!3d-30.358559152917433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432202268b27ad1%3A0x563f115c2f69424e!2sTerminal%20De%20Omnibus!5e0!3m2!1ses!2sar!4v1611848056256!5m2!1ses!2sar"
        },
        {
            "location": "LA FALDA",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "LA FRANCIA",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3405.316118335384!2d-62.6282979!3d-31.405415399999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cb4d52ccc32d3b%3A0xb9678ac7f87a5981!2sEstaci%C3%B3n%20De%20%C3%93mnibus%20La%20Francia!5e0!3m2!1ses!2sar!4v1611848090286!5m2!1ses!2sar"
        },
        {
            "location": "LA PARA",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3423.759336908822!2d-63.00326148517477!3d-30.893392778239388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x943379f711414e5d%3A0x9c667b65c04bedcb!2sTerminal%20De%20%C3%93mnibus!5e0!3m2!1ses!2sar!4v1611848134789!5m2!1ses!2sar"
        },
        {
            "location": "LA PUERTA",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3423.862328540259!2d-63.25582368517488!3d-30.890512078101914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94330a5e33e662ed%3A0x1bcaec0bd3c2d36d!2sTerminal%20De%20Omnibus%20La%20Puerta!5e0!3m2!1ses!2sar!4v1611848158587!5m2!1ses!2sar"
        },
        {
            "location": "LABOULAYE",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3302.4650982682633!2d-63.39215238506838!3d-34.13444124032537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cf64d51c0ca325%3A0xc2322b95a67bb398!2sTerminal%20de%20Omnibus%20-%20Ciudad%20de%20Laboulaye!5e0!3m2!1ses!2sar!4v1611848183577!5m2!1ses!2sar"
        },
        {
            "location": "LAS ARRIAS",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3441.895746138482!2d-63.598035685190744!3d-30.382318154033786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94322d0e288f090b%3A0xa22a56169850668c!2sTerminal%20de%20omnibus!5e0!3m2!1ses!2sar!4v1611848204649!5m2!1ses!2sar"
        },
        {
            "location": "LAS PEÑAS",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27486.270262416863!2d-64.01730932877472!3d-30.555412322349635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x943216537ce5d9d7%3A0x560e843fdfdbbfdd!2zTGFzIFBlw7FhcywgQ8OzcmRvYmE!5e0!3m2!1ses!2sar!4v1612190740333!5m2!1ses!2sar"
        },
        {
            "location": "LAS PERDICES",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "SIERRAS DE CALAMUCHITA",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3357.550753493189!2d-63.71140808511658!3d-32.69798986667331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cdc9a4f7208b8d%3A0xc402b626fd72d97a!2sTERMINAL%20DE%20%C3%93MNIBUS!5e0!3m2!1ses!2sar!4v1611848242350!5m2!1ses!2sar"
        },
        {
            "location": "LEONES",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "LOS COCOS",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "MARCOS JUAREZ",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "MATTALDI",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3288.7970779689285!2d-64.1719026491046!3d-34.482671685759236!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95c5570cad8923cd%3A0xb61741894d89187d!2sX6271%2C%20Av.%20Belgrano%20336%2C%20X6271%20Mattaldi%2C%20C%C3%B3rdoba!5e0!3m2!1ses!2sar!4v1611854732798!5m2!1ses!2sar"
        },
        {
            "location": "MAYU SUMAJ",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "MIRAMAR",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3132.709960011234!2d-57.84527898492129!3d-38.26303236765623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9585108ddac7d5c5%3A0x70c3c672638450f9!2sTerminal%20de%20%C3%93mnibus%20de%20Miramar!5e0!3m2!1ses!2sar!4v1611848319080!5m2!1ses!2sar"
        },
        {
            "location": "MOLINARI",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13652.086940418074!2d-64.48278507197587!3d-31.192410697981096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942d790b84581837%3A0x84a4646f1c205af0!2sMolinari%2C%20C%C3%B3rdoba!5e0!3m2!1ses!2sar!4v1612190763011!5m2!1ses!2sar"
        },
        {
            "location": "MONTE CRISTO",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3407.476827097858!2d-63.947769585160394!3d-31.345818599978035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432eaba1698e891%3A0xb5075ad4f0ded519!2sTerminal%20De%20Omnibus%20Monte%20Cristo!5e0!3m2!1ses!2sar!4v1611848371340!5m2!1ses!2sar"
        },
        {
            "location": "MORRISON",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "MORTEROS",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6860.435347721615!2d-62.00517852561!3d-30.712280452514893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9435039efeda6997%3A0xfe792c13cd087bb9!2sTerminal%20Morteros!5e0!3m2!1ses!2sar!4v1612189706140!5m2!1ses!2sar"
        },
        {
            "location": "OBISPO TREJO",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13711.112184209462!2d-63.42451137218456!3d-30.780808320876986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9433af72fb90a365%3A0x11fe01035b49075f!2sObispo%20Trejo%2C%20C%C3%B3rdoba!5e0!3m2!1ses!2sar!4v1612189749087!5m2!1ses!2sar"
        },
        {
            "location": "POTRERO DE GARAY",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "SIERRAS DE CALAMUCHITA",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3391.6600059217685!2d-64.54670258484298!3d-31.779757881284624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzHCsDQ2JzQ3LjEiUyA2NMKwMzInNDAuMyJX!5e0!3m2!1ses!2sar!4v1612190937963!5m2!1ses!2sar"
        },
        {
            "location": "RAMON J CARCANO",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "RIO CEBALLOS ",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3413.9626062529783!2d-64.32241578516606!3d-31.16631279131813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x943281e99aee3f1b%3A0x1f90607a40b760c0!2sTerminal%20de%20%C3%93mnibus%20R%C3%ADo%20Ceballos!5e0!3m2!1ses!2sar!4v1611848428450!5m2!1ses!2sar"
        },
        {
            "location": "RIO CUARTO",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3341.812549365666!2d-64.35392238510275!3d-33.114011587709484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d201a4c88c27ed%3A0x1762b13608a8dfa2!2sTerminal%20de%20Omnibus%20de%20Rio%20Cuarto!5e0!3m2!1ses!2sar!4v1611850507321!5m2!1ses!2sar"
        },
        {
            "location": "RIO PRIMERO",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3414.4267474175863!2d-63.408559285166476!3d-31.15343119069847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94331a1cf44b40ef%3A0x36dbc365b99eec3e!2sTerminal%20De%20Omnibus%20Villa%20Santa%20Rosa!5e0!3m2!1ses!2sar!4v1611850563367!5m2!1ses!2sar"
        },
        {
            "location": "RIO SECO",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3458.5713486351115!2d-63.72747668520558!3d-29.905449531785216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9431a2fee02e0fc5%3A0x5ca97ef69d6e0de3!2sTerminal%20De%20Omnibus!5e0!3m2!1ses!2sar!4v1611850587370!5m2!1ses!2sar"
        },
        {
            "location": "RIO TERCERO",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS DE CALAMUCHITA",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3377.3707406062263!2d-64.12116348513395!3d-32.1672759401892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cd6f341ab4c88b%3A0xcec0bbdcb0866943!2sTerminal%20%C3%93mnibus%20R%C3%ADo%20Tercero!5e0!3m2!1ses!2sar!4v1611850612372!5m2!1ses!2sar"
        },
        {
            "location": "S.F.DEL CHAÑAR",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3462.626305951753!2d-63.94450808520919!3d-29.788445326376323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9431b74371be6f4d%3A0xb067698cb3b1a8!2sTerminal%20de%20Omnibus!5e0!3m2!1ses!2sar!4v1611850698125!5m2!1ses!2sar"
        },
        {
            "location": "SALSIPUEDES",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3414.911047768801!2d-64.29287498516697!3d-31.13998499005182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x943281aa65b82313%3A0xf863a299da9236cf!2sTerminal%20de%20%C3%B3mnibus%20Salsipuedes!5e0!3m2!1ses!2sar!4v1611850647006!5m2!1ses!2sar"
        },
        {
            "location": "SAN ANTONIO",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "SAN BASILIO",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3327.077047675165!2d-64.3173860013485!3d-33.49937249122101!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95ce206a250b6d67%3A0x79b6901995e24888!2sMariano%20Moreno%2C%20San%20Basilio%2C%20C%C3%B3rdoba!5e0!3m2!1ses!2sar!4v1611854677839!5m2!1ses!2sar"
        },
        {
            "location": "SAN FRANSISCO",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "SANTA CRUZ DEL LAGO",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "SANTA MARIA DE PUNILLA",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "SANTA ROSA DE CALAMUCHITA",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "SIERRAS DE CALAMUCHITA",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380.831982736037!2d-64.53387178513698!3d-32.073792835565165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d2bb5231aad6f9%3A0x3388a33c363af13a!2sTerminal%20de%20%C3%93mnibus%20de%20Santa%20Rosa%20de%20Calamuchita!5e0!3m2!1ses!2sar!4v1611850959795!5m2!1ses!2sar"
        },
        {
            "location": "SANTA ROSA DE RIO PRIMERO",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3414.4267474175863!2d-63.408559285166476!3d-31.15343119069847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94331a1cf44b40ef%3A0x36dbc365b99eec3e!2sTerminal%20De%20Omnibus%20Villa%20Santa%20Rosa!5e0!3m2!1ses!2sar!4v1612189882920!5m2!1ses!2sar"
        },
        {
            "location": "SERRANO",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3289.208112829491!2d-63.54156108505687!3d-34.472244458059876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95c59db3b4bc02b7%3A0xa2475a545b025f33!2sTerminal%20De%20%C3%93mnibus!5e0!3m2!1ses!2sar!4v1611851075427!5m2!1ses!2sar"
        },
        {
            "location": "TANTI.",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13627.91762126934!2d-64.59028602189052!3d-31.359547529578375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942d6e446cbc9341%3A0x64bcf37bab93c693!2sTerminal%20Omnibus%20de%20Tanti!5e0!3m2!1ses!2sar!4v1611851267107!5m2!1ses!2sar"
        },
        {
            "location": "TOTORAL",
            "a_terminal": "SI",
            "a_domicilio": "NO",
            "boleteria": "FONO BUS",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.53024329285!2d-64.07032908518072!3d-30.703490569201627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432134d2867eda1%3A0xd7c6a5ad8ec3bf6e!2sTerminal%20Omnibus%20Villa%20Del%20Totoral!5e0!3m2!1ses!2sar!4v1611851394459!5m2!1ses!2sar"
        },
        {
            "location": "VALLE HERMOSO",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "VICUÑA MACKENNA",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3310.925376152987!2d-64.39297988507576!3d-33.917320129009504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d0297135f99d33%3A0xeae73e1c750ddb68!2sTerminal%20de%20%C3%93mnibus%20de%20Vicu%C3%B1a%20Mackenna!5e0!3m2!1ses!2sar!4v1611851291740!5m2!1ses!2sar"
        },
        {
            "location": "VILLA DEL DIQUE",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS DE CALAMUCHITA",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d108065.76126081287!2d-64.52253234179686!3d-32.175790500000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d298c175db5f19%3A0x23fb8889df59a8fd!2sTerminal%20De%20Villa%20Del%20Dique!5e0!3m2!1ses!2sar!4v1611851361986!5m2!1ses!2sar"
        },
        {
            "location": "VILLA GARCIA ",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "VILLA GENERAL BELGRANO",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS DE CALAMUCHITA",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3384.5233867161096!2d-64.56416168514018!3d-31.973824430633893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d2b0452f3c7a91%3A0x619ccc236a1b5c9a!2sTerminal%20de%20Omnibus%20Villa%20Gral.%20Belgrano!5e0!3m2!1ses!2sar!4v1611851441265!5m2!1ses!2sar"
        },
        {
            "location": "VILLA GIARDINO",
            "a_terminal": "NO",
            "a_domicilio": "SI",
            "boleteria": "Aún no disponemos de boletería.",
            "src": "../assets/Image/Contact/empty-state-map.svg"
        },
        {
            "location": "VILLA HUIDOBRO",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS SUR",
            "src": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d700.3366989868325!2d-64.5857612!3d-34.8403013!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95dab88d7d399379%3A0x8554bfd813bd66ae!2sTerminal%20De%20%C3%93mnibus%20%22Dr.%20Jos%C3%A9%20Manuel%20de%20la%20Sota%22!5e1!3m2!1ses!2sar!4v1611854768915!5m2!1ses!2sar"
        },
        {
            "location": "VILLA MARIA ",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS DE CALAMUCHITA",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3368.3247976340604!2d-63.24583368512598!3d-32.41045685227573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cc43ac752c25a9%3A0x6be4c70f024259d7!2sterminal%20de%20omnibus%20villa%20maria!5e0!3m2!1ses!2sar!4v1611851515525!5m2!1ses!2sar"
        },
        {
            "location": "VILLA RUMIPAL",
            "a_terminal": "SI",
            "a_domicilio": "SI",
            "boleteria": "SIERRAS DE CALAMUCHITA",
            "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d216225.59076628785!2d-64.54891128905112!3d-32.136131959106294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d298567af19097%3A0x4521ee4f9a000356!2sLa%20Terminal%20Villa%20Rumipal!5e0!3m2!1ses!2sar!4v1611851551911!5m2!1ses!2sar"
        }
    ],
    };
  },
  mounted() {
    this.$store.commit("valueCalculatorButton", false);
    setTimeout(function () {
            window.scrollTo(0, 0);
        },2);
  },
};
</script>

<style lang="css" scoped>
.contact-button {
  width: 100%;
  height: 4rem;
  background-color: #fafafa;
  margin-bottom: 0.75rem;
  text-decoration: none;
  outline: 0;
}

.map-image {
  height: 320px;
  width: 100%;
  background-color: #fafafa;
  background-position: center;
}

.bg-contact {
  background-image: url("../assets/Image/fondo-desktop-montañas.png");
  background-size: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-color: #1d3460;
}
.backgroundDark{
  background: transparent !important;
}

.empty-map{
  background-image: url("../assets/Image/Contact/empty-state-map.svg");
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.m-empty-map{
  background-image: url("../assets/Image/Contact/empty-state-map-mobile.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width: 1024px) {
  .contact-button {
    width: 100%;
    height: 4rem;
    background-color: white;
    margin-bottom: 0.75rem;
    text-decoration: none;
    outline: 0;
    border-bottom: 1px solid #d9d9d9; 
    border-width: 0.125rem;
  }
}
</style>

<style lang="css">
.v-text-field > .v-input__control > .v-input__slot::before {
  content: none;
}

.v-text-field > .v-input__control > .v-input__slot::after {
  content: none;
}
</style>
