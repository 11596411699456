<template>
<div>
   <div v-if="mobile">
  <v-container fluid class=" pb-15 mb-15 px-0">
    <div>
      <h3 class="mobile text-left  my-2 px-20 m-h1 border-red primary--text">
        Método de pago
      </h3>
    <div class="hr">
      </div>
    </div>
    <v-row align="center">
      <v-col cols="12 pb-4" style="padding-top:0px;padding-bottom:0px">
      <p class="text-left m-p1 font-weight-medium  ">
      ¿Quién va a realizar el pago?
      </p>
        <v-radio-group v-model="payFrom">
          <v-radio label="Pago en origen" value="pago en origen"></v-radio>
          <v-radio label="Pago en destino" value="pago en destino"></v-radio>
        </v-radio-group>
      </v-col>
       <v-col cols="12" style="padding-top:0px;padding-bottom:0px">
       <p class="text-left m-p1 font-weight-medium  ">
       Elegir metodo de pago
       </p>
        <v-radio-group v-model="payMethod" :disabled="payFrom == 'pago en destino'">
          <v-radio label="Efectivo" value="efectivo"></v-radio>
          <v-radio label="Online Banco Roela" value="online"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
  </div>
  <v-container v-else fluid class="px-0">
    <div>
      <h3 class="text-left  my-2 px-20 m-h1 border-red primary--text">
        Método de pago
      </h3>
    <div class="hr">
      </div>
    </div>
    <v-row align="center">
      <v-col cols="12 pb-4" style="padding-top:0px;padding-bottom:0px">
      <p class="text-left m-p1 font-weight-medium  ">
      ¿Quién va a realizar el pago?
      </p>
        <v-radio-group v-model="payFrom">
          <v-radio label="Pago en origen" value="pago en origen"></v-radio>
          <v-radio label="Pago en destino" value="pago en destino"></v-radio>
        </v-radio-group>
      </v-col>
       <v-col cols="12" style="padding-top:0px;padding-bottom:0px">
       <p class="text-left m-p1 font-weight-medium  ">
       Elegir metodo de pago
       </p>
        <v-radio-group v-model="payMethod" :disabled="payFrom == 'pago en destino'">
          <v-radio label="Efectivo" value="efectivo"></v-radio>
          <v-radio label="Online Banco Roela" value="online"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>
<script>
import { localidades } from "@/services/shipping";
import { isMobile } from 'mobile-device-detect';

export default {
  data: () => ({
    mobile: isMobile ? true : false,
    isLoading: false,
    payFrom: "pago en origen",
    payMethod: "efectivo",
  }),
  methods: {
    async dataLoc() {
      this.isLoading = true;
      const { data } = await localidades();
      this.dataLocalidades = data;
      if (this.dataLocalidades) {
        this.dataLocalidades.forEach((location) => {
          this.items.push(location.NmLocalidad);
        });
      }
      this.isLoading = false;
    },
    completed() {
      this.$store.commit("disableButtom", false);
    },
    incompleted() {
      this.$store.commit("disableButtom", true);
    },
  },
  computed: {
    token() {
      return localStorage.getItem("Token");
    },
  },
  watch: {
   payFrom(){
     var idPay = 1;
     if( this.payFrom == 'pago en origen'){
      this.$store.commit('valueIdPayFrom', idPay);
     }
     else{
       idPay = 2;
      this.$store.commit('valueIdPayFrom', idPay);
     }
     },
     payMethod(){
      this.$store.commit('valuePayMethod', this.payMethod);
     }
  },
  created() {
    this.$store.commit("disableButtom", false);
  },
  mounted(){
    this.$store.commit("disableButtom", false);
  }
};
</script>
