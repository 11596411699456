<template> 
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <defs>
        <svg:style>
            .cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}
        </svg:style>
    </defs>
    <g id="help-circle" transform="translate(-1 -1)">
        <circle id="Elipse_13" cx="10" cy="10" r="10" class="cls-1" transform="translate(2 2)"/>
        <path id="Trazado_437" d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" class="cls-1"/>
        <path id="Línea_3" d="M0 0L0.01 0" class="cls-1" transform="translate(12 17)"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'HelpIcon'
  }
</script>