<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2px" d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" transform="translate(-2 -2)"/>
  </svg>
</template>

<script>
  export default {
    name: 'ContactIcon'
  }
</script>