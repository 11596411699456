<template>
<div>
    <v-container class="px-0 py-0" style="max-width: 100%; min-height: 800px;">
      <div class="bluediv"> 
        <img alt="Pago Exitoso" src="../assets/Image/icon error.svg" class="pago-exitoso pt-11"/>
        <h1 class="pt-14 m-h1" style="color: white">
          El pago fue rechazado
        </h1>
        <div class="pt-10 px-16">
          <p style="color: white; padding-bottom: 110px;" class="m-p1" >
            Por favor verifica los datos de tu tarjeta o intentá con otra.
          </p>
        </div>
      </div>
      <div class="white d-flex justify-center waves" style="align-items: center; justify-content: center;">
      <router-link
        to="/envio"
        class="decoration-remove route"
        style="align-content: center;"
      >
      <v-btn
        color="secondary"
        class="try-again-button mx-4"
        style="height: 44px !important;"
        m-button
        >
          VOLVER A INTENTAR
      </v-btn>
      </router-link>
      <router-link
        to="/"
        class="decoration-remove route"
        style="align-content: center;"
      >
      <v-btn
        color="white"
        class="home-button mx-4 secondary--text"
        style="height: 44px !important;"
        depressed
        m-button
        >
          IR AL INICIO
      </v-btn>
      </router-link>
      </div>
    </v-container>
    <BottomBar />
</div>
</template>

<!-- eslint-disable -->
<script>
import BottomBar from "@/components/BottomBar.vue";
import firebase from 'firebase/app';
import 'firebase/database';

import { sendEmit } from "@/services/shipping";

export default {
  name: "pago-incorrecto",
  components: {
    BottomBar,
  },
  data:() => (
    {
      idpago: '',
      dataForm: {},
      nroRemito: '',
      copySucceeded: null,
    }
  ),
methods: {
},
mounted() {
    this.idpago = this.$route.params.idpago;
    console.log('idpago:',this.idpago);
    this.$store.commit("valuePayNotOk", this.idpago);
  },
}
</script>


<style lang="css" scoped>
.bluediv {
  background-color: #1d3460;
  height: 28.188rem;
  width: 100%;
}

.text-ok {
  padding-top: 6.625rem;
  text-align: left;
}

.home-button {
  width: calc(100% - 32px) !important;
  border-radius: 4px;
  text-align: center !important;
  position: absolute !important;
  bottom: 76px !important;
  left: 0;
}

.try-again-button {
  width: calc(100% - 32px) !important;
  border-radius: 4px;
  text-align: center !important;
  position: absolute !important;
  bottom: 136px !important;
  left: 0;
}

.waves {
  background-image: url("../assets/Image/waves.svg");
  background-position: top;
  background-repeat: no-repeat;
  height: 100px;
  background-size: 100% 40%;
}

@media(min-width: 1024px){
.text-ok {
  padding-top: 8.625rem;
  padding-left:30% !important;
  padding-right: 30% !important;
  text-align: left;
}
.bluediv {
  background-color: #1d3460;
  height: 33.188rem;
  width: 100%;
  padding-top:60px;
}
.home-button {
  margin: auto !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
}

.try-again-button {
  margin: auto !important;
  position: absolute !important; 
  left: 0 !important;
  right: 0 !important;
}

.waves {
  background-size: 100% 100%;
}

}
</style>