<template>
<div>
    <v-container class="px-0 py-0" style="max-width: 100%; min-height: 800px;">
      <div class="bluediv"> 
        <img alt="Pago Exitoso" src="../assets/Image/icon exito.svg" class="pago-exitoso pt-11"/>
        <h1 class="pt-14 m-h1" style="color: white">
          ¡El pago se realizó con éxito!
        </h1>
        <p class="pt-10 m-p1" style="color: white">
          Número de seguimiento:
        </p>
        <div class="pt-0">
          <v-progress-circular
            indeterminate
            color="secondary"
            v-if="!this.nroRemito"
          ></v-progress-circular>
          <h2 v-else style="color: white" class="m-seguimiento">
            {{ this.nroRemito }}
          </h2>
        </div>
        <button 
          v-clipboard:copy="idpago"
          v-clipboard:success="copySuccess" 
          v-clipboard:error="copyFail"
          class="px-3 pt-4">
          <img alt="Copiar" src="../assets/Image/copy.svg"/>
        </button>
        <button class="px-3 pt-4"> 
          <img alt="Compartir" src="../assets/Image/share.svg"  @click="shareViaWebShare"/>
        </button>
        <p v-if="copySucceeded === true" style="color: white">¡Código copiado!</p>
        <p v-if="copySucceeded === false" style="color: white">Error, copie manualmente.</p>
      </div>
      <div class="px-4 text-ok m-p1 waves" style="padding-bottom: 110px;">
        <p>
          Te enviaremos un mail con los datos de envío y número de seguimiento. Recuerda que podrás ver el estado de tu envío en el <b>buscador</b> ubicado en la <b>página de inicio.</b>
        </p>
      </div>
      <router-link
        to="/"
        class="decoration-remove route"
        style="align-content: center;"
      >
      <v-btn
        color="secondary"
        class="home-button mx-4"
        style="height: 44px !important;"
        m-button
        >
          IR AL INICIO
      </v-btn>
      </router-link>
    </v-container>
    <BottomBar />
    <v-overlay 
      :value="overlay"
      :opacity="1"
      color="white">
      <v-progress-circular
        indeterminate
        size="64"
        color="#1d3460"
      ></v-progress-circular>
    </v-overlay>
</div>
</template>

<!-- eslint-disable -->
<script>
import BottomBar from "@/components/BottomBar.vue";
import firebase from 'firebase/app';
import 'firebase/database';

import { tokenPayment, sendEmit, queryPayment } from "@/services/shipping";

export default {
  name: "pago-correcto",
  components: {
    BottomBar,
  },
  data:() => (
    {
      idpago: '',
      dataForm: {},
      nroRemito: '',
      copySucceeded: null,
      IdResultado: '',
      IdReferenciaOperacion: '',
      tokenPaymentData: "",
      paymentStatus: false,
      hash: "",
      overlay: true,
    }
  ),
methods: {
  getdata(){
    firebase.database().ref('pagoremito').child(this.idpago).once('value', (snapshot) => {
        this.dataForm = snapshot.val();
        console.log(this.dataForm)
        this.hash = this.dataForm.hash;
        if (this.dataForm.Remito == "") {
          this.callPaymentToken();
        } else {
          this.nroRemito = this.dataForm.Remito;
          console.log('Remito creado anteriormente');
          this.overlay = false;
        }        
      });
  },
  async getpaymentData() {
    var paymentdataform;
    paymentdataform = {
      hash: this.hash,
      id_resultado: this.IdResultado,
      token: this.tokenPaymentData,
    };
    console.log('Payment dataform: ', paymentdataform)
    const { data } = await queryPayment(paymentdataform);
    console.log('Payment data status: ', data);
    this.paymentStatus = data.PagoExitoso;
    console.log('Payment status: ', this.paymentStatus)
    if (this.paymentStatus == true) {
          this.emit();
          console.log('Remito creado correctamente');
    }
    else {
      console.log('Pago cancelado');
      this.$router.push({name: 'pago-incorrecto',params: { idpago: this.idpago }});
      this.overlay = false;
    }
  },
  async emit() {
      this.dataFrom.Pagado = 1;
      const { data } = await sendEmit(this.dataForm);
      console.log("data", data);
      if (data.codigo != 0) {
        // error al mandar remito
        this.errorMessageRemit = data.mensaje;
        this.overlay = false;
      } else {
        // avanzo a pagina de exito y guardo el remito en el store
        this.$store.commit("valueRemit", data.NroRemito);
        this.nroRemito = data.NroRemito;
        this.writeBaseDate();
        this.overlay = false;
      }
    },
  copySuccess(e) {
    this.copySucceeded = true;
  },
  copyFail: function copyFail() {
    this.copySucceeded = false;
  },
  shareViaWebShare() {
    
    navigator.share({
      title: 'Compartir',
      text: '',
      url: location.protocol + '//' + location.host + "/seguimiento/" + this.nroRemito
    })
  },
   writeBaseDate() {
      var id = this.idpago;
      var dataFormBase = this.dataForm;
      dataFormBase.Remito = this.nroRemito;

      const updates = {};
      updates[`pagoremito/${id}`] = dataFormBase;
      console.log(updates);
      firebase.database().ref().update(updates).then(() => {
      console.log('se guardo');
      });
    },
  async callPaymentToken() {
      const { data } = await tokenPayment();
      this.tokenPaymentData = data.access_token;
      console.log("el token es:", data.access_token);
      this.getpaymentData();
    },  
  },
  mounted() {
    this.idpago = this.$route.params.idpago;
    console.log('idpago:',this.idpago);

    var urlParams = new URLSearchParams(window.location.search);

    this.IdResultado = urlParams.get('IdResultado');
    console.log('IdResultado:',this.IdResultado);

    this.IdReferenciaOperacion = urlParams.get('IdReferenciaOperacion');
    console.log('IdReferenciaOperacion:',this.IdReferenciaOperacion);

    this.getdata();
  },
};
</script>


<style lang="css" scoped>
.bluediv {
  background-color: #1d3460;
  height: 28.188rem;
  width: 100%;
}

.text-ok {
  padding-top: 6.625rem;
  text-align: left;
}

.home-button {
  width: calc(100% - 32px) !important;
  border-radius: 4px;
  text-align: center !important;
  position: absolute !important;
  bottom: 76px !important;
  left: 0;
}

.waves {
  background-image: url("../assets/Image/waves.svg");
  background-position: top;
  background-repeat: no-repeat;
  height: 100px;
  background-size: 100% 40%;
}

@media(min-width: 1024px){
.home-button {
  margin: auto !important;
  position: absolute !important; 
  left: 0 !important;
  right: 0 !important;
}
.text-ok {
  padding-top: 8.625rem;
  padding-left:30% !important;
  padding-right: 30% !important;
  text-align: left;
}
.bluediv {
  background-color: #1d3460;
  height: 33.188rem;
  width: 100%;
  padding-top:60px;
}
}
</style>