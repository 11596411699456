<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="icon_home" data-name="icon / home" transform="translate(-2 -1)">
    <path id="Trazado_434" data-name="Trazado 434" d="M3,8.3,12,2l9,6.3v9.9A1.908,1.908,0,0,1,19,20H5a1.908,1.908,0,0,1-2-1.8Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="cls-home"/>
    <path id="Trazado_435" data-name="Trazado 435" d="M9,22V12h6V22" transform="translate(0 -2)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="cls-home"/>
  </g>
  </svg>
</template>

<script>
  export default {
    name: 'HomeIcon'
  }
</script>