<template>
  <div class="home">
    <div id="mobile" class="mobile" v-if="mobile">
      <v-container class="px-4 pb-10 ">
        <div class="pb-6">
          <div class="d-flex justify-start py-3">
            <img
              alt="Vue logo"
              src="../assets/Image/Logo/LOGO-MOBILE.svg"
              height="40px"
              class="logo-sierraspack"
            />
          </div>
          <h1 class="text-home ">
            No pierdas tiempo,<br />lo buscamos por tu casa!
          </h1>
          <h2 class="subtext-home">
            Nuevo sistema de paquetería<br /><b>puerta a puerta.</b>
          </h2>
        </div>
        <div class="pb-2">
          <v-text-field
            outlined
            label="Número de seguimiento"
            v-model="trackingCode"
            class="tracking-input"
            @keydown.enter="goToTracking()"
          >
            <template slot="append">
              <a
                @click="goToTracking()"
                class="decoration-remove route"
              >
                <search-icon
                  size="1.5x"
                  class="custom-class"
                  color="#0052cc"
                ></search-icon>
              </a>
            </template>
          </v-text-field>
        </div>
        <div>
          <h3 class="text-center pt-14 complementary-text-home">
            ¡Enviar un paquete<br />
            nunca fue tan <b>fácil!</b>
            <!-- <br> Window height: {{ windowHeight }} <br/> -->
            <!-- {{ originalHeight }}<br/> -->
          </h3>
        </div>
        <div class="carousel mt-6">
          <v-carousel
            height="279"
            delimiter-icon="mdi-circle-small"
            hide-delimiter-background
            :show-arrows="false"
          >
            <v-carousel-item v-for="(item, i) in items" :key="i" class="mt-3">
              <v-card class="v-card-style">
                <img
                  :src="item.src"
                  style="padding-top: 2.39rem; padding-bottom: 1.683rem"
                />
                <h2 class="m-h2">{{ item.titulo }}</h2>
                <h3 class="m-p1" style="padding: 0 1.125rem 0 1.063rem">
                  {{ item.texto }}
                </h3>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </div>
      </v-container>
      <BottomBar v-if="originalHeight <= windowHeight + 100" />
    </div>
    <v-row id="desktop " v-if="!mobile" class="white">
      <div
        class="col-12 pl-16 
      pr-0 pt-15  bg-desktop"
        style="margin-top:92px !important; height:531px"
      >
        <div class="pb-6 col-11">
          <div class="pb-4">
            <h1 class="text-home ">
              No pierdas tiempo,<br />lo buscamos por tu casa!
            </h1>
            <h2 class="subtext-home">
              Nuevo sistema de paquetería <b>puerta a puerta.</b>
            </h2>
          </div>
          <div class="mr-auto col-6 pl-0 d-flex ">
             <router-link
                to="/envio"
                class="decoration-remove route"
              >
            <v-btn color="secondary" elevation="2" x-large class="px-7"
              >ENVIAR UN PAQUETE
            </v-btn>
             </router-link>
          </div>
        </div>
      </div>
      <div
        class="col-12  pt-12 px-16  backgroundDark"
        style="padding-bottom:98px;"
      >
        <h2 class="white--text mb-13">
          ¡Enviar un paquete nunca fue tan fácil!
        </h2>
        <v-row class="justify-space-around" style="max-width: 1364px; margin: auto;">
          <div v-for="(item, i) in items" :key="i" class="col-3 mt-3">
            <v-card class="v-card-style">
              <img
                :src="item.src"
                style="padding-top: 2.39rem; padding-bottom: 1.683rem"
              />
              <h2 class="m-h2">{{ item.titulo }}</h2>
              <h3 class="m-p1" style="padding: 0 1.125rem 0 1.063rem">
                {{ item.texto }}
              </h3>
            </v-card>
            <div v-if="i != 0 && i != 4" class="line-red"></div>
          </div>
        </v-row>
      </div>
    </v-row>
  </div>
</template>

<script>
//  @ is an alias to /src
import BottomBar from "@/components/BottomBar.vue";

import { tokenAuth } from "@/services/shipping";
import { SearchIcon } from "vue-feather-icons";
import { isMobile } from 'mobile-device-detect';

export default {
  name: "home",
  components: {
    BottomBar,
    SearchIcon,

  },
  data: () => ({
    mobile: isMobile ? true : false,
    originalHeight: window.innerHeight,
    windowHeight: window.innerHeight,
    items: [
      {
        src: require("../assets/Image/Home/icon calculadora.svg"),
        titulo: "Cotizá tu envío",
        texto: "Elegí el origen y destino. Ingresá las medidas del paquete.",
      },
      {
        src: require("../assets/Image/Home/check.svg"),
        titulo: "Cargá los datos",
        texto: "Completá los formularios con tus datos y los del destinatario.",
      },
      {
        src: require("../assets/Image/Home/credit.svg"),
        titulo: "Método de pago",
        texto: "Elegí el método que prefieras para abonar tu envío.",
      },
      {
        src: require("../assets/Image/Home/icon exito.svg"),
        titulo: "Método de pago",
        texto: "Te daremos el número de seguimiento de tu paquete.",
      },
    ],
    trackingCode: "",

  }),
  methods: {
    async created() {
      const { data } = await tokenAuth();
      console.log("token?", data.token);
      await localStorage.setItem("Token", data.token);
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        this.originalHeight = window.innerHeight;
      } else if (orientation === "landscape-primary") {
        this.originalHeight = window.innerHeight;
      }
    },
    goToTracking(){
      if(this.trackingCode){
        console.log(this.trackingCode, 'qe onda?')
      this.$router.push({name: 'seguimiento',params: { tracking: this.trackingCode }})
    }
    }
  },
  mounted() {
    this.$store.commit("valueCalculatorButton", false);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    window.addEventListener("orientationchange", this.handleOrientationChange);
    setTimeout(function () {
            window.scrollTo(0, 0);
        },2);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="css" scoped>
.carousel {
  padding-bottom: 57px;
}
.theme--dark.v-btn.v-btn--icon {
  color: blue;
}

.logo-sierraspack {
  height: 1.75rem;
}

.text-home {
  width: 20rem;
  height: 4.063rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #0052cc;
}

.subtext-home {
  width: 23.75rem;
  height: 2.813rem;
  margin-top: 0.5rem;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  text-align: left;
  color: #000000;
}

.complementary-text-home {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  color: #000000;
}

.tracking-input {
  width: 23.75rem;
  height: 3.5rem;
  border-radius: 4px;
}

.home {
  background: #08163e;
  height: 100%;
}
@media (min-width: 1024px) {
  .home {
    background: #1d3460;
    height: 100%;
  }
}
.mobile {
  background-image: url("../assets/Image/Home/fondo-mobile.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: white;
}

.bg-desktop {
  background-image: url("../assets/Image/Home/home-desktop.svg");
  background-position: calc(100% + 41.3px) 3px;
  background-repeat: no-repeat;
}

@media (max-width: 1500px) {
  .bg-desktop {
    background-image: url("../assets/Image/Home/home-desktop.svg");
    background-position: calc(100% + 231px) 3px;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1280px) {
  .bg-desktop {
    background-image: url("../assets/Image/Home/home-desktop.svg");
    background-position: calc(100% + 531px) 3px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.items-size {
  width: 3.1rem;
  height: 3.607rem;
}

.theme--dark.v-sheet {
  background-color: #ffffff;
}

.v-card-style {
  width: 17.563rem;
  height: 14.25rem;
  text-align: center;
  margin: auto;
  color: #000000;
  box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
}
@media (min-width: 1024px) {
  .v-card-style {
    width: 17.563rem;
    height: 100%;
    padding-bottom: 29px;
    text-align: center;
    margin: auto;
    color: #000000;
    box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.1);
    z-index: 2;
  }
}
.line-red {
  background: #ff2633;
  height: 2px;
  width: 25%;
  position: relative;
  bottom: 50%;
  left: -16%;
  z-index: 1;
}
@media (min-width: 200px) {
  .line-red {
    background: #ff2633;
    height: 2px;
    width: 35%;
    position: relative;
    bottom: 50%;
    left: -20%;
  }
}
.v-sheet.v-card {
  border-radius: 1rem;
}
.my-input.v-input .v-input__slot {
  border-radius: 100px;
}
.chat-background {
  background-color: #ECE5DD !important;
}
.chat-header {
  background-color: #128C7E !important;
}
.chip-chat {
  background-color: #dcf8c6 !important;
}
.theme--light.v-divider {
    border-color: rgba(0,0,0,17.62);
}

</style>

<style lang="css">@media (min-width: 1024px) {
  .home {
    height: 100%;
  }
}
.v-carousel__controls__item {
  margin: 0px !important;
}

.v-btn--icon.v-size--small {
  height: 15px;
  width: 15px;
}

.v-carousel__item {
  overflow: visible;
}

.v-select__selection {
  justify-content: left;
}

.my-input > .v-input__control > .v-input__slot {
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 100px;
  padding-right: 12px;
  padding-left: 12px;
  margin-left: 24px;
  width: 95%;
}
.v-input__slot:focus {
  border: 2px solid #009688;
  border-radius: 100px;
  padding-right: 12px;
  padding-left: 12px;
  margin-left: 24px;
  width: 95%;
}
.my-input > .v-input__control > .v-input__slot:hover {
  border: 2px solid #009688;
  border-radius: 100px; 
  padding-right: 12px;
  padding-left: 12px;
  margin-left: 24px;
  width: 95%;
}

.v-chip.v-size--default{
  border-top-left-radius: 0px;
  background-color:white !important;
}
.v-btn--fixed.v-btn--bottom{
  bottom: 82px;
right: 75px;
}

.v-btn--fab.v-size--default {
    height: 65px;
    width: 65px;
}

</style>
