<template>
<div>
      <div v-if="mobile">
  <v-container fluid class="pb-15 mb-15 px-0">
    <div>
      <h3 class="mobile text-left  my-2 px-20 m-h1 border-red primary--text">
        Datos del destinatario
      </h3>
      <p class="text-left m-p3 mb-3">
        Los campos con (*) asterísco son obligatorios.
      </p>
      <div class="hr"></div>
    </div>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      fixed-tabs
      id='tabs-form'
    >
      <v-tab v-for="tabname in tabnames" :key="tabname" @click="incompleted()">
        {{ tabname }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tabname in tabnames" :key="tabname">
        <v-flex transparent-body v-if="tabname === 'Envio a una persona'">
          <v-form v-model="isFormValid" class="flex">
            <v-row align="center">
              <v-col cols="12" style="padding-top:0px;padding-bottom:0px">
                <v-text-field
                  required
                  :rules="namesRules"
                  v-model="sendPerson.name"
                  outlined
                  label="Nombre y Apellido *"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" class="py-0 ">
                <v-text-field
                  required
                  :rules="dniRules"
                  v-model="sendPerson.dni"
                  type="number" pattern="[0-9]*" inputmode="numeric"
                  outlined
                  label="DNI *"
                  @blur="getClientExist()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="py-0">
                <v-text-field
                  outlined
                  :rules="emailRules"
                  v-model="sendPerson.mail"
                  label="Mail"
                >
                </v-text-field>
              </v-col>
              <p class="col-12 m-p1 font-weight-medium mb-4 text-left">
                Teléfono de contacto
              </p>
              <v-radio-group
                v-model="choosePhone"
                row
                class="px-4 mt-0"
              >
              <v-radio
                label="Celular"
                value="celular"
              ></v-radio>
              <v-radio
                label="Telefono fijo"
                value="telefono"
              ></v-radio>
              </v-radio-group>
              <div v-if="choosePhone == 'celular'" class="row" style="margin: 0;">
              <v-col cols="6" md="12" class="py-0" style="display: flex;">
                <p style="padding-top:15px; padding-right: 7px">0</p>
                <v-text-field
                  outlined
                  :rules="preNumberRulesCel"
                  v-model="sendPerson.prePhone"
                  required
                  type="number" pattern="[0-9]*" inputmode="numeric"
                  persistent-hint
                  label="Cód. de área *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="12" class="py-0" style="display: flex;">
                <p style="padding-top:15px; padding-right: 7px">15</p>
                <v-text-field
                  outlined
                  :rules="numberRulesCel"
                  type="number" pattern="[0-9]*" inputmode="numeric"
                  v-model="sendPerson.phone"
                  required
                  persistent-hint
                  label="Número *"
                >
                </v-text-field>
              </v-col>
              </div>
              <div v-if="choosePhone == 'telefono'" class="row" style="margin: 0;">
              <v-col cols="6" md="12" class="py-0">
                <v-text-field
                  outlined
                  :rules="preNumberRulesCel"
                  type="number" pattern="[0-9]*" inputmode="numeric"
                  v-model="sendPerson.prePhone"
                  required
                  persistent-hint
                  label="Cód. de área *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="12" class="py-0">
                <v-text-field
                  outlined
                  :rules="numberRulesCel"
                  type="number" pattern="[0-9]*" inputmode="numeric"
                  v-model="sendPerson.phone"
                  required
                  persistent-hint
                  label="Número *"
                >
                </v-text-field>
              </v-col>
              </div>
              <p class="col-12 m-p1 font-weight-medium my-4 text-left">
                Datos del domicilio
              </p>
              <v-col cols="12" md="12" class="py-0">
                <v-text-field
                  outlined
                  :rules="otherRules"
                  v-model="sendPerson.street"
                  required
                  label="Calle *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="12" class="py-0">
                <v-text-field
                  outlined
                  :rules="numberRules"
                  type="number" pattern="[0-9]*" inputmode="numeric"
                  v-model="sendPerson.streetNumber"
                  required
                  label="Numero *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="12" class="py-0">
                <v-text-field outlined label="Piso" v-model="sendPerson.floor">
                </v-text-field>
              </v-col>
              <v-col cols="6" md="12" class="py-0">
                <v-text-field
                  outlined
                  label="Departamento"
                  v-model="sendPerson.flat"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="12" class="py-0">
                <v-text-field outlined label="Torre" v-model="sendPerson.tower">
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-flex>
        <v-flex transparent-body v-if="tabname == 'Envio a una empresa'">
          <v-form v-model="isFormValidCompany" class="flex">
            <v-row align="center">
              <v-col cols="12" style="padding-top:0px;padding-bottom:0px">
                <v-text-field
                  required
                  :rules="namesRules"
                  v-model="sendCompany.name"
                  outlined
                  label="Razón social *"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="12" class="py-0 ">
                <v-text-field
                  required
                  :rules="cuitRules"
                  type="number" pattern="[0-9]*" inputmode="numeric"
                  v-model="sendCompany.cuit"
                  outlined
                  label="CUIT *"
                  @blur="getClientExist()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="py-0">
                <v-text-field
                  outlined
                  :rules="namesRules"
                  required
                  persistent-hint
                  v-model="sendCompany.reference"
                  hint="Indica quién recibirá el paquete"
                  label="Referente *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="py-0">
                <v-text-field
                  outlined
                  :rules="emailRules"
                  v-model="sendCompany.mail"
                  label="Mail"
                >
                </v-text-field>
              </v-col>
              <p class="col-12 m-p1 font-weight-medium my-4 text-left">
                Teléfono de contacto
              </p>
              <v-radio-group
                v-model="choosePhone"
                row
                class="px-4 mt-0"
              >
              <v-radio
                label="Celular"
                value="celular"
              ></v-radio>
              <v-radio
                label="Telefono fijo"
                value="telefono"
              ></v-radio>
              </v-radio-group>
              <div v-if="choosePhone == 'celular'" class="row" style="margin: 0;"> 
              <v-col cols="6" md="12" class="py-0" style="display: flex;">
                <p style="padding-top:15px; padding-right: 7px">0</p>
                <v-text-field
                  outlined
                  :rules="preNumberRulesCel"
                  type="number" pattern="[0-9]*" inputmode="numeric"
                  v-model="sendCompany.prePhone"
                  required
                  persistent-hint
                  label="Cód. de área *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="12" class="py-0" style="display: flex;">
                <p style="padding-top:15px; padding-right: 7px">0</p>
                <v-text-field
                  outlined
                  :rules="numberRulesCel"
                  type="number" pattern="[0-9]*" inputmode="numeric"
                  v-model="sendCompany.phone"
                  required
                  persistent-hint
                  label="Número *"
                >
                </v-text-field>
              </v-col>
              </div>
              <div v-if="choosePhone == 'telefono'" class="row" style="margin: 0;">
              <v-col cols="6" md="12" class="py-0">
                <v-text-field
                  outlined
                  :rules="preNumberRulesCel"
                  type="number" pattern="[0-9]*" inputmode="numeric"
                  v-model="sendCompany.prePhone"
                  required
                  persistent-hint
                  label="Cód. de área *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="12" class="py-0">
                <v-text-field
                  outlined
                  :rules="numberRulesCel"
                  type="number" pattern="[0-9]*" inputmode="numeric"
                  v-model="sendCompany.phone"
                  required
                  persistent-hint
                  label="Número *"
                >
                </v-text-field>
              </v-col>
              </div>
              <p class="col-12 m-p1 font-weight-medium my-4 text-left">
                Datos del domicilio
              </p>
              <v-col cols="12" md="12" class="py-0">
                <v-text-field
                  outlined
                  :rules="otherRules"
                  v-model="sendCompany.street"
                  required
                  label="Calle *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="12" class="py-0">
                <v-text-field
                  outlined
                  :rules="numberRules"
                  type="number" pattern="[0-9]*" inputmode="numeric"
                  v-model="sendCompany.streetNumber"
                  required
                  label="Numero *"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6" md="12" class="py-0">
                <v-text-field outlined label="Piso" v-model="sendCompany.floor">
                </v-text-field>
              </v-col>
              <v-col cols="6" md="12" class="py-0">
                <v-text-field
                  outlined
                  label="Departamento"
                  v-model="sendCompany.flat"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="12" class="py-0">
                <v-text-field
                  outlined
                  label="Torre"
                  v-model="sendCompany.tower"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-flex>
      </v-tab-item>
    </v-tabs-items>
    <v-alert
      dense
      outlined
      type="error"
      v-if="
        (this.isFormValid || this.isFormValidCompany) &&
          this.errorMessage != '' &&
          this.errorMessage != 'Exitoso'
      "
    >
      {{ errorMessage }}
    </v-alert>
  </v-container>
  </div>
  <v-container v-else fluid class="desktop  px-0">
    <div>
      <h3 class="mobile text-left  my-2 px-20 m-h1 border-red primary--text">
        Datos del destinatario
      </h3>
      <p class="text-left m-p3 mb-3">
        Los campos con (*) asterísco son obligatorios.
      </p>
      <div class="hr"></div>
    </div>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      fixed-tabs
      id='tabs-form'
    >
      <v-tab v-for="tabname in tabnames" :key="tabname" @click="incompleted()">
        {{ tabname }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tabname in tabnames" :key="tabname">
        <v-flex transparent-body v-if="tabname === 'Envio a una persona'">
          <v-form v-model="isFormValid" class="flex">
            <v-row align="center">
              <v-col cols="12" md="6" style="padding-top:0px;padding-bottom:0px">
                <v-text-field
                  required
                  :rules="namesRules"
                  v-model="sendPerson.name"
                  outlined
                  label="Nombre y Apellido *"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6" class="py-0 ">
                <v-text-field
                  required
                  :rules="dniRules"
                  v-model="sendPerson.dni"
                  outlined
                  label="DNI *"
                  @blur="getClientExist()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  outlined
                  :rules="emailRules"
                  v-model="sendPerson.mail"
                  label="Mail"
                >
                </v-text-field>
              </v-col>
              <p class="col-12 m-p1 font-weight-medium mb-4 text-left">
                Teléfono de contacto
              </p>
              <v-radio-group
                v-model="choosePhone"
                row
                class="px-4 mt-0"
              >
              <v-radio
                label="Celular"
                value="celular"
              ></v-radio>
              <v-radio
                label="Telefono fijo"
                value="telefono"
              ></v-radio>
              </v-radio-group>
              <div v-if="choosePhone == 'celular'" class="row" style="margin: 0;">
              <v-col cols="6" md="4" class="py-0" style="display: flex;">
                <p style="padding-top:15px; padding-right: 7px">0</p>
                <v-text-field
                  outlined
                  :rules="preNumberRulesCel"
                  v-model="sendPerson.prePhone"
                  required
                  persistent-hint
                  label="Cód. de área *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="py-0" style="display: flex;">
                <p style="padding-top:15px; padding-right: 7px">15</p>
                <v-text-field
                  outlined
                  :rules="numberRulesCel"
                  v-model="sendPerson.phone"
                  required
                  persistent-hint
                  label="Número *"
                >
                </v-text-field>
              </v-col>
              </div>
              <div v-if="choosePhone == 'telefono'" class="row" style="margin: 0;">
              <v-col cols="6" md="4" class="py-0">
                <v-text-field
                  outlined
                  :rules="preNumberRulesCel"
                  v-model="sendPerson.prePhone"
                  required
                  persistent-hint
                  label="Cód. de área *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="py-0">
                <v-text-field
                  outlined
                  :rules="numberRulesCel"
                  v-model="sendPerson.phone"
                  required
                  persistent-hint
                  label="Número *"
                >
                </v-text-field>
              </v-col>
              </div>
              <p class="col-12 m-p1 font-weight-medium my-4 text-left">
                Datos del domicilio
              </p>
              <v-col cols="12" md="8" class="py-0">
                <v-text-field
                  outlined
                  :rules="otherRules"
                  v-model="sendPerson.street"
                  required
                  label="Calle *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="py-0">
                <v-text-field
                  outlined
                  :rules="numberRules"
                  v-model="sendPerson.streetNumber"
                  required
                  label="Numero *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="py-0">
                <v-text-field outlined label="Piso" v-model="sendPerson.floor">
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="py-0">
                <v-text-field
                  outlined
                  label="Departamento"
                  v-model="sendPerson.flat"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="py-0">
                <v-text-field outlined label="Torre" v-model="sendPerson.tower">
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-flex>
        <v-flex transparent-body v-if="tabname == 'Envio a una empresa'">
          <v-form v-model="isFormValidCompany" class="flex">
            <v-row align="center">
              <v-col cols="12" md="6" style="padding-top:0px;padding-bottom:0px">
                <v-text-field
                  required
                  :rules="namesRules"
                  v-model="sendCompany.name"
                  outlined
                  label="Razón social *"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6" class="py-0 ">
                <v-text-field
                  required
                  :rules="cuitRules"
                  v-model="sendCompany.cuit"
                  outlined
                  label="CUIT *"
                  @blur="getClientExist()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  outlined
                  :rules="namesRules"
                  required
                  persistent-hint
                  v-model="sendCompany.reference"
                  hint="Indica quién recibirá el paquete"
                  label="Referente *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  outlined
                  :rules="emailRules"
                  v-model="sendCompany.mail"
                  label="Mail"
                >
                </v-text-field>
              </v-col>
              <p class="col-12 m-p1 font-weight-medium my-4 text-left">
                Teléfono de contacto
              </p>
              <v-radio-group
                v-model="choosePhone"
                row
                class="px-4 mt-0"
              >
              <v-radio
                label="Celular"
                value="celular"
              ></v-radio>
              <v-radio
                label="Telefono fijo"
                value="telefono"
              ></v-radio>
              </v-radio-group>
              <div v-if="choosePhone == 'celular'" class="row" style="margin: 0;"> 
              <v-col cols="6" md="4" class="py-0" style="display: flex;">
                <p style="padding-top:15px; padding-right: 7px">0</p>
                <v-text-field
                  outlined
                  :rules="preNumberRulesCel"
                  v-model="sendCompany.prePhone"
                  required
                  persistent-hint
                  label="Cód. de área *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="py-0" style="display: flex;">
                <p style="padding-top:15px; padding-right: 7px">0</p>
                <v-text-field
                  outlined
                  :rules="numberRulesCel"
                  v-model="sendCompany.phone"
                  required
                  persistent-hint
                  label="Número *"
                >
                </v-text-field>
              </v-col>
              </div>
              <div v-if="choosePhone == 'telefono'" class="row" style="margin: 0;">
              <v-col cols="6" md="4" class="py-0">
                <v-text-field
                  outlined
                  :rules="preNumberRulesCel"
                  v-model="sendCompany.prePhone"
                  required
                  persistent-hint
                  label="Cód. de área *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="py-0">
                <v-text-field
                  outlined
                  :rules="numberRulesCel"
                  v-model="sendCompany.phone"
                  required
                  persistent-hint
                  label="Número *"
                >
                </v-text-field>
              </v-col>
              </div>
              <p class="col-12 m-p1 font-weight-medium my-4 text-left">
                Datos del domicilio
              </p>
              <v-col cols="12" md="8" class="py-0">
                <v-text-field
                  outlined
                  :rules="otherRules"
                  v-model="sendCompany.street"
                  required
                  label="Calle *"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="py-0">
                <v-text-field
                  outlined
                  :rules="numberRules"
                  v-model="sendCompany.streetNumber"
                  required
                  label="Numero *"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6" md="4" class="py-0">
                <v-text-field outlined label="Piso" v-model="sendCompany.floor">
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="py-0">
                <v-text-field
                  outlined
                  label="Departamento"
                  v-model="sendCompany.flat"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="py-0">
                <v-text-field
                  outlined
                  label="Torre"
                  v-model="sendCompany.tower"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-flex>
      </v-tab-item>
    </v-tabs-items>
    <v-alert
      dense
      outlined
      type="error"
      v-if="
        (this.isFormValid || this.isFormValidCompany) &&
          this.errorMessage != '' &&
          this.errorMessage != 'Exitoso'
      "
    >
      {{ errorMessage }}
    </v-alert>
  </v-container>
  </div>
</template>

<script>
import { clients, agregarCliente, modificarCliente } from "@/services/shipping";
import { isMobile } from 'mobile-device-detect';
export default {
  data: () => ({
    mobile: isMobile ? true : false,
    tab: 0,
    isFormValid: false,
    isFormValidCompany: false,
    tabnames: ["Envio a una persona", "Envio a una empresa"],
    namesRules: [
      (v) => !!v || "Este campo es requerido",
      (v) => v.length <= 25 || "Este campo permite hasta 25 caracteres",
      (v) =>
        /^[a-zA-Z\s\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+$/i.test(v) ||
        "Este campo no acepta números",
    ],
    numberRulesCel: [
      (v) => !!v || "Este campo es requerido",
      (v) => /^\d+$/.test(v) || "Este campo solo acepta números",
      (v) => v > 0 || "Este campo no puede ser 0",
      (v) => v.length <= 8 || "Número de telefono no válido",
    ],
    preNumberRulesCel: [
      (v) => !!v || "Este campo es requerido",
      (v) => /^\d+$/.test(v) || "Este campo solo acepta números",
      (v) => v > 0 || "Este campo no puede ser 0",
      (v) => v.length <= 4 || "Prefijo no válido",
    ],
    numberCompanyRulesCel: [
      (v) => /^\d+$/.test(v) || "Este campo solo acepta números",
      (v) => v > 0 || "Este campo no puede ser 0",
      (v) => v.length >= 7 || "Telefono/Celular inválido",
    ],
    dniRules: [
      (v) => !!v || "Este campo es requerido",
      (v) => /^\d+$/.test(v) || "Este campo solo acepta números",
      (v) => v.length == 7 || v.length == 8 || "DNI no válido",
    ],
    cuitRules: [
      (v) => !!v || "Este campo es requerido",
      (v) => /^\d+$/.test(v) || "Este campo solo acepta números",
      (v) => v.length == 11 || "CUIT no válido",
    ],
    numberRules: [
      (v) => !!v || "Este campo es requerido",
      (v) => /^\d+$/.test(v) || "Este campo solo acepta números",
      (v) => v > 0 || "Este campo no puede ser 0",
    ],
    emailRules: [
       //eslint-disable-next-line
       (v) => {
         //eslint-disable-next-line
         if (v) return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(v) || 'E-mail no valido';
         else return true;
        }, ],
    otherRules: [(v) => !!v || "Este campo es requerido"],
    sendPerson: {
      name: "",
      dni: "",
      prePhone: "",
      phone: "",
      street: "",
      streetNumber: "",
      floor: "",
      flat: "",
      tower: "",
      mail: "",
    },
    sendCompany: {
      name: "",
      cuit: "",
      reference: "",
      prePhone: "",
      phone: "",
      street: "",
      streetNumber: "",
      floor: "",
      flat: "",
      tower: "",
      mail: "",
    },
    idClient: 0,
    errorMessage: "",
    choosePhone: "celular",
  }),
  props :{
   clickButtonNext : Boolean,
   stateE1: Number ,
  },
  methods: {
    completed() {
      this.$store.commit("disableButtom", false);
      if(this.sendPerson.name || this.sendCompany.name){
      this.$store.commit("valueFormReceiverValid", true);
      }
    },
    incompleted() {
      this.$store.commit("disableButtom", true);
    },
    async getClientExist() {
      var DNICliente;
      if (this.sendPerson.dni) {
        DNICliente = this.sendPerson.dni;
      }
      if (this.sendCompany.cuit) {
        DNICliente = this.sendCompany.cuit;
      }
      console.log(DNICliente);
      const { data } = await clients(DNICliente);
      if (data.IdCliente) {
        this.idClient = data.IdCliente;
        this.$store.commit("valueIdClientReceiver", data.IdCliente);
      }
    },
    async addNewClient() {
      var dataForm;
      var domicilio;
      if (this.tab == 0 || this.tab == null) {
        domicilio = this.sendPerson.street + "," + this.sendPerson.streetNumber;
        if (this.sendPerson.floor) {
          domicilio = domicilio + ",piso:" + this.sendPerson.floor;
        }
        if (this.sendPerson.flat) {
          domicilio = domicilio + ",departamento:" + this.sendPerson.flat;
        }
        if (this.sendPerson.tower) {
          domicilio = domicilio + ", torre:" + this.sendPerson.tower;
        }
        dataForm = {
          IdCliente: this.idClient,
          NmCliente: this.sendPerson.name,
          RazonSocial: this.sendPerson.name,
          Domicilio: domicilio,
          Localidad: this.destination,
          CP: "",
          TE: this.sendPerson.prePhone + "-" + this.sendPerson.phone,
          CdIVA: 3, // consumidor final
          CUIT: "",
          DNI: this.sendPerson.dni,
          IngBrutos: "",
          Mail: this.sendPerson.mail,
        };
        this.$store.commit("valueReceiver", dataForm);
      } else {
        domicilio =
          this.sendCompany.street + "," + this.sendCompany.streetNumber;
        if (this.sendCompany.floor) {
          domicilio = domicilio + ",piso:" + this.sendCompany.floor;
        }
        if (this.sendCompany.flat) {
          domicilio = domicilio + ",departamento:" + this.sendCompany.flat;
        }
        if (this.sendCompany.tower) {
          domicilio = domicilio + ", torre:" + this.sendCompany.tower;
        }
        dataForm = {
          IdCliente: this.idClient,
          NmCliente: this.sendCompany.name,
          RazonSocial: this.sendCompany.name,
          Domicilio: domicilio,
          Localidad: this.destination,
          CP: "",
          TE: this.sendCompany.prePhone + "-" + this.sendCompany.phone,
          CdIVA: 3, // consumidor final
          CUIT: this.sendCompany.cuit,
          DNI: "",
          IngBrutos: "",
          Mail: this.sendCompany.mail,
        };
        this.$store.commit("valueReceiver", dataForm);
      }

      const { data } = await agregarCliente(dataForm);
      console.log("data", data);
      if (data.mensaje == 'Exitoso') {
        this.incompleted();
         this.$emit('finishApiClient', true);
        this.errorMessage = data.mensaje;
        if (data.IdCliente) {
          this.idClient = data.IdCliente;
          this.$store.commit("valueIdClientReceiver", data.IdCliente);       
        }
      } else {
        this.errorMessage = data.mensaje;
        this.completed();
        this.$emit('finishApiClient', false );
      }
    },
    async changeDataClient() {
      var dataForm;
      var domicilio;
      if (this.tab == 0 || this.tab == null) {
        domicilio = this.sendPerson.street + " " + this.sendPerson.streetNumber;
        if (this.sendPerson.floor) {
          domicilio = domicilio + ",piso:" + this.sendPerson.floor;
        }
        if (this.sendPerson.flat) {
          domicilio = domicilio + ",departamento:" + this.sendPerson.flat;
        }
        if (this.sendPerson.tower) {
          domicilio = domicilio + ", torre:" + this.sendPerson.tower;
        }
        dataForm = {
          IdCliente: this.idClient,
          NmCliente: this.sendPerson.name,
          RazonSocial: this.sendPerson.name,
          Domicilio: domicilio,
          Localidad: this.destination,
          CP: "",
          TE: this.sendPerson.prePhone + "-" + this.sendPerson.phone,
          CdIVA: 3, // consumidor final
          CUIT: "",
          DNI: this.sendPerson.dni,
          IngBrutos: "",
          Mail: this.sendPerson.mail,
        };
        this.$store.commit("valueReceiver", dataForm);
      } else {
        domicilio =
          this.sendCompany.street + "," + this.sendCompany.streetNumber;
        if (this.sendCompany.floor) {
          domicilio = domicilio + ",piso:" + this.sendCompany.floor;
        }
        if (this.sendCompany.flat) {
          domicilio = domicilio + ",departamento:" + this.sendCompany.flat;
        }
        if (this.sendCompany.tower) {
          domicilio = domicilio + ", torre:" + this.sendCompany.tower;
        }
        dataForm = {
          IdCliente: this.idClient,
          NmCliente: this.sendCompany.name,
          RazonSocial: this.sendCompany.name,
          Domicilio: domicilio,
          Localidad: this.destination,
          CP: "",
          TE: this.sendCompany.prePhone + "-" + this.sendCompany.phone,
          CdIVA: 1, // responsable inscripto
          CUIT: this.sendCompany.cuit,
          DNI: "",
          IngBrutos: "",
          Mail: this.sendCompany.mail,
        };
        this.$store.commit("valueReceiver", dataForm);
      }
      const { data } = await modificarCliente(dataForm);
      console.log("data", data);
      if (data.mensaje == 'Exitoso') {
        this.incompleted();
         this.$emit('finishApiClient',  true );
        this.errorMessage = data.mensaje;
        if (data.IdCliente) {
          this.idClient = data.IdCliente;
          this.$store.commit("valueIdClientReceiver", data.IdCliente); 
        }
      } else {
        this.errorMessage = data.mensaje;
        this.completed();
        this.$emit('finishApiClient',  false);
      }
    },
  },
  computed: {
    destination() {
      return this.$store.state.destination;
    },
  },
  watch: {
    tab() {
      this.incompleted();
      this.$store.commit("disableButtom", true);
    },
    clickButtonNext(){
      if(this.clickButtonNext == true && this.stateE1 == 3){
     if( this.tab == 0 || this.tab == null){
        if (this.isFormValid == true) {
        console.log('persona');
        if (this.idClient == 0 && this.sendPerson.dni != "") {
            console.log(' new persona');
          this.addNewClient();
        }
        if (this.idClient != 0) {
          console.log('old persona',this.sendPerson.dni,this.idClient);
          this.changeDataClient();
        }
        this.completed();
      } else {
        this.incompleted();
      }
     }
     else{
       if (this.isFormValidCompany == true) {
          console.log('compañia');
        if (this.idClient == 0 && this.sendCompany.cuit != "") {
          this.addNewClient();
        }
        if (this.idClient != 0) {
          this.changeDataClient();
        }
        this.completed();
      } else {
        this.incompleted();
      }
     }
      }
    },
     isFormValid() {
       if (this.isFormValid == true) {
         console.log('persona');
         this.completed();
       } else {
         this.incompleted();
       }
     },
     isFormValidCompany() {
       if (this.isFormValidCompany == true) {
           console.log('compañia');
         this.completed();
       } else {
         this.incompleted();
       }
     },
    // sendPerson: {
    //   handler(val) {
    //     console.log(val);
    //     if (this.sendPerson.dni && this.idClient == 0) {
    //       console.log("entro o no?");
    //       this.getClientExist();
    //     }
    //   },
    //   deep: true,
    // },
    // sendCompany: {
    //   handler(val) {
    //     console.log(val);
    //     if (this.sendCompany.cuit && this.idClient == 0) {
    //       this.getClientExist();
    //     }
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.$store.commit("disableButtom", true);
  },
};
</script>

<style lang="css" scoped>
.transparent-body {
  background: transparent;
}

.row {
  padding-top: 10px !important;
}
.v-tab {
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.07px;
  text-align: center;
  color: #0052cc;
  padding: 0;
  margin: 0;
  min-width: 50%;
 }
.v-tabs-slider-wrapper{
  width: 50% !important;
}
</style>
<style lang="css">
#tabs-form  .v-tabs-slider-wrapper{
  width: 50% !important;
}
.border-red {
  border-left: solid 0.188rem #ff2633;
  padding-left: 0.5rem;
}
.add-cost {
  margin: 0.65rem 0rem 1rem 0.375rem;
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.24px;
  text-align: left;
  color: #0052cc;
}
.total {
  font-family: Roboto;
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-align: left;
  color: #2e2e2e;
}
.text-total {
  margin: 0.625rem 0 0;
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.24px;
  text-align: left;
  color: #000000;
}

</style>
