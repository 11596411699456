<template>
  <div id="desktop-bar" class="top-bar">
    <v-app-bar color="white"  class="py-0 px-15"
    style="height:60px;">
      <v-toolbar-title>
        <div class="d-flex justify-start py-3">
          <router-link
                to="/inicio"
                class="decoration-remove route"
              >
          <img
            alt="Vue logo"
            src="../assets/Image/Logo/LOGO-MOBILE.svg"
            height="35px"
            class="logo-sierraspack"
          />
          </router-link>
          </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-text-field
        outlined
        dense
        hide-details
        label="Número de seguimiento"
        v-model="trackingCode"
        class="tracking-input pr-15"
        v-if="this.$route.name != 'seguimiento'"
        @keydown.enter="goToTracking()"
      >
        <template slot="append">
          <a
            @click="goToTracking()"
            class="decoration-remove route"
          >
            <search-icon
              size="1.5x"
              class="custom-class"
              color="#0052cc"
            ></search-icon>
          </a>
        </template>
      </v-text-field>

      <div style="height:100%">

        <v-tabs 
        v-model="tab"
        right style="height:100%"
         color="secondary" id="tabs-desktop">
          <router-link
            to="/inicio"
            class="decoration-remove route"
          >
          <v-tab>Inicio</v-tab> 
          </router-link> 
           <router-link
            to="/envio"
            class="decoration-remove route"
          >     
          <v-tab  >Cotizador</v-tab>
           </router-link> 
          <router-link
            to="/contacto"
            class="decoration-remove route"
          >
          <v-tab>Contacto</v-tab>
          </router-link>
          <router-link
            to="/ayuda"
            class="decoration-remove route"
          >             
          <v-tab>Ayuda </v-tab>
          </router-link>
        </v-tabs>
      </div>
    </v-app-bar>
    <v-snackbar
        :timeout="15000"
        :value="snackbar"
        absolute
        top
        color="#1d3460"
        elevation="3"
        class="snackbar-tooltip"
        v-if="$route.name == 'Inicio'"
      >
        <div><p style="margin: 0;"> Ingresá tu número de seguimiento aquí y seguí tu paquete en <strong>tiempo real.</strong></p>
        </div>
        <div class="arrow-up">
        </div>    
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
          style="padding:0; margin:0; min-width: 30px !important;"
          id="close-hover"
        >
          <span class="v-btn__content">
            <span aria-hidden="true" class="v-icon notranslate theme--dark" style="font-size:16px;height:16px;width:16px;">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size:16px;height:16px;width:16px;">
                <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z">
                </path>
              </svg>
              </span>
            </span>
        </v-btn>
      </template>      
    </v-snackbar>
  </div>
</template>

<script>
import { SearchIcon } from "vue-feather-icons";
export default {
  name: "TopBar",
  components:{
   SearchIcon,
  },
  data: () => ({
    originalHeight: window.innerHeight,
    windowHeight: window.innerHeight,
    snackbar: true,
    items: [
      {
        src: require("../assets/Image/Home/icon calculadora.svg"),
        titulo: "Cotizá tu envío",
        texto: "Elegí el origen y destino. Ingresá las medidas del paquete.",
      },
      {
        src: require("../assets/Image/Home/check.svg"),
        titulo: "Cargá los datos",
        texto: "Completá los formularios con tus datos y los del destinatario.",
      },
      {
        src: require("../assets/Image/Home/credit.svg"),
        titulo: "Método de pago",
        texto: "Elegí el método que prefieras para abonar tu envío.",
      },
      {
        src: require("../assets/Image/Home/icon exito.svg"),
        titulo: "Método de pago",
        texto: "Te daremos el número de seguimiento de tu paquete.",
      },
    ],
    trackingCode: "",
    tab:0,
  }),
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        this.originalHeight = window.innerHeight;
      } else if (orientation === "landscape-primary") {
        this.originalHeight = window.innerHeight;
      }
    },
     clickCalculator(){
      this.$store.commit('valueCalculatorButton',true);
      this.tab = 1;
      this.$router.push({ path: '/envio' });
    },
    checkRoute(){
      const routeName = this.$route.name ;
      if(routeName == 'Inicio'){
        this.tab = 0;
      }
      if(routeName == 'contacto'){
        this.tab = 2;
      }
      if(routeName == 'ayuda'){
        this.tab = 3;
      }
    },
      goToTracking(){
      if(this.trackingCode){
      this.$router.push({name: 'seguimiento',params: { tracking: this.trackingCode }})
    }
    }

  },
  watch:{
  tab(){
    if(this.tab == 1)
    this.clickCalculator();
  }
  },
  mounted() {
    this.checkRoute();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    window.addEventListener("orientationchange", this.handleOrientationChange);
    this.checkRoute();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="css">
.top-bar {
  position: fixed;
  top: 0%;
  width: 100%;
  z-index: 40;
}
.theme--light #tabs-desktop > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #0052cc !important;
}

.theme--light #tabs-desktop > .v-tabs-bar {
 height:100%;
}
 .v-toolbar__content {
    height: 60px !important;
    padding-bottom: 0px;
    margin-bottom:0px;
}

.snackbar-tooltip {
  padding-bottom: 0px; 
  padding-top: 56px; 
  width: 400px !important; 
  left:50% !important; 
  margin-left: -150px !important;
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #1d3460;
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  z-index: 2;
}

@media (max-width: 1366px) {
.snackbar-tooltip{
  left: 40% !important;
}
}

@media (min-width: 1024px) {
.tracking-input .v-label {
  color: #0052cc !important;
}
}

#close-hover::before{
  background-color: transparent !important; 
}
</style>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #0052cc;
}

</style>