<template> 
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="square" transform="translate(-2 -2)">
        <path id="Trazado_560" d="M19 22H5a2.946 2.946 0 0 1-3-3V5a2.946 2.946 0 0 1 3-3h14a2.946 2.946 0 0 1 3 3v14a2.946 2.946 0 0 1-3 3zM5 4a.945.945 0 0 0-1 1v14a.945.945 0 0 0 1 1h14a.945.945 0 0 0 1-1V5a.945.945 0 0 0-1-1z" class="cls-calculator"/>
        <path id="Trazado_561" d="M11 3.6h2v16.6h-2z" class="cls-calculator"/>
        <path id="Trazado_562" d="M3.7 13v-2h16.6v2z" class="cls-calculator"/>
        <g id="Grupo_248">
            <g id="Grupo_246">
                <path id="Trazado_563" d="M7.5 10a.536.536 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v3.9a.56.56 0 0 1-.5.6z" class="cls-calculator"/>
                <path id="Trazado_564" d="M7.5 10.1a.645.645 0 0 1-.6-.6v-4a.645.645 0 0 1 .6-.6.645.645 0 0 1 .6.6v3.9a.669.669 0 0 1-.6.7zm0-4.9a.43.43 0 0 0-.4.4v3.9a.4.4 0 0 0 .8 0v-4c0-.2-.2-.3-.4-.3z" class="cls-calculator"/>
            </g>
            <g id="Grupo_247">
                <path id="Trazado_565" d="M9.5 8h-4a.5.5 0 0 1 0-1h3.9a.56.56 0 0 1 .6.5.536.536 0 0 1-.5.5z" class="cls-calculator"/>
                <path id="Trazado_566" d="M9.5 8.1h-4a.645.645 0 0 1-.6-.6.645.645 0 0 1 .6-.6h3.9a.645.645 0 0 1 .6.6.56.56 0 0 1-.5.6zm-4-1a.4.4 0 0 0 0 .8h3.9a.43.43 0 0 0 .4-.4c0-.2-.1-.4-.3-.4z" class="cls-calculator"/>
            </g>
        </g>
        <g id="Grupo_251">
            <g id="Grupo_249">
                <path id="Trazado_567" d="M9.2 18.2a.483.483 0 0 1-.7 0l-2.8-2.8a.495.495 0 0 1 .7-.7l2.8 2.8c.2.2.2.6 0 .7z" class="cls-calculator"/>
                <path id="Trazado_568" d="M8.9 18.5a.52.52 0 0 1-.4-.2l-2.8-2.8a.746.746 0 0 1 0-.9.746.746 0 0 1 .9 0l2.8 2.8a.758.758 0 0 1 .2.4.758.758 0 0 1-.2.4.914.914 0 0 1-.5.3zm-2.8-3.8a.367.367 0 0 0-.3.1.454.454 0 0 0 0 .5l2.8 2.8a.367.367 0 0 0 .3.1.367.367 0 0 0 .3-.1.454.454 0 0 0 0-.5l-2.8-2.8c-.1 0-.2-.1-.3-.1z" class="cls-calculator"/>
            </g>
            <g id="Grupo_250">
                <path id="Trazado_569" d="M9.2 15.5l-2.8 2.8a.495.495 0 0 1-.7-.7l2.8-2.8a.495.495 0 0 1 .7.7z" class="cls-calculator"/>
                <path id="Trazado_570" d="M6.1 18.5a.52.52 0 0 1-.4-.2.746.746 0 0 1 0-.9l2.8-2.8a.746.746 0 0 1 .9 0 .746.746 0 0 1 0 .9l-2.8 2.8c-.2.1-.3.2-.5.2zm2.8-3.8a.367.367 0 0 0-.3.1l-2.8 2.8a.454.454 0 0 0 0 .5.367.367 0 0 0 .3.1.367.367 0 0 0 .3-.1l2.8-2.8a.454.454 0 0 0 0-.5c-.1 0-.2-.1-.3-.1z" class="cls-calculator"/>
            </g>
        </g>
        <g id="Grupo_252">
            <path id="Trazado_571" d="M18.5 8h-3.9a.56.56 0 0 1-.6-.5.536.536 0 0 1 .5-.5h3.9a.56.56 0 0 1 .6.5.536.536 0 0 1-.5.5z" class="cls-calculator"/>
            <path id="Trazado_572" d="M18.5 8.1h-3.9a.645.645 0 0 1-.6-.6.645.645 0 0 1 .6-.6h3.9a.645.645 0 0 1 .6.6.645.645 0 0 1-.6.6zm-4-1a.4.4 0 0 0 0 .8h3.9a.4.4 0 0 0 0-.8z" class="cls-calculator"/>
        </g>
        <g id="Grupo_255">
            <g id="Grupo_253">
                <path id="Trazado_573" d="M18.5 15.7h-3.9a.5.5 0 0 1 0-1h3.9a.472.472 0 0 1 .5.5.536.536 0 0 1-.5.5z" class="cls-calculator"/>
                <path id="Trazado_574" d="M18.5 15.9h-3.9a.6.6 0 1 1 0-1.2h3.9a.6.6 0 1 1 0 1.2zm-4-1a.4.4 0 0 0 0 .8h3.9a.4.4 0 1 0 0-.8z" class="cls-calculator"/>
            </g>
            <g id="Grupo_254">
                <path id="Trazado_575" d="M18.5 18.2h-3.9a.5.5 0 0 1 0-1h3.9a.472.472 0 0 1 .5.5.536.536 0 0 1-.5.5z" class="cls-calculator"/>
                <path id="Trazado_576" d="M18.5 18.4h-3.9a.6.6 0 1 1 0-1.2h3.9a.6.6 0 1 1 0 1.2zm-4-1a.4.4 0 0 0 0 .8h3.9a.4.4 0 1 0 0-.8z" class="cls-calculator"/>
            </g>
        </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'CalculatorIcon'
  }
</script>